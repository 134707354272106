export const validateFormDetails = (formDetails) => {
    const errors = {};

    if (!formDetails.formName || formDetails.formName.length < 3 || formDetails.formName.length > 80) {
        errors.formName = 'Name is mandatory and should contain at least 3 characters and max 80 characters';
    }

    if (!formDetails.formURL || formDetails.formURL.length < 3 || formDetails.formURL.length > 32) {
        errors.formURL = 'Form URL is mandatory and should contain at least 3 characters and max 32 characters';
    }

    if (formDetails.formDescription.length > 200) {
        errors.formDescription = 'Form description should not exceed 200 characters';
    }
    /*
    if (!formDetails.webhookURL || !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(formDetails.webhookURL)) {
        errors.webhookURL = 'Webhook URL is mandatory and should be a valid URL';
    }*/

    return errors;
};

export const validateQuestionDetails = (question) => {
    const errors = {};

    if (!question.key || question.key.length < 3 || question.key.length > 26) {
        errors.key = 'Question key is mandatory and should contain at least 3 characters and max 26 characters';
    }

    if (!question.label || question.label.length < 3 || question.label.length > 72) {
        errors.label = 'Question label is mandatory and should contain at least 3 characters and max 72 characters';
    }

    return errors;
};
