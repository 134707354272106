import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './../dashboard/Sidebar';
import TopBar from './../dashboard/TopBar';
import './../../assets/css/FormListPage.css';
import { FaRegEye, FaRegTrashAlt, FaRegEdit, FaChartBar } from 'react-icons/fa'; // Updated icon for view responses

const FormListPage = () => {

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };
    const forms = [
        {
            id: 1,
            name: 'Customer Satisfaction Survey',
            createdDate: '2024-08-01',
            responses: 120,
            campaignActive: true,
        },
        {
            id: 2,
            name: 'Employee Feedback Form',
            createdDate: '2024-07-15',
            responses: 75,
            campaignActive: false,
        },
        // Add more forms as needed
    ];

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                <div className="form-list-container">
                    <h2>All Forms</h2>
                    <table className="form-list-table">
                        <thead>
                            <tr>
                                <th>Form Name</th>
                                <th>Created Date</th>
                                <th>Responses</th>
                                <th>Campaign Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map((form) => (
                                <tr key={form.id}>
                                    <td>{form.name}</td>
                                    <td>{form.createdDate}</td>
                                    <td>{form.responses}</td>
                                    <td>{form.campaignActive ? 'Yes' : 'No'}</td>
                                    <td>
                                        <Link to={`/forms/${form.id}/view`} className="action-icon">
                                            <FaRegEye title="View Form" />
                                        </Link>
                                        <Link to={`/forms/${form.id}/responses`} className="action-icon">
                                            <FaChartBar title="View Responses" />
                                        </Link>
                                        <Link to={`/forms/${form.id}/edit`} className="action-icon">
                                            <FaRegEdit title="Edit Form" />
                                        </Link>
                                        <Link to={`/forms/${form.id}/delete`} className="action-icon delete-icon">
                                            <FaRegTrashAlt title="Delete Form" />
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FormListPage;
