import React, { useState } from 'react';
import Sidebar from './dashboard/Sidebar';
import TopBar from './dashboard/TopBar';
import { FiTrash2, FiArrowUp, FiArrowDown, FiPlus } from 'react-icons/fi';
import './../assets/css/FormBuilder.css';

const initialComponents = [
    { id: 'text', type: 'text', label: 'Text Input' },
    { id: 'textarea', type: 'textarea', label: 'Paragraph Text' },
    { id: 'dropdown', type: 'dropdown', label: 'Dropdown' },
    { id: 'radio', type: 'radio', label: 'Radio Button' },
    { id: 'file', type: 'file', label: 'File Upload' },
];

const FormBuilder = () => {
    const [formComponents, setFormComponents] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const addComponent = (component) => {
        const newComponent = { ...component, id: `${component.id}-${formComponents.length}`, options: [] };
        setFormComponents([...formComponents, newComponent]);
    };

    const removeComponent = (index) => {
        const newFormComponents = formComponents.filter((_, i) => i !== index);
        setFormComponents(newFormComponents);
    };

    const moveComponentUp = (index) => {
        if (index === 0) return;
        const newFormComponents = Array.from(formComponents);
        [newFormComponents[index - 1], newFormComponents[index]] = [newFormComponents[index], newFormComponents[index - 1]];
        setFormComponents(newFormComponents);
    };

    const moveComponentDown = (index) => {
        if (index === formComponents.length - 1) return;
        const newFormComponents = Array.from(formComponents);
        [newFormComponents[index + 1], newFormComponents[index]] = [newFormComponents[index], newFormComponents[index + 1]];
        setFormComponents(newFormComponents);
    };

    const updateLabel = (index, newLabel) => {
        const newFormComponents = formComponents.map((component, i) => (
            i === index ? { ...component, label: newLabel } : component
        ));
        setFormComponents(newFormComponents);
    };

    const addOption = (index) => {
        const newFormComponents = formComponents.map((component, i) => {
            if (i === index) {
                const newOptions = [...component.options, `Option ${component.options.length + 1}`];
                return { ...component, options: newOptions };
            }
            return component;
        });
        setFormComponents(newFormComponents);
    };

    const removeOption = (componentIndex, optionIndex) => {
        const newFormComponents = formComponents.map((component, i) => {
            if (i === componentIndex) {
                const newOptions = component.options.filter((_, oi) => oi !== optionIndex);
                return { ...component, options: newOptions };
            }
            return component;
        });
        setFormComponents(newFormComponents);
    };

    const updateOption = (componentIndex, optionIndex, newOption) => {
        const newFormComponents = formComponents.map((component, i) => {
            if (i === componentIndex) {
                const newOptions = component.options.map((option, oi) => (
                    oi === optionIndex ? newOption : option
                ));
                return { ...component, options: newOptions };
            }
            return component;
        });
        setFormComponents(newFormComponents);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="main-content">
                <TopBar modalIsOpen={modalIsOpen} openModal={openModal} closeModal={closeModal} />
                <div className="form-builder-container">
                    <div className="initial-components">
                        <h3>Available Components</h3>
                        {initialComponents.map((component) => (
                            <div
                                key={component.id}
                                className="clickable-component"
                                onClick={() => addComponent(component)}
                            >
                                {component.label}
                            </div>
                        ))}
                    </div>
                    <div className="form-components">
                        <h3>Your Form</h3>
                        {formComponents.map((component, index) => (
                            <div key={component.id} className="form-component">
                                <div className="form-component-header">
                                    <input
                                        type="text"
                                        value={component.label}
                                        onChange={(e) => updateLabel(index, e.target.value)}
                                        className="form-component-label"
                                    />
                                    <div className="form-component-controls">
                                        <button onClick={() => moveComponentUp(index)}><FiArrowUp /></button>
                                        <button onClick={() => moveComponentDown(index)}><FiArrowDown /></button>
                                        <button onClick={() => removeComponent(index)}><FiTrash2 /></button>
                                    </div>
                                </div>
                                {component.type === 'text' && <input type="text" disabled />}
                                {component.type === 'textarea' && <textarea disabled />}
                                {component.type === 'dropdown' && (
                                    <div className="form-component-options">
                                        <select disabled>
                                            {component.options.map((option, oi) => (
                                                <option key={oi}>{option}</option>
                                            ))}
                                        </select>
                                        <button onClick={() => addOption(index)}><FiPlus /> Add Option</button>
                                        {component.options.map((option, oi) => (
                                            <div key={oi}>
                                                <input
                                                    type="text"
                                                    value={option}
                                                    onChange={(e) => updateOption(index, oi, e.target.value)}
                                                />
                                                <button onClick={() => removeOption(index, oi)}><FiTrash2 /></button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {component.type === 'radio' && (
                                    <div className="form-component-options">
                                        {component.options.map((option, oi) => (
                                            <div key={oi}>
                                                <input type="radio" disabled />
                                                <input
                                                    type="text"
                                                    value={option}
                                                    onChange={(e) => updateOption(index, oi, e.target.value)}
                                                />
                                                <button onClick={() => removeOption(index, oi)}><FiTrash2 /></button>
                                            </div>
                                        ))}
                                        <button onClick={() => addOption(index)}><FiPlus /> Add Option</button>
                                    </div>
                                )}
                                {component.type === 'file' && <input type="file" disabled />}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormBuilder;
