import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../assets/css/SignupPage.css';

const OtpPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { email, contact_number } = location.state; // Receiving contact_number from previous page
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({});
    const [timer, setTimer] = useState(60);
    const [resendDisabled, setResendDisabled] = useState(true);
    const [apiError, setApiError] = useState(''); // To handle API errors
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    setResendDisabled(false);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setOtp(value);

        if (errors.otp) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                otp: '',
            }));
        }

        // Clear the apiError when the user starts typing
        if (apiError) {
            setApiError('');
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!otp) newErrors.otp = 'OTP is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            setApiError('');

            try {
                const response = await fetch('http://localhost:5020/prod/api/v1/user/verify-2fa', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        two_factor_key: otp,
                        ref_access: 'CAS',
                    }),
                });

                const result = await response.json();

                if (response.ok && result.api.responseCode === 2250) {
                    // Success - Redirect to dashboard or next page
                    console.log('OTP verified:', result);
                    navigate('/dashboard');
                } else {
                    setApiError(result.message || 'Invalid OTP. Please try again.');
                }
            } catch (error) {
                setApiError('Something went wrong. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleResendOTP = async () => {
        if (!resendDisabled) {
            try {
                setLoading(true);
                const response = await fetch('http://localhost:5020/prod/api/v1/user/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: '', // Assuming password is empty or stored securely
                        ref_access: 'CAS',
                    }),
                });

                const result = await response.json();
                if (response.ok && result.api.responseCode === 2230) {
                    setTimer(60);
                    setResendDisabled(true);
                    setApiError(''); // Clear any previous errors
                    console.log('OTP resent to:', result.result.contact_number || email);
                } else {
                    setApiError(result.message || 'Failed to resend OTP. Please try again.');
                }
            } catch (error) {
                setApiError('Something went wrong while resending OTP. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Verify 2FA</h2>
                <p>
                    {contact_number
                        ? `An OTP has been sent to ${email} and ${contact_number}.`
                        : `An OTP has been sent to ${email}.`}
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>OTP</label>
                        <input
                            type="text"
                            value={otp}
                            onChange={handleInputChange}
                            placeholder="Enter OTP"
                            className="input-field"
                        />
                        {errors.otp && <span className="error-message">{errors.otp}</span>}
                    </div>
                    {apiError && <p className="error-message">{apiError}</p>} {/* Display API error */}
                    <button type="submit" className="button" disabled={loading}>
                        {loading ? 'Verifying...' : 'Verify'}
                    </button>
                </form>
                <p className="resend-otp">
                    Didn't receive the OTP?{' '}
                    <span onClick={handleResendOTP} className={resendDisabled ? 'disabled' : ''}>
                        Resend OTP
                    </span>{' '}
                    {resendDisabled && `in ${timer}s`}
                </p>
            </div>
            <footer className="footer">
                <p>© {new Date().getFullYear()} Eden Care. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default OtpPage;
