import React, { useState } from 'react';
import './../../assets/css/SurveyResult.css';

import Analytics from './Analytics';
import ReportViewer from './ReportViewer';
import FormConfiguration from './FormConfiguration';
import ControlPanel from './ControlPanel';

import ReportList from './ReportList';

import Sidebar from './Sidebar';

const SurveyResult = () => {

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const [activeTab, setActiveTab] = useState('excel');

    const changeTab = (tabName) => {
        setActiveTab(tabName);
    };

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    return (

        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <div className="survey-result">
                    <h2 className="survey-result-header">Survey Form Results</h2>
                    <div className="tabs">
                        <button className={`tab-button ${activeTab === 'formConfiguration' ? 'active' : ''}`} onClick={() => changeTab('formConfiguration')}>
                            Control Panel
                        </button>
                        <button className={`tab-button ${activeTab === 'analytics' ? 'active' : ''}`} onClick={() => changeTab('analytics')}>Reports</button>
                        <button className={`tab-button ${activeTab === 'excel' ? 'active' : ''}`} onClick={() => changeTab('excel')}>Excel View</button>
                    </div>
                    {activeTab === 'excel' && <ReportViewer />}
                    {activeTab === 'analytics' && <ReportList />}
                    {activeTab === 'formConfiguration' && <FormConfiguration />}
                </div>
            </div>
        </div>


    );
};

export default SurveyResult;
