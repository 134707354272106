import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import formStructure from './data/formStructure.json';
import SurveyForm from './components/SurveyForm';
import SurveyResult from './components/dashboard/SurveyResult';
import ErrorPage from './components/ErrorPage';
import './assets/css/styles.css';
import './assets/css/fonts.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login/LoginPage';
import Signup from './components/Login/Signup';
import FormBuilder from './components/FormBuilder'; // Import the FormBuilder component
import CreateFormPage from './components/dashboard/CreateFormPage';
import FormSubmittedPage from './components/dashboard/FormSubmittedPage';
import FormPreviewPage from './components/dashboard/FormPreviewPage';
import OTPVerificationPage from './components/Login/OTPVerificationPage';
import SetupFormlyticsPage from './components/Login/SetupFormlyticsPage';
import CreateWorkspacePage from './components/Login/CreateWorkspacePage';
import SettingsPage from './components/dashboard/SettingsPage';
import FormConfigurationsPage from './components/dashboard/FormConfigurationsPage';
import FormListPage from './components/dashboard/FormListPage';
import ReportList from './components/dashboard/ReportList';
import ReportViewer from './components/dashboard/ReportViewer';
import Otp from './components/Login/OtpPage';

import CreateReport from './components/dashboard/CreateReport';

import ReportSettings from './components/dashboard/ReportSettings';

import GraphicalInsights from './components/dashboard/GraphicalInsights';

import AIGraph from './components/dashboard/AIGraph';

const App = () => {
  const [formData, setFormData] = useState({});
  const [surveyUrl, setSurveyUrl] = useState('');

  useEffect(() => {
    setFormData(formStructure);
    setSurveyUrl(formStructure.url);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    const currentQuestion = formData.questions.find(question => question.label === name);

    if (currentQuestion && currentQuestion.options) {
      const selectedOption = currentQuestion.options.find(option => option.value === value);
      if (selectedOption && selectedOption.nextQuestion) {
        const nextQuestion = selectedOption.nextQuestion;
        setFormData(prevState => ({
          ...prevState,
          [nextQuestion.label]: ''
        }));
      } else if (selectedOption && selectedOption.nextQuestion === null) {
        const nextQuestionIndex = formData.questions.findIndex(question => question.label === currentQuestion.label) + 1;
        if (nextQuestionIndex < formData.questions.length) {
          const nextQuestion = formData.questions[nextQuestionIndex];
          setFormData(prevState => ({
            ...prevState,
            [nextQuestion.label]: ''
          }));
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    window.location.href = formData.url;
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-otp" element={<OTPVerificationPage />} />
        <Route path="/setup-formlytics" element={<SetupFormlyticsPage />} />
        <Route path="/create-workspace" element={<CreateWorkspacePage />} />
        <Route path="/settings" element={<SettingsPage />} />

        <Route path="/form-configurations" element={<FormConfigurationsPage />} />

        <Route path="/form-list" element={<FormListPage />} />

        <Route path="/report/:reportId" element={<ReportViewer />} />

        <Route path="/reports" element={<ReportList />} />

        <Route path="/login/otp" element={<Otp />} />

        <Route path="/create-report" element={<CreateReport />} />

        <Route path="/member-insights" element={<GraphicalInsights />} />

        <Route path="/report-settings/:reportId" element={<ReportSettings />} />

        <Route path="/ai-graph" element={<AIGraph />} />




        <Route
          path={`/${surveyUrl}`}
          element={
            <div>
              <SurveyForm formData={formData} setFormData={setFormData} />
            </div>
          }
        />
        <Route path="/dashboard" element={<ReportList />} />
        <Route path="/dashboard/form-result" element={<SurveyResult />} />

        <Route path="/dashboard/report-list" element={<ReportList />} />

        <Route path="/create-form" element={<CreateFormPage />} />
        <Route path="/form-submitted" element={<FormSubmittedPage />} />
        <Route path="/form-preview" element={<FormPreviewPage />} />
        <Route path="/form-builder" element={<FormBuilder />} /> {/* Add this route <Route path="/" element={<Navigate to="/" />} /> */}

        <Route path="/preview/:formUrl" element={<FormSubmittedPage />} /> {/* Dynamic route for form preview */}

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
