import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../assets/css/SignupPage.css';

const OTPVerificationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state;
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({});
    const [timer, setTimer] = useState(60);
    const [resendDisabled, setResendDisabled] = useState(true);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    setResendDisabled(false);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setOtp(value);

        if (errors.otp) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                otp: '',
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!otp) newErrors.otp = 'OTP is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Simulate OTP verification
            console.log('Verifying OTP:', otp);
            navigate('/setup-formlytics');
        }
    };

    const handleResendOTP = () => {
        if (!resendDisabled) {
            console.log('Resending OTP to:', email);
            setTimer(60);
            setResendDisabled(true);
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Verify Your Email</h2>
                <p>Enter the OTP sent to {email}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>OTP</label>
                        <input
                            type="text"
                            value={otp}
                            onChange={handleInputChange}
                            placeholder="Enter OTP"
                            className="input-field"
                        />
                        {errors.otp && <span className="error-message">{errors.otp}</span>}
                    </div>
                    <button type="submit" className="button">Verify</button>
                </form>
                <p className="resend-otp">
                    Didn't receive the OTP? <span onClick={handleResendOTP} className={resendDisabled ? 'disabled' : ''}>Resend OTP</span> {resendDisabled && `in ${timer}s`}
                </p>
            </div>
            <footer className="footer">
                <p>© {new Date().getFullYear()} Formlytics. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default OTPVerificationPage;
