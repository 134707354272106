import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiHome, FiMessageSquare, FiBook, FiUsers, FiSettings, FiLogOut, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import './../../assets/css/Sidebar.css';

const Sidebar = ({ handleLogout, onToggle }) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleSidebar = () => {
        const newCollapsedState = !collapsed;
        setCollapsed(newCollapsedState);
        onToggle(newCollapsedState);
    };

    const logout = () => {
        handleLogout();
        navigate('/login');
    };

    // Sample user data
    const user = {
        name: 'John Doe',
        email: 'john.doe@example.com',
        accountId: '1234567890',
        profilePhoto: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    };

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
            <div className="sidebar-toggle" onClick={toggleSidebar}>
                {collapsed ? <FiChevronRight /> : <FiChevronLeft />}
            </div>
            <div className="sidebar-profile">
                <img className="profile-photo" src={user.profilePhoto} alt="Profile" />
                <h3 className="profile-name">{user.name}</h3>
                <div className="account-id">Account ID: {user.accountId}</div>
                <p className="profile-email">{user.email}</p>
            </div>
            <ul className="sidebar-nav">
                <li className={`sidebar-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <Link to="/dashboard" className="sidebar-link">
                        <FiHome className="sidebar-icon" />
                        {!collapsed && 'Dashboard'}
                    </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/contacts' ? 'active' : ''}`}>
                    <Link to="/contacts" className="sidebar-link">
                        <FiBook className="sidebar-icon" />
                        {!collapsed && 'Reports'}
                    </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/messages' ? 'active' : ''}`}>
                    <Link to="/messages" className="sidebar-link">
                        <FiMessageSquare className="sidebar-icon" />
                        {!collapsed && 'Insights'}
                    </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/audiences' ? 'active' : ''}`}>
                    <Link to="/audiences" className="sidebar-link">
                        <FiUsers className="sidebar-icon" />
                        {!collapsed && 'Workspaces'}
                    </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/settings' ? 'active' : ''}`}>
                    <Link to="/settings" className="sidebar-link">
                        <FiSettings className="sidebar-icon" />
                        {!collapsed && 'Settings'}
                    </Link>
                </li>
            </ul>
            <div className="sidebar-bottom">
                <button className="logout-button" onClick={logout}>
                    <FiLogOut className="logout-icon" />
                    {!collapsed && 'Logout'}
                </button>
                {!collapsed && <p className="app-version">App Version 1.0.0</p>}
            </div>
        </div>
    );
};

export default Sidebar;
