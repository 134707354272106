import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../../assets/css/SignupPage.css';

const CreateWorkspacePage = () => {
    const navigate = useNavigate();
    const [workspaceName, setWorkspaceName] = useState('');
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { value } = e.target;
        setWorkspaceName(value);

        if (errors.workspaceName) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                workspaceName: '',
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Workspace created:', workspaceName);
        navigate('/create-form');
    };

    const handleSkip = () => {
        navigate('/create-form');
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Create Your First Workspace</h2>
                <p>Your workspace can be your organization, department, or any preferred title where you can create forms. You can create multiple workspaces later from the Dashboard.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Workspace Name (Optional)</label>
                        <input
                            type="text"
                            value={workspaceName}
                            onChange={handleInputChange}
                            placeholder="Enter workspace name"
                            className="input-field"
                        />
                    </div>
                    <button type="submit" className="button">Let's get started</button>
                </form>
                <p className="skip-link" onClick={handleSkip}>Skip now</p>
            </div>
            <footer className="footer">
                <p>© {new Date().getFullYear()} Formlytics. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default CreateWorkspacePage;
