import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import Modal from 'react-modal';
import './../../assets/css/ReportSettings.css';
import emptyBoxIcon from './../../assets/images/empty-box.png';
import noScheduleIcon from './../../assets/images/no-schedule.png';
import Select from 'react-select';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';

Modal.setAppElement('#root');

const ReportSettings = () => {
    const location = useLocation();
    // Get report from state
    const { report } = location.state || {};
    const { reportId } = useParams(); // Get the reportId from the route

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [scheduleData, setScheduleData] = useState([]); // Initialize with an empty array
    const [loading, setLoading] = useState(false); // Manages the loading state during form submission
    const [errorMessage, setErrorMessage] = useState(''); // Manages any error messages for the form
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newSchedule, setNewSchedule] = useState({
        email: '',
        frequency: '',
        dayOfWeek: '',
        dayOfMonth: '',
        reportFilter: '',
    });
    const [validationErrors, setValidationErrors] = useState({}); // Validation errors state
    const [dropdownOptions, setDropdownOptions] = useState({}); // State to store dropdown options for parameters

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    const resetFormFields = () => {
        setNewSchedule({
            email: '',
            frequency: '',
            dayOfWeek: '',
            dayOfMonth: '',
            reportFilter: '',
            monthInQuarter: ''
        });
        setValidationErrors({}); // Reset validation errors
    };

    const openModal = () => {
        resetFormFields(); // Reset fields when the modal is opened
        setIsModalOpen(true); // Open modal
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close modal
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Reset fields when frequency changes
        if (name === 'frequency') {
            setNewSchedule((prevSchedule) => ({
                ...prevSchedule,
                frequency: value,
                dayOfWeek: '',        // Reset day of week for Weekly
                dayOfMonth: '',       // Reset day of month for Monthly or Quarterly
                monthInQuarter: '',   // Reset month in quarter for Quarterly
            }));
        } else {
            // Update specific fields
            setNewSchedule((prevSchedule) => ({
                ...prevSchedule,
                [name]: value,
            }));
        }
        // Remove validation error when user interacts with the field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setNewSchedule((prevSchedule) => ({
            ...prevSchedule,
            [fieldName]: selectedOption.value,
        }));
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));
    };

    const loadDropdownOptions = async (param) => {
        try {
            const response = await fetch(
                `http://localhost:5020/prod/api/v1/cas/reporting/run-aggregation/${param.pre_load_data_source.report_id}`,
                { method: 'POST' }
            );
            const result = await response.json();
            const options = result.map(item => ({
                label: item[param.pre_load_data_source.value_key],
                value: item[param.pre_load_data_source.parameter_key]
            }));
            setDropdownOptions(prevOptions => ({
                ...prevOptions,
                [param.parameter_name]: options
            }));
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
        }
    };

    // Fetch dropdown options for parameters if needed
    useEffect(() => {
        if (report?.requires_parameter && report.parameters) {
            report.parameters.forEach(param => {
                if (param.is_mandatory && param.parameter_component_type === 'Dropdown') {
                    loadDropdownOptions(param);
                }
            });
        }
    }, [report]);

    const handleScheduleSubmit = (e) => {
        e.preventDefault();

        const errors = {};

        // Mandatory field validations
        if (!newSchedule.email) {
            errors.email = 'Email is required.';
        }
        if (!newSchedule.frequency) {
            errors.frequency = 'Frequency is required.';
        }
        if (!newSchedule.reportFilter) {
            errors.reportFilter = 'Report Filter Condition is required.';
        }

        // Conditional validations based on frequency
        if (newSchedule.frequency === 'Weekly' && !newSchedule.dayOfWeek) {
            errors.dayOfWeek = 'Day of the Week is required for Weekly frequency.';
        }
        if (newSchedule.frequency === 'Monthly' && !newSchedule.dayOfMonth) {
            errors.dayOfMonth = 'Day of the Month is required for Monthly frequency.';
        }
        if (newSchedule.frequency === 'Quarterly') {
            if (!newSchedule.monthInQuarter) {
                errors.monthInQuarter = 'Month in Quarter is required for Quarterly frequency.';
            }
            if (!newSchedule.dayOfMonth) {
                errors.dayOfMonth = 'Day of the Month is required for Quarterly frequency.';
            }
        }

        // If there are validation errors, show them and prevent form submission
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        // If no validation errors, proceed with submission
        setLoading(true); // Set loading to true during submission
        setErrorMessage(''); // Clear any previous error messages

        // Simulate form submission
        setTimeout(() => {
            setLoading(false); // Set loading to false after submission is done
            closeModal(); // Close the modal after successful submission
        }, 2000);
    };

    const handleDeleteSchedule = (index) => {
        const updatedScheduleData = scheduleData.filter((_, i) => i !== index);
        setScheduleData(updatedScheduleData);
    };

    const handleTogglePause = (index) => {
        const updatedScheduleData = scheduleData.map((schedule, i) => {
            if (i === index) {
                return { ...schedule, isPaused: !schedule.isPaused };
            }
            return schedule;
        });
        setScheduleData(updatedScheduleData);
    };

    const emailOptions = [
        { value: 'ekansh@edencaremedical.com', label: 'ekansh@edencaremedical.com' },
        { value: 'report@edencaremedical.com', label: 'report@edencaremedical.com' },
    ];

    useEffect(() => {
        const fetchScheduleData = async () => {
            try {
                const response = await fetch(`http://localhost:5020/prod/api/v1/cas/reporting/schedules/ekansh@edencaremedical.com/${reportId}`);
                const data = await response.json();
                setScheduleData(data);  // Update state with fetched data
            } catch (error) {
                console.error('Error fetching schedule data:', error);
            }
        };
        if (reportId) {
            fetchScheduleData(); // Fetch data when reportId is available
        }
    }, [reportId]);

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                <div className="report-settings-container">
                    {/* Report Title and Description */}
                    <div className="report-header">
                        <h2 className="report-title">{report?.report_name || 'Report Name'}</h2>
                        <p className="report-description">{report?.description || 'Report description goes here.'}</p>
                    </div>

                    {/* Schedule Table or No Schedule */}
                    {scheduleData.length > 0 ? (
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Frequency</th>
                                        <th>Day of Week</th>
                                        <th>Report Filter</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scheduleData.map((schedule, index) => (
                                        <tr key={index}>
                                            <td>{schedule.email}</td>
                                            <td>{schedule.frequency}</td>
                                            <td>{schedule.day_of_week || schedule.day_of_month}</td>
                                            <td>{schedule.date_range_type}</td>
                                            <td>
                                                <button
                                                    className="add-question-button pause-button"
                                                    onClick={() => handleTogglePause(index)}
                                                >
                                                    {schedule.isPaused ? 'Resume' : 'Pause'}
                                                </button>
                                                <button
                                                    className="add-question-button delete-button"
                                                    onClick={() => handleDeleteSchedule(index)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="modal-submit-button mar-t-2p" onClick={openModal}>
                                Schedule a New Report
                            </button>
                        </div>
                    ) : (
                        <div className="no-schedule-container">
                            <img src={noScheduleIcon} alt="No Schedule" className="no-schedule-icon" />
                            <p className="no-schedule-text">No schedule exists for this report.</p>
                            <button className="modal-submit-button mar-t-2p" onClick={openModal}>
                                Schedule a New Report
                            </button>
                        </div>
                    )}

                    <Modal
                        isOpen={isModalOpen}
                        onAfterOpen={() => {
                            setValidationErrors({}); // Reset validation errors when modal opens
                        }}
                        onRequestClose={closeModal}
                        contentLabel="Schedule this Report"
                        className="custom-modal"
                        overlayClassName="custom-overlay"
                    >
                        <div className="modal-header">
                            <h2>Schedule this Report</h2>
                            <button onClick={closeModal} className="modal-close-button">×</button>
                        </div>
                        <div className="modal-body">
                            {/* Render Mandatory Parameters First */}
                            {report?.requires_parameter && report.parameters?.map((param, index) => (
                                param.is_mandatory && (
                                    <div key={index} className="parameter-group-settings">
                                        <label className="modal-input-label">{param.parameter_description}</label>
                                        {param.parameter_component_type === 'Dropdown' && (
                                            <Select
                                                className="input-field search-select-field scheduler-modal-input"
                                                options={dropdownOptions[param.parameter_name]}
                                                isSearchable={true}
                                                placeholder="Search and select..."
                                                onChange={(selectedOption) => {
                                                    setNewSchedule(prev => ({
                                                        ...prev,
                                                        [param.parameter_name]: selectedOption.value
                                                    }));
                                                    setValidationErrors(prev => ({
                                                        ...prev,
                                                        [param.parameter_name]: ''
                                                    }));
                                                }}
                                            />
                                        )}
                                        {param.parameter_component_type === 'Text' && (
                                            <input
                                                type="text"
                                                className="input-field"
                                                placeholder={`Enter ${param.parameter_description}`}
                                                onChange={(e) => {
                                                    setNewSchedule(prev => ({
                                                        ...prev,
                                                        [param.parameter_name]: e.target.value
                                                    }));
                                                    setValidationErrors(prev => ({
                                                        ...prev,
                                                        [param.parameter_name]: ''
                                                    }));
                                                }}
                                            />
                                        )}
                                        {param.parameter_component_type === 'Date' && (
                                            <div className="date-range-picker">
                                                <label>Select Date:</label>
                                                <input
                                                    type="date"
                                                    className="input-field"
                                                    onChange={(e) => {
                                                        setNewSchedule(prev => ({
                                                            ...prev,
                                                            [param.parameter_name]: e.target.value
                                                        }));
                                                        setValidationErrors(prev => ({
                                                            ...prev,
                                                            [param.parameter_name]: ''
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {validationErrors[param.parameter_name] && (
                                            <span className="validation-error-message">{validationErrors[param.parameter_name]}</span>
                                        )}
                                    </div>
                                )
                            ))}

                            {/* Email Field */}
                            <div className="parameter-group-settings">
                                <label className="modal-input-label">Email</label>
                                <Select
                                    name="email"
                                    options={emailOptions}
                                    value={emailOptions.find(option => option.value === newSchedule.email)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'email')}
                                    className="input-field search-select-field scheduler-modal-input"
                                    isSearchable={true}
                                    placeholder="Search and select email"
                                />
                                {validationErrors.email && <span className="validation-error-message">{validationErrors.email}</span>}
                            </div>

                            {/* Frequency Field */}
                            <div className="parameter-group-settings">
                                <label className="modal-input-label">Frequency</label>
                                <select
                                    name="frequency"
                                    value={newSchedule.frequency}
                                    onChange={handleInputChange}
                                    required
                                    className="input-field scheduler-modal-input"
                                >
                                    <option value="">Select Frequency</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                </select>
                                {validationErrors.frequency && <span className="validation-error-message">{validationErrors.frequency}</span>}
                            </div>

                            {/* Conditional Fields for Weekly, Monthly, Quarterly */}
                            {newSchedule.frequency === 'Weekly' && (
                                <div className="parameter-group-settings">
                                    <label className="modal-input-label">Day of Week</label>
                                    <select
                                        name="dayOfWeek"
                                        value={newSchedule.dayOfWeek}
                                        onChange={handleInputChange}
                                        className="input-field scheduler-modal-input"
                                    >
                                        <option value="">Select Day</option>
                                        <option value="Sunday">Sunday</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                    </select>
                                    {validationErrors.dayOfWeek && <span className="validation-error-message">{validationErrors.dayOfWeek}</span>}
                                </div>
                            )}

                            {newSchedule.frequency === 'Monthly' && (
                                <div className="parameter-group-settings">
                                    <label className="modal-input-label">Day of Month</label>
                                    <input
                                        type="number"
                                        name="dayOfMonth"
                                        value={newSchedule.dayOfMonth}
                                        onChange={handleInputChange}
                                        className="input-field scheduler-modal-input"
                                        placeholder="Enter day of month"
                                        min={1}
                                        max={31}
                                    />
                                    {validationErrors.dayOfMonth && <span className="validation-error-message">{validationErrors.dayOfMonth}</span>}
                                </div>
                            )}

                            {newSchedule.frequency === 'Quarterly' && (
                                <>
                                    <div className="parameter-group-settings">
                                        <label className="modal-input-label">Which month of the quarter would you like to schedule the report?</label>
                                        <select
                                            name="monthInQuarter"
                                            value={newSchedule.monthInQuarter}
                                            onChange={handleInputChange}
                                            className="input-field scheduler-modal-input"
                                        >
                                            <option value="">Select Month</option>
                                            <option value="First">First Month</option>
                                            <option value="Second">Second Month</option>
                                            <option value="Third">Third Month</option>
                                        </select>
                                        {validationErrors.monthInQuarter && <span className="validation-error-message">{validationErrors.monthInQuarter}</span>}
                                    </div>

                                    <div className="parameter-group-settings">
                                        <label className="modal-input-label">Day of Month</label>
                                        <input
                                            type="number"
                                            name="dayOfMonth"
                                            value={newSchedule.dayOfMonth}
                                            onChange={handleInputChange}
                                            className="input-field scheduler-modal-input"
                                            placeholder="Enter day of month"
                                            min={1}
                                            max={31}
                                        />
                                        {validationErrors.dayOfMonth && <span className="validation-error-message">{validationErrors.dayOfMonth}</span>}
                                    </div>
                                </>
                            )}

                            {/* Report Filter Field */}
                            <div className="parameter-group-settings">
                                <label className="modal-input-label">Report Filter Condition</label>
                                <select
                                    name="reportFilter"
                                    value={newSchedule.reportFilter}
                                    onChange={handleInputChange}
                                    required
                                    className="input-field scheduler-modal-input"
                                >
                                    <option value="">Select Filter</option>
                                    <option value="FROM_START_OF_WEEK">FROM START OF WEEK</option>
                                    <option value="FROM_START_OF_MONTH">FROM START OF MONTH</option>
                                    <option value="FROM_START_OF_QUARTER">FROM START OF QUARTER</option>
                                    <option value="NO_DATE_RANGE">NO DATE RANGE</option>
                                </select>
                                {validationErrors.reportFilter && <span className="validation-error-message">{validationErrors.reportFilter}</span>}
                            </div>
                            {/* New Question: Raw Data or Insights */}
                            <div className="parameter-group-settings">
                                <label className="modal-input-label">Do you want to receive raw data or only insights in the report?</label>
                                <p className="modal-input-description">
                                    Insights provide a summary of the report, without the entire dataset. If you need detailed data, select raw data.
                                </p>
                                <select
                                    name="dataPreference"
                                    value={newSchedule.dataPreference || ''}
                                    onChange={handleInputChange}
                                    className="input-field scheduler-modal-input"
                                >
                                    <option value="">Select Preference</option>
                                    <option value="insights">Only Insights</option>
                                    <option value="rawData">Raw Data</option>
                                </select>
                                {validationErrors.dataPreference && <span className="validation-error-message">{validationErrors.dataPreference}</span>}
                            </div>

                            {/* Follow-up question if Raw Data is selected */}
                            {newSchedule.dataPreference === 'rawData' && (
                                <div className="parameter-group-settings">
                                    <label className="modal-input-label">In which format would you like to receive the report?</label>
                                    <select
                                        name="reportFormat"
                                        value={newSchedule.reportFormat || ''}
                                        onChange={handleInputChange}
                                        className="input-field scheduler-modal-input"
                                    >
                                        <option value="">Select Format</option>
                                        <option value="xlsx">File (XLSX Format)</option>
                                        <option value="csv">File (CSV Format)</option>
                                        <option value="web">Web Report</option>
                                    </select>
                                    {validationErrors.reportFormat && <span className="validation-error-message">{validationErrors.reportFormat}</span>}
                                </div>
                            )}

                            {errorMessage && <span className="validation-error-message">{errorMessage}</span>}
                        </div>
                        {errorMessage && <span className="validation-error-message">{errorMessage}</span>}
                        <div className="modal-footer">
                            {!loading && (
                                <button
                                    onClick={handleScheduleSubmit}
                                    className="modal-submit-button"
                                    disabled={loading} // Disable the button during loading
                                >
                                    Save Schedule
                                </button>
                            )}
                            {loading && (
                                <button className="modal-submit-button" disabled={true}>
                                    Loading...
                                </button>
                            )}
                        </div>
                    </Modal>
                </div>
            </div >
        </div >
    );
};

export default ReportSettings;
