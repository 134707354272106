import React, { useEffect, useState } from 'react';
import { BsFilter, BsSortDown, BsArrowClockwise, BsChevronExpand, BsTable, BsCardText } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import './../../assets/css/ReportViewer.css'; // Reusing the same CSS for consistency
import Sidebar from './../dashboard/Sidebar';
import TopBar from './../dashboard/TopBar';
import Modal from 'react-modal'; // For Modal
import DatePicker from 'react-datepicker'; // For Date Picker
import 'react-datepicker/dist/react-datepicker.css'; // Date picker styles
import Select from 'react-select';
import emptyBoxIcon from './../../assets/images/empty-box.png';
import noDataIcon from './../../assets/images/no-data-icon.png';


const ReportList = () => {
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns] = useState(['report_id', 'report_name', 'description']);
    const [visibleColumns, setVisibleColumns] = useState(['report_id', 'report_name', 'description']);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filterConfig, setFilterConfig] = useState({ query: '', column: '', values: {} });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(null);
    const navigate = useNavigate();
    const [hasError, setHasError] = useState(false);

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // State to track selected report for modal
    const [selectedReport, setSelectedReport] = useState(null);
    const [selectedParameters, setSelectedParameters] = useState({}); // Store all selected parameter values

    const [dropdownOptions, setDropdownOptions] = useState({}); // For preloaded data in dropdowns
    const [errorMessage, setErrorMessage] = useState('');

    const [reportOptions, setReportOptions] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState(null);

    const [isCardView, setIsCardView] = useState(false); // To toggle between table and card view


    const handleViewSwitch = () => {
        setIsCardView(!isCardView); // Toggle view state
    };

    const fetchReportNames = async () => {
        try {
            const response = await fetch('http://localhost:5020/prod/api/v1/cas/reporting/report-names');
            const data = await response.json();
            const options = data.map(report => ({
                value: report.report_id,
                label: report.report_name
            }));
            setReportOptions(options);
        } catch (error) {
            console.error("Error fetching report names:", error);
        }
    };



    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    const formatColumnName = (key) => {
        return key
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
    };

    const getUniqueValues = (column) => {
        const values = originalData.map(item => item[column]);
        return Array.from(new Set(values.map(value => (value === null || value === undefined ? 'null' : value.toString()))));
    };

    const fetchData = async () => {
        setLoading(true);
        setHasError(false);
        try {
            const response = await fetch('http://localhost:5020/prod/api/v1/cas/reporting/reports');
            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }
            const result = await response.json();
            if (!Array.isArray(result)) {
                throw new Error('Unexpected response format');
            }
            setData(result);
            setOriginalData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasError(true); // API failure
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSort = (column) => {
        let direction = 'ascending';
        if (sortConfig.key === column && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: column, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[column] < b[column]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[column] > b[column]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handleFilterByValues = (column, selectedValues) => {
        setFilterConfig(prev => ({
            ...prev,
            values: { ...prev.values, [column]: selectedValues }
        }));

        const filteredData = originalData.filter(item => {
            if (!selectedValues.length) return true;
            const value = item[column];
            if (selectedValues.includes('null') && (value === null || value === '')) return true;
            return selectedValues.includes(value?.toString());
        });

        setData(filteredData);
        setCurrentPage(1); // Reset to first page after filtering
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleFilter();
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleColumnVisibilityChange = (column) => {
        setVisibleColumns(prevState =>
            prevState.includes(column)
                ? prevState.filter(col => col !== column) // Hide the column
                : [...prevState, column] // Show the column
        );
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page when items per page changes
    };

    const handleFilter = () => {
        const { query } = filterConfig;
        if (!query) {
            setData(originalData); // Reset data if filter is cleared
            return;
        }

        const filteredData = originalData.filter(item =>
            // Use optional chaining (?.) and fallback to empty string if `name` or `description` is undefined
            (item?.report_id == query) ||
            (item?.report_name?.toLowerCase().includes(query.toLowerCase()) || '') ||
            (item?.description?.toLowerCase().includes(query.toLowerCase()) || '')
        );

        setData(filteredData);
        setCurrentPage(1); // Reset to first page after filtering
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const getPaginationGroup = () => {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
        let pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    const handleReportSettingsClick = (report) => {
        // Redirect to ReportSettings.js, passing reportId, reportName, and description
        navigate(`/report-settings/${report.report_id}`, {
            state: {
                report: report, // send report data as it is
                reportId: report.report_id,
                reportName: report.report_name,
                reportDescription: report.description,
                requiresParameter: report.requires_parameter, // Add this line
                reportParameters: report.parameters, // Add this line to pass parameters
            },
        });
    };


    const handleViewReport = (report) => {
        if (report.requires_parameter) {
            setSelectedReport(report); // Store the report for the modal
            setModalIsOpen(true); // Open modal for parameter input

            // Preload data for any "Search_Dropdown" parameters
            report.parameters.forEach(async (param) => {
                if (param.parameter_component_type === 'Dropdown') {
                    await loadDropdownOptions(param);
                }
            });
        } else {
            const constructedEndpoint = `http://localhost:5020/prod/api/v1/cas/reporting/run-aggregation/${report.report_id}`;
            navigate(`/report/${report.report_id}`, {
                state: {
                    reportEndpoint: constructedEndpoint,
                    reportName: report.report_name,
                    reportDescription: report.description,
                    enableAction: report.enable_action,
                    actionLabel: report.action_label,
                    actionReportId: report.action_report_id
                }
            });
        }
    };

    // Load data for Search_Dropdown
    const loadDropdownOptions = async (param) => {
        try {
            const response = await fetch(
                `http://localhost:5020/prod/api/v1/cas/reporting/run-aggregation/${param.pre_load_data_source.report_id}`,
                { method: 'POST' }
            );
            const result = await response.json();
            const options = result.map(item => ({
                label: item[param.pre_load_data_source.value_key],
                value: item[param.pre_load_data_source.parameter_key]
            }));
            setDropdownOptions(prevOptions => ({
                ...prevOptions,
                [param.parameter_name]: options
            }));
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
        }
    };

    // Handle form submission for parameters
    const handleModalSubmit = async () => {
        setLoading(true);
        setErrorMessage('');

        const requestBody = {};
        let hasValidationError = false; // Track if there are validation errors

        // Loop through parameters and build the request body
        selectedReport.parameters.forEach(param => {

            // Check if the parameter is mandatory and if the user provided a value
            if (param.is_mandatory && !selectedParameters[param.parameter_name]) {
                setErrorMessage(`The field "${param.parameter_label}" is required.`);
                hasValidationError = true;
                return; // Skip the rest of the logic if validation fails
            }

            if (param.parameter_component_type === 'Date') {
                const finalEndDate = endDate || new Date();
                requestBody[param.parameter_name] = {
                    "$gte": new Date(startDate).toISOString().substr(0, 10) + "T00:00:00.000Z",
                    "$lte": new Date(finalEndDate).toISOString().substr(0, 10) + "T23:59:59.000Z"
                };
            } else if (param.parameter_component_type === 'Dropdown') {
                // Check if the value for the dropdown parameter is not null or undefined
                if (selectedParameters[param.parameter_name] != null) {
                    if (param.parameter_data_type === 'Long') {
                        requestBody[param.parameter_name] = parseInt(selectedParameters[param.parameter_name], 10);
                    } else {
                        requestBody[param.parameter_name] = selectedParameters[param.parameter_name];
                    }
                }
            } else if (param.parameter_component_type === 'Text') {
                // Check if the value for the text parameter is not null or undefined
                if (selectedParameters[param.parameter_name] != null) {
                    if (param.parameter_data_type === 'Long') {
                        requestBody[param.parameter_name] = parseInt(selectedParameters[param.parameter_name], 10);
                    } else {
                        requestBody[param.parameter_name] = selectedParameters[param.parameter_name]; // Send as string by default
                    }
                }
            }
        });

        // If validation error occurred, stop further processing
        if (hasValidationError) {
            setLoading(false);
            return;
        }


        try {
            const constructedEndpoint = `http://localhost:5020/prod/api/v1/cas/reporting/run-aggregation/${selectedReport.report_id}`;
            const response = await fetch(constructedEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const result = await response.json();

            // After a successful API call, navigate to the report viewer
            navigate(`/report/${selectedReport.report_id}`, {
                state: {
                    reportEndpoint: constructedEndpoint,
                    reportName: selectedReport.report_name,
                    reportDescription: selectedReport.description,
                    enableAction: selectedReport.enable_action,
                    actionLabel: selectedReport.action_label,
                    actionReportId: selectedReport.action_report_id,
                    parameterData: result // Pass the parameters along for display or further use
                }
            });
        } catch (error) {
            console.error('Error fetching the report:', error);
            setErrorMessage('There was an error fetching the report. Please try again.');
        } finally {
            setLoading(false);
            setModalIsOpen(false);
            setStartDate(null);
            setEndDate(null);
            setSelectedParameters({});
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setStartDate(null);
        setEndDate(null);
        setSelectedParameters({});
    };

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">

                <TopBar modalIsOpen={modalIsOpen} />
                <div className="excel-view">
                    {/* Existing filter and sort section */}
                    <div className="excel-panel-wrapper">
                        <div className="excel-panel">
                            <div className="filter-section">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={filterConfig.query}
                                    onChange={(e) => {
                                        setFilterConfig({ ...filterConfig, query: e.target.value });
                                        if (e.target.value === '') {
                                            setData(originalData); // Reset to original data when search is cleared
                                            setCurrentPage(1); // Reset to first page
                                        }
                                    }}
                                    onKeyPress={handleKeyPress}
                                    className="input-field"
                                />
                                <button onClick={handleFilter} className="excel-panel-button">
                                    <BsFilter className="excel-panel-icon" />Filter
                                </button>
                            </div>
                            <button className="excel-panel-button"><BsSortDown className="excel-panel-icon" />Sort</button>
                            <button className="excel-panel-button" onClick={fetchData}>
                                <BsArrowClockwise className="excel-panel-icon" />Refresh
                            </button>
                            <button className="excel-panel-button" onClick={() => setShowColumnSelector(!showColumnSelector)}>
                                Show/Hide Columns
                            </button>
                            {/* Switch to Table/Card View button */}
                            <button onClick={() => setIsCardView(!isCardView)} className="excel-panel-button">
                                {isCardView ? (
                                    <>
                                        <BsTable className="view-switch-icon" />
                                        Table View
                                    </>
                                ) : (
                                    <>
                                        <BsCardText className="view-switch-icon" />
                                        Card View
                                    </>
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Column Selector */}
                    {showColumnSelector && (
                        <div className="column-selector">
                            {columns.map((column, index) => (
                                <div key={index} className="column-selector-item">
                                    <input
                                        type="checkbox"
                                        id={`col-${column}`}
                                        checked={visibleColumns.includes(column)}
                                        onChange={() => handleColumnVisibilityChange(column)}
                                    />
                                    <label htmlFor={`col-${column}`}>{formatColumnName(column)}</label>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Display error or no data */}
                    {hasError ? (
                        <div className="no-data-container">
                            <img src={noDataIcon} alt="No Data" className="no-data-icon" />
                            <p className="no-data-text">No reports found</p>
                            <p className="no-data-subtext">No reports available at the moment. Please check back later or try adjusting your filters.</p>
                        </div>
                    ) : loading ? (
                        <div className="loader">Loading...</div>
                    ) : currentData.length === 0 ? (
                        <div className="no-data-container">
                            <img src={noDataIcon} alt="No Data" className="no-data-icon" />
                            <p className="no-data-text">No reports found for matching results</p>
                            <p className="no-data-subtext">Your search did not return any results. Please try again with different keywords.</p>
                        </div>
                    ) : (
                        <>
                            {isCardView ? (
                                <div className="card-view-container">
                                    {currentData.map((report, index) => (
                                        <div key={index} className="report-card">
                                            <h3 className="report-title">{report.report_name}</h3>
                                            <p className="report-description">{report.description}</p>
                                            <button
                                                className="add-question-button"
                                                onClick={() => handleViewReport(report)}
                                            >
                                                View Report
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="table-container fixed-table-container">
                                    <table className='fixed-table'>
                                        <thead>
                                            <tr>
                                                {columns.map((column, index) => (
                                                    visibleColumns.includes(column) && (
                                                        <th key={index}>
                                                            <div className="column-header" onClick={() => handleSort(column)} style={{ cursor: 'pointer' }}>
                                                                <span>{formatColumnName(column)}</span>
                                                                <div className="filter-dropdown-wrapper">
                                                                    <BsChevronExpand
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // Prevent sorting on icon click
                                                                            setFilterDropdown(filterDropdown === column ? null : column);
                                                                        }}
                                                                        className="filter-dropdown-icon"
                                                                    />
                                                                    {filterDropdown === column && (
                                                                        <div className="filter-dropdown">
                                                                            {getUniqueValues(column).map((value, i) => (
                                                                                <div key={i}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={value}
                                                                                        checked={filterConfig.values[column]?.includes(value) || false}
                                                                                        onChange={(e) => {
                                                                                            const selectedValues = filterConfig.values[column] || [];
                                                                                            if (e.target.checked) {
                                                                                                handleFilterByValues(column, [...selectedValues, value]);
                                                                                            } else {
                                                                                                handleFilterByValues(column, selectedValues.filter(val => val !== value));
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label>{value === 'null' ? '(Blank/Empty)' : value}</label>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                ))}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((item, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {/* Render other columns */}
                                                    {columns.map((column, colIndex) => (

                                                        visibleColumns.includes(column) && (
                                                            <td key={colIndex}>
                                                                {column === 'report_id' ? (
                                                                    <a
                                                                        onClick={() => handleReportSettingsClick(item)}
                                                                        className="report-id-link"
                                                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                    >
                                                                        {item[column]}
                                                                    </a>
                                                                ) : (
                                                                    item[column]
                                                                )}
                                                            </td>
                                                        )
                                                    ))}
                                                    <td className="report-action">
                                                        {/* View Report Link */}
                                                        <a
                                                            onClick={() => handleViewReport(item)}
                                                            className="view-report-link"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            View Report
                                                        </a>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className="pagination">
                                <div className="items-per-page">
                                    <label htmlFor="itemsPerPage">Items per page: </label>
                                    <select
                                        id="itemsPerPage"
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        className="items-per-page-select"
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="pagination-controls">
                                    <button
                                        onClick={() => paginate(1)}
                                        className="pagination-button"
                                        disabled={currentPage === 1}
                                    >
                                        First
                                    </button>
                                    <button
                                        onClick={() => handlePageChange('prev')}
                                        className="pagination-button"
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    {getPaginationGroup().map(page => (
                                        <button
                                            key={page}
                                            onClick={() => paginate(page)}
                                            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                        >
                                            {page}
                                        </button>
                                    ))}
                                    {currentPage < totalPages - 2 && <span className="pagination-dots">...</span>}
                                    {currentPage < totalPages - 1 && (
                                        <button
                                            onClick={() => paginate(totalPages)}
                                            className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
                                        >
                                            {totalPages}
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handlePageChange('next')}
                                        className="pagination-button"
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                    <button
                                        onClick={() => paginate(totalPages)}
                                        className="pagination-button"
                                        disabled={currentPage === totalPages}
                                    >
                                        Last
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Modal for parameters */}
            {selectedReport && (
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setErrorMessage(''); // Reset values and error message when modal opens
                    }}
                    onRequestClose={closeModal}
                    contentLabel="Report Parameters"
                    className="custom-modal"
                    overlayClassName="custom-overlay"
                >
                    <div className="modal-header">
                        <h2>{`Filter results`}</h2>
                        <button onClick={closeModal} className="modal-close-button">×</button>
                    </div>
                    <div className="modal-body">
                        {selectedReport.parameters.map((param, index) => (
                            <div key={index} className="parameter-group">

                                <p className='modal-input-label'>
                                    {param.parameter_description} {param.is_mandatory ? '' : '(Optional)'}
                                </p>

                                {param.parameter_data_type === 'Date' && (
                                    <div className="date-range-picker">
                                        <label>Select Start Date:</label>
                                        <input
                                            type="date"
                                            className="input-field mar-t--10 mar-b-0"
                                            value={startDate ? startDate.toISOString().substr(0, 10) : ''}
                                            onChange={(e) => {
                                                setStartDate(new Date(e.target.value));
                                                setErrorMessage(''); // Clear error message on interaction
                                            }}
                                        />
                                        <label>Select End Date:</label>
                                        <input
                                            type="date"
                                            className="input-field mar-t--10 mar-b-0"
                                            value={endDate ? endDate.toISOString().substr(0, 10) : ''}
                                            onChange={(e) => {
                                                setEndDate(new Date(e.target.value));
                                                setErrorMessage(''); // Clear error message on interaction
                                            }}
                                        />
                                    </div>
                                )}

                                {param.parameter_component_type === 'Dropdown' && (
                                    <Select
                                        className="input-field search-select-field"
                                        options={dropdownOptions[param.parameter_name]}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                        isSearchable={true}
                                        placeholder="Search and select..."
                                        onChange={(selectedOption) => {
                                            setSelectedParameters(prev => ({
                                                ...prev,
                                                [param.parameter_name]: selectedOption.value
                                            }));
                                            setErrorMessage(''); // Clear error message on interaction
                                        }}
                                    />
                                )}
                                {param.parameter_component_type === 'Text' && (
                                    <input
                                        type="text"
                                        className="input-field"
                                        placeholder={`Enter ${param.parameter_label}`}
                                        onChange={(e) => {
                                            setSelectedParameters(prev => ({
                                                ...prev,
                                                [param.parameter_name]: e.target.value
                                            }));
                                            setErrorMessage(''); // Clear error message on interaction
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                        {errorMessage && <span className="validation-error-message">{errorMessage}</span>}
                    </div>

                    <div className="modal-footer">
                        {!loading && (
                            <button
                                onClick={handleModalSubmit}
                                className="modal-submit-button"
                                disabled={loading} // Disable the button when loading
                            >
                                Get Report
                            </button>
                        )}

                        {loading && (
                            <button className="modal-submit-button" disabled={true}>
                                Loading...
                            </button>
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );

};

export default ReportList;
