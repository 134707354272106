import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
import Sidebar from './../dashboard/Sidebar';
import TopBar from './../dashboard/TopBar';
import './../../assets/css/FormConfigurationsPage.css';
import 'react-quill/dist/quill.snow.css';

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        borderRadius: '10px',
        padding: '20px',
        border: '1px solid #d3e2fd',
        boxShadow: '0 1px 2px rgba(0,0,0,.1)',
        width: '50%',
    },
};

Modal.setAppElement('#root');

const FormConfigurationsPage = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [exportAnswers, setExportAnswers] = useState(false);
    const [webhookURL, setWebhookURL] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [sendEmail, setSendEmail] = useState(false);
    const [audienceList, setAudienceList] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [runCampaign, setRunCampaign] = useState(false);
    const [followUpTrigger, setFollowUpTrigger] = useState('');
    const [followUpInterval, setFollowUpInterval] = useState('');
    const [followUpEmailCount, setFollowUpEmailCount] = useState('');
    const [reminderEmailTime, setReminderEmailTime] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [redirectOption, setRedirectOption] = useState('url');
    const [redirectURL, setRedirectURL] = useState('');
    const [redirectMessage, setRedirectMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [webhookTestResult, setWebhookTestResult] = useState(null);
    const [sendReminderEmail, setSendReminderEmail] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const formData = location.state?.formData || {};

    const handleExportAnswersChange = (e) => {
        setExportAnswers(e.target.value === 'yes');
    };

    const handleSendEmailChange = (e) => {
        setSendEmail(e.target.value === 'yes');
    };

    const handleRunCampaignChange = (e) => {
        setRunCampaign(e.target.value === 'yes');
    };

    const handleSendReminderEmailChange = (e) => {
        setSendReminderEmail(e.target.value === 'yes');
    };

    const handleRedirectOptionChange = (e) => {
        setRedirectOption(e.target.value);
    };

    const handleOpenModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalContent('');
    };

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    const handleTestWebhook = async () => {
        try {
            const response = await fetch(webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken ? `Bearer ${authToken}` : undefined,
                },
                body: JSON.stringify({
                    message: "This is a test webhook from Formlytics",
                }),
            });

            if (response.ok) {
                setWebhookTestResult('Webhook test successful!');
            } else {
                setWebhookTestResult('Webhook test failed. Please check the endpoint and try again.');
            }
        } catch (error) {
            setWebhookTestResult('An error occurred during the webhook test. Please check the endpoint and try again.');
        }
    };

    const handleSubmit = () => {
        const finalFormData = {
            ...formData,
            exportAnswers,
            webhookURL: exportAnswers ? webhookURL : null,
            authToken: exportAnswers ? authToken : null,
            sendEmail,
            audienceList: sendEmail ? audienceList : null,
            emailSubject: sendEmail ? emailSubject : null,
            emailBody: sendEmail ? emailBody : null,
            runCampaign: sendEmail && runCampaign,
            followUpTrigger: runCampaign ? followUpTrigger : null,
            followUpInterval: runCampaign ? followUpInterval : null,
            followUpEmailCount: runCampaign ? followUpEmailCount : null,
            reminderEmailTime: sendReminderEmail ? reminderEmailTime : null,
            sendReminderEmail,
            campaignStartDate: sendEmail && runCampaign ? startDate : null,
            campaignEndDate: sendEmail && runCampaign ? endDate : null,
            redirectOption,
            redirectURL: redirectOption === 'url' ? redirectURL : null,
            redirectMessage: redirectOption === 'message' ? redirectMessage : null,
        };

        // Handle form submission logic
        console.log("Final Form Data:", finalFormData);

        // Redirect to the form summary page or wherever needed
        navigate('/form-summary', { state: { finalFormData } });
    };

    const handleCreateAudienceList = () => {
        navigate('/create-audience-list'); // Example navigation, adjust as needed
    };

    const handleBack = () => {
        navigate('/create-form', { state: { ...formData, step: 2 } });
    };

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                <div className="form-configurations-container">
                    <h2>Form Configurations1</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Do you want to export the form answers using an API? <span className="know-more" onClick={() => handleOpenModal('exportAnswers')}>Know more</span></label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        name="exportAnswers"
                                        value="yes"
                                        checked={exportAnswers === true}
                                        onChange={handleExportAnswersChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="exportAnswers"
                                        value="no"
                                        checked={exportAnswers === false}
                                        onChange={handleExportAnswersChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        {exportAnswers ? (
                            <>
                                <div className="form-group">
                                    <label>Webhook API Endpoint</label>
                                    <input
                                        type="text"
                                        name="webhookURL"
                                        value={webhookURL}
                                        onChange={(e) => setWebhookURL(e.target.value)}
                                        placeholder="Enter webhook API endpoint"
                                        className="input-field"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Authorization Token (optional)</label>
                                    <input
                                        type="text"
                                        name="authToken"
                                        value={authToken}
                                        onChange={(e) => setAuthToken(e.target.value)}
                                        placeholder="Enter authorization token"
                                        className="input-field"
                                    />
                                </div>
                                <button type="button" className="inner-button" onClick={handleTestWebhook}>Test Webhook Endpoint</button>
                                {webhookTestResult && <p className="webhook-test-result">{webhookTestResult}</p>}
                            </>
                        ) : (
                            <p className="info-message">
                                The form responses will be saved with Formlytics and accessible from responses.
                            </p>
                        )}

                        <div className="form-group">
                            <label>Do you want to send this form as an email to your audience? <span className="know-more" onClick={() => handleOpenModal('sendEmail')}>Know more</span></label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        name="sendEmail"
                                        value="yes"
                                        checked={sendEmail === true}
                                        onChange={handleSendEmailChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="sendEmail"
                                        value="no"
                                        checked={sendEmail === false}
                                        onChange={handleSendEmailChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        {sendEmail && (
                            <>
                                <div className="form-group">
                                    <label>Select Audience List</label>
                                    <select
                                        name="audienceList"
                                        value={audienceList}
                                        onChange={(e) => setAudienceList(e.target.value)}
                                        className="select-field"
                                    >
                                        <option value="" disabled>Select audience list</option>
                                        {/* Add your options here */}
                                    </select>
                                </div>
                                <button type="button" className="inner-button" onClick={handleCreateAudienceList}>Create an Audience List</button>

                                <div className="form-group">
                                    <label>Email Subject</label>
                                    <input
                                        type="text"
                                        name="emailSubject"
                                        value={emailSubject}
                                        onChange={(e) => setEmailSubject(e.target.value)}
                                        placeholder="Enter the email subject"
                                        className="input-field"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Email Body</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={emailBody}
                                        onChange={setEmailBody}
                                        placeholder={`Hi [Recipient Name],

We would like to invite you to participate in our survey.

Please click on the link below to access the form:

[Insert Form Title Here]

[Form Link]

Thank you for your time and participation.

Best regards,
[Your Company Name]`}
                                        className="email-body-editor"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Do you want to run a campaign for this form? <span className="know-more" onClick={() => handleOpenModal('runCampaign')}>Know more</span></label>
                                    <div className="radio-group">
                                        <label>
                                            <input
                                                type="radio"
                                                name="runCampaign"
                                                value="yes"
                                                checked={runCampaign === true}
                                                onChange={handleRunCampaignChange}
                                            />
                                            Yes
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="runCampaign"
                                                value="no"
                                                checked={runCampaign === false}
                                                onChange={handleRunCampaignChange}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>

                                {runCampaign && (
                                    <>
                                        <div className="form-group date-group">
                                            <div className="date-field">
                                                <label>Campaign Start Date</label>
                                                <input
                                                    type="date"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    className="input-field"
                                                />
                                            </div>
                                            <div className="date-field">
                                                <label>Campaign End Date (Optional)</label>
                                                <input
                                                    type="date"
                                                    name="endDate"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    className="input-field"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>When to trigger the next follow-up email?</label>
                                            <select
                                                name="followUpTrigger"
                                                value={followUpTrigger}
                                                onChange={(e) => setFollowUpTrigger(e.target.value)}
                                                className="select-field"
                                            >
                                                <option value="" disabled>Select trigger timing</option>
                                                <option value="1day">1 day after the first email</option>
                                                <option value="2days">2 days after the first email</option>
                                                <option value="3days">3 days after the first email</option>
                                                <option value="1week">1 week after the first email</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label>What should be the interval between follow-up emails?</label>
                                            <select
                                                name="followUpInterval"
                                                value={followUpInterval}
                                                onChange={(e) => setFollowUpInterval(e.target.value)}
                                                className="select-field"
                                            >
                                                <option value="" disabled>Select interval</option>
                                                <option value="1day">1 day</option>
                                                <option value="2days">2 days</option>
                                                <option value="3days">3 days</option>
                                                <option value="1week">1 week</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label>How many follow-up emails would you like to send?</label>
                                            <input
                                                type="number"
                                                name="followUpEmailCount"
                                                value={followUpEmailCount}
                                                onChange={(e) => setFollowUpEmailCount(e.target.value)}
                                                placeholder="Enter number of follow-up emails"
                                                className="input-field"
                                                min="1"
                                                max="10"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Would you like to send a reminder email? <span className="know-more" onClick={() => handleOpenModal('sendReminderEmail')}>Know more</span></label>
                                            <div className="radio-group">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="sendReminderEmail"
                                                        value="yes"
                                                        checked={sendReminderEmail === true}
                                                        onChange={handleSendReminderEmailChange}
                                                    />
                                                    Yes
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="sendReminderEmail"
                                                        value="no"
                                                        checked={sendReminderEmail === false}
                                                        onChange={handleSendReminderEmailChange}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        {sendReminderEmail && (
                                            <div className="form-group">
                                                <label>When would you like to send the reminder email?</label>
                                                <select
                                                    name="reminderEmailTime"
                                                    value={reminderEmailTime}
                                                    onChange={(e) => setReminderEmailTime(e.target.value)}
                                                    className="select-field"
                                                >
                                                    <option value="" disabled>Select reminder timing</option>
                                                    <option value="1day">1 day before campaign end date</option>
                                                    <option value="2days">2 days before campaign end date</option>
                                                    <option value="3days">3 days before campaign end date</option>
                                                    <option value="1week">1 week before campaign end date</option>
                                                </select>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        <div className="form-group">
                            <label>Form Submission Redirect <span className="know-more" onClick={() => handleOpenModal('formSubmissionRedirect')}>Know more</span></label>
                            <div className="radio-group mar-bot-10">
                                <label>
                                    <input
                                        type="radio"
                                        name="redirectOption"
                                        value="url"
                                        checked={redirectOption === 'url'}
                                        onChange={handleRedirectOptionChange}
                                    />
                                    Redirect to URL
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="redirectOption"
                                        value="message"
                                        checked={redirectOption === 'message'}
                                        onChange={handleRedirectOptionChange}
                                    />
                                    Display a Message
                                </label>
                            </div>

                            {redirectOption === 'url' && (
                                <div className="form-group">
                                    <label>Redirect URL</label>
                                    <input
                                        type="text"
                                        name="redirectURL"
                                        value={redirectURL}
                                        onChange={(e) => setRedirectURL(e.target.value)}
                                        placeholder="Enter the URL to redirect to after submission"
                                        className="input-field"
                                    />
                                </div>
                            )}

                            {redirectOption === 'message' && (
                                <div className="form-group">
                                    <label>Redirect Message</label>
                                    <textarea
                                        name="redirectMessage"
                                        value={redirectMessage}
                                        onChange={(e) => setRedirectMessage(e.target.value)}
                                        placeholder="Enter the message to display after submission"
                                        className="textarea-field"
                                    />
                                </div>
                            )}
                        </div>

                        <div className="modal-buttons">
                            <button type="submit" className="button">Submit Configurations</button>
                            <button type="button" className="button" onClick={handleBack}>Back</button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                style={customModalStyles}
                contentLabel="Information"
            >
                <h2>What does this mean?</h2>
                <div className="modal-content">
                    {modalContent === 'exportAnswers' && (
                        <p>
                            Exporting form answers using an API allows you to automatically send the form responses to an external system or service via a Webhook.
                            You can specify a Webhook API endpoint where the form data will be sent after each submission.
                            An optional authorization token can be used to secure the communication.
                        </p>
                    )}
                    {modalContent === 'sendEmail' && (
                        <p>
                            Sending the form as an email to your audience lets you distribute the form directly to the contacts in your audience list.
                            You can select an existing audience list or create a new one. Optionally, you can also run a campaign to track and manage the form submissions over a specific period.
                        </p>
                    )}
                    {modalContent === 'runCampaign' && (
                        <p>
                            Running a campaign allows you to set a specific time period during which the form will be actively distributed and tracked.
                            You can define the start and end dates for the campaign, making it easier to manage the form's visibility and collect responses within the specified time frame.
                            The end date is optional, allowing for ongoing campaigns if desired.
                        </p>
                    )}
                    {modalContent === 'sendReminderEmail' && (
                        <p>
                            Sending a reminder email ensures that participants who have not yet responded to the form receive a follow-up notification.
                            You can choose when to send the reminder email relative to the campaign's end date, helping to increase response rates before the campaign concludes.
                        </p>
                    )}
                    {modalContent === 'formSubmissionRedirect' && (
                        <p>
                            The Form Submission Redirect option allows you to decide what happens after a user submits the form.
                            You can either redirect them to a specific URL, such as a thank you page, or display a custom message directly on the screen.
                            Choose the option that best fits your form's flow and user experience goals.
                        </p>
                    )}
                </div>
                <div className="modal-buttons">
                    <button className="button" onClick={handleCloseModal}>Close</button>
                </div>
            </Modal>
        </div>
    );
};

export default FormConfigurationsPage;
