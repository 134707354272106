import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Sidebar from './../dashboard/Sidebar';
import TopBar from './../dashboard/TopBar';
import './../../assets/css/FormConfigurationsPage.css';
import Select from 'react-select';
import AceEditor from "react-ace"; // Importing react-ace for JSON editor
// Import JSON mode and theme for AceEditor
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools'; // To enable auto-completion

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        borderRadius: '10px',
        padding: '20px',
        border: '1px solid #d3e2fd',
        boxShadow: '0 1px 2px rgba(0,0,0,.1)',
        width: '50%',
    },
};

Modal.setAppElement('#root');

const CreateReport = () => {
    const navigate = useNavigate(); // Use navigate for redirecting




    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [reportName, setReportName] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [enableAction, setEnableAction] = useState(false);
    const [actionLabel, setActionLabel] = useState('');
    const [actionReportId, setActionReportId] = useState('');
    const [isSubEndpoint, setIsSubEndpoint] = useState(false);
    const [requiresParameter, setRequiresParameter] = useState(false);
    const [parameterName, setParameterName] = useState('');
    const [parameterLabel, setParameterLabel] = useState('');
    const [parameterType, setParameterType] = useState('');
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [showPipelineForm, setShowPipelineForm] = useState(false);
    const [collectionName, setCollectionName] = useState('');
    const [pipeline, setPipeline] = useState(''); // Default empty value for pipeline
    const [pipelineError, setPipelineError] = useState(''); // To store JSON validation error

    const [reportId, setReportId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pipelineLoading, setPipelineLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState('');
    const [finalStep, setFinalStep] = useState(false); // State to control the final step

    const [parameters, setParameters] = useState([]);

    const maxDescriptionLength = 400;

    const [reportOptions, setReportOptions] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState(null);

    const [responseKeys, setResponseKeys] = useState([]);

    const [selectedValueKey, setSelectedValueKey] = useState('');
    const [selectedParameterKey, setSelectedParameterKey] = useState('');

    const [isLookupReport, setIsLookupReport] = useState(false);



    const handlePipelineChange = (newValue) => {
        try {
            JSON.parse(newValue); // Try parsing the JSON to check for syntax errors
            setPipelineError(''); // If valid, clear the error
        } catch (err) {
            setPipelineError('Invalid JSON format'); // If invalid, show an error
        }
        setPipeline(newValue); // Update the pipeline value
    };

    const fetchReportNames = async () => {
        try {
            const response = await fetch('http://localhost:5020/prod/api/v1/cas/reporting/report-names');
            const data = await response.json();
            const options = data.map(report => ({
                value: report.report_id,
                label: report.report_name
            }));
            setReportOptions(options);
        } catch (error) {
            console.error("Error fetching report names:", error);
        }
    };

    const fetchAggregationData = async (reportId, index) => {
        try {
            const response = await fetch(`http://localhost:5020/prod/api/v1/cas/reporting/run-aggregation/${reportId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();

            if (data && data.length > 0) {
                const keys = Object.keys(data[0]);
                setResponseKeys(keys);

                // Optionally, set the first key as default in the input fields
                handlePreloadSourceChange(index, 'value_key', keys[0]);
                handlePreloadSourceChange(index, 'parameter_key', keys[0]);
            }
        } catch (error) {
            console.error("Error fetching aggregation data:", error);
        }
    };




    const handleAddParameter = () => {
        setParameters([...parameters, {
            parameter_name: '',
            parameter_label: '',
            parameter_description: '',
            parameter_data_type: '',
            parameter_component_type: '',
            is_mandatory: false,
        }]);
    };


    const handleParameterChange = (index, field, value) => {
        const updatedParameters = [...parameters];
        updatedParameters[index][field] = value;
        setParameters(updatedParameters);
    };

    /*
    const handlePreloadSourceChange = (index, field, value) => {
        setParameters((prevParameters) => {
            const updatedParameters = [...prevParameters];
            const currentParameter = updatedParameters[index];
            const updatedPreloadDataSource = {
                ...currentParameter.pre_load_data_source,
                [field]: value,
            };
            updatedParameters[index] = {
                ...currentParameter,
                pre_load_data_source: updatedPreloadDataSource,
            };
            return updatedParameters;
        });


        // If "Existing Report" is selected, fetch report names
        if (field === 'source' && value === 'Existing Report') {
            fetchReportNames();
        }
    };*/

    const handlePreloadSourceChange = (index, field, value) => {
        setParameters((prevParameters) => {
            const updatedParameters = [...prevParameters];
            const currentParameter = updatedParameters[index];
            const updatedPreloadDataSource = {
                ...currentParameter.pre_load_data_source,
                [field]: value,
            };
            updatedParameters[index] = {
                ...currentParameter,
                pre_load_data_source: updatedPreloadDataSource,
            };

            // Update selectedValueKey and selectedParameterKey if necessary
            if (field === 'value_key') {
                setSelectedValueKey(value);
            }
            if (field === 'parameter_key') {
                setSelectedParameterKey(value);
            }

            return updatedParameters;
        });

        // Fetch report names if source is selected as "Existing Report"
        if (field === 'source' && value === 'Existing Report') {
            fetchReportNames();
        }
    };




    const handleRemoveParameter = (indexToRemove) => {
        setParameters((prevParameters) => prevParameters.filter((_, index) => index !== indexToRemove));
    };


    const handleInputChange = (setter, fieldName) => (e) => {
        setter(e.target.value);
        if (errors[fieldName]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxDescriptionLength) {
            setReportDescription(value);

            if (errors.reportDescription) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    reportDescription: '',
                }));
            }
        }
    };

    const handleEnableActionChange = (e) => {
        setEnableAction(e.target.value === 'yes');
        setActionLabel('');
        setActionReportId('');
        setErrors((prevErrors) => ({
            ...prevErrors,
            actionLabel: '',
            actionReportId: '',
        }));
    };

    const handleIsSubEndpointChange = (e) => {
        setIsSubEndpoint(e.target.value === 'yes');
    };

    const handleRequiresParameterChange = (e) => {
        setRequiresParameter(e.target.value === 'yes');
    };

    const handleOpenModal = (content) => {
        let modalContent = '';

        if (content === 'configureParameter') {
            modalContent = `
                <h3>Configuring Parameters for Reports</h3>
                <p>Configuring parameters allows you to define specific inputs that users can provide when fetching a report. Parameters help in filtering or customizing the data that will be presented in the report.</p>
                <p><strong>Example:</strong> Let’s say you are creating a "Sales Report" and want users to be able to filter the report by date range. You can configure a parameter named "timestamp" that allows the user to input a start date and an end date. When the report is fetched, it will return the sales data within that specified date range.</p>
                <p><strong>Why configure parameters?</strong></p>
                <ul>
                    <li>Make your report dynamic by allowing users to fetch customized data.</li>
                    <li>Improve the performance of reports by narrowing down the data scope.</li>
                    <li>Enhance flexibility for different use cases (e.g., generating data for specific periods).</li>
                </ul>
                <p>Use parameters wisely to make your reports more interactive and tailored to specific needs!</p>
            `;
        } else if (content === 'parameterConfig') {
            modalContent = `
                <h3>Parameter Configuration Details</h3>
                <p>Here is a breakdown of the fields required for configuring a parameter:</p>
                <ul>
                    <li><strong>Parameter Name:</strong> This is the key or field name for the parameter. For example, if you want to filter data by date, you could use <code>created_timestamp</code> as the parameter name.</li>
                    <li><strong>Parameter Label:</strong> This is the label visible to the user. It helps them understand what the parameter is for. For example, <code>Date and Time Filter</code> can be used as a label for a timestamp filter.</li>
                    <li><strong>Parameter Description:</strong> A brief description of the parameter’s function. This will be visible to the user for guidance. For example, <code>Provide a Date range to filter Sales Records</code> can be the description for a date filter.</li>
                    <li><strong>Parameter Data Type:</strong> The type of data this parameter represents. Common data types include <code>String</code>, <code>Long</code>, <code>Date</code>, and <code>Boolean</code>. Refer to <a href="https://www.example.com/data-types" target="_blank">this link</a> for a comprehensive list of valid data types.</li>
                    <li><strong>Parameter Component Type:</strong> The component type refers to the UI element used to collect the parameter value from the user. For instance, this could be a <code>Dropdown</code>, <code>Text Input</code>, or <code>Date Picker</code>, depending on the nature of the parameter.</li>
                </ul>
                <p>Ensure you choose the appropriate data type and component type to make the parameter intuitive and useful for report users.</p>
            `;
        } else if (content === 'lookupReport') {
            modalContent = `
                <h3>What is a Lookup Report?</h3>
                <p>A Lookup Report is not directly visible to users in the Report List on the Dashboard.</p>
                <p>However, these reports can be used as a reference and linked as a data source for other reports.</p>
                <p>For example, if you have a master list of clients or products, you can create a Lookup Report to store that data, which can then be referenced when generating other reports.</p>
            `;
        }


        setModalContent(modalContent);
        setIsModalOpen(true);
    };



    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalContent('');
    };

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!reportName || reportName.length < 8) {
            newErrors.reportName = 'Report Name must be at least 8 characters long';
        }
        if (!reportDescription || reportDescription.length < 10) {
            newErrors.reportDescription = 'Report Description must be at least 10 characters long';
        }
        if (enableAction) {
            if (!actionLabel || actionLabel.length < 3 || actionLabel.length > 24) {
                newErrors.actionLabel = 'Action Label must be between 3 and 24 characters long';
            }
            if (!actionReportId || !/^\d{5,}$/.test(actionReportId)) {
                newErrors.actionReportId = 'Action Report ID must be an integer of at least 5 digits';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true); // Show loader and disable button

            console.log("isLookupReport ", isLookupReport);

            const formData = {
                report_name: reportName,
                description: reportDescription,
                is_lookup_report: isLookupReport,
                requires_parameter: !isLookupReport && requiresParameter,  // Only set this if it's not a lookup report
                parameters: !isLookupReport ? parameters.map((param) => ({
                    parameter_name: param.parameter_name,
                    parameter_label: param.parameter_label,
                    parameter_description: param.parameter_description,
                    parameter_data_type: param.parameter_data_type,
                    parameter_component_type: param.parameter_component_type,
                    is_mandatory: param.is_mandatory,
                    ...(param.parameter_component_type === 'Dropdown' && {
                        pre_load_data_source: {
                            source: param.pre_load_data_source?.source,
                            report_id: param.pre_load_data_source?.source === 'Existing Report' ? param.pre_load_data_source?.report_id : undefined,
                            value_key: param.pre_load_data_source?.source === 'Existing Report' ? param.pre_load_data_source?.value_key : selectedValueKey, // Use selected value key
                            parameter_key: param.pre_load_data_source?.source === 'Existing Report' ? param.pre_load_data_source?.parameter_key : selectedParameterKey, // Use selected parameter key
                            ...(param.pre_load_data_source?.source === 'Enum' && { enum_values: param.pre_load_data_source?.enum_values }),
                            ...(param.pre_load_data_source?.source === 'API' && { api_endpoint: param.pre_load_data_source?.api_endpoint }),
                        },
                    }),
                })) : [],  // Only include parameters if it's not a lookup report
            };






            // Remove undefined fields
            Object.keys(formData).forEach(key => formData[key] === undefined && delete formData[key]);

            try {
                const response = await fetch('http://localhost:5020/prod/api/v1/cas/reporting/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const result = await response.json();
                if (response.ok) {
                    console.log("Form created successfully:", result);
                    setReportId(result.report_id); // Store report ID
                    setShowPipelineForm(true); // Show the pipeline form
                } else {
                    console.error("Failed to create report:", result);
                }
            } catch (error) {
                console.error("Error creating report:", error);
            } finally {
                setLoading(false); // Hide loader
            }
        }
    };

    const handlePipelineFormSubmit = async (e) => {
        e.preventDefault();
        if (!collectionName) {
            setErrors({ collectionName: 'Collection Name is required' });
            return;
        }

        if (pipelineError && pipeline.length > 0) {
            setErrors({ pipeline: 'Pipeline has Invalid JSON format' });
            return;
        }

        setPipelineLoading(true); // Show loader and disable button
        const parsedPipeline = JSON.parse(pipeline);
        const pipelineFormData = {
            report_id: reportId,
            collection_name: collectionName,
            pipeline: Array.isArray(parsedPipeline) ? parsedPipeline : [parsedPipeline],
        };

        try {
            const response = await fetch('http://localhost:5020/prod/api/v1/cas/reporting/configure-pipeline', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pipelineFormData),
            });

            if (response.ok) {
                setApiResponse('Report created successfully.');
                setFinalStep(true); // Show the final step with the message and buttons
            } else {
                console.error("Failed to configure pipeline");
            }
        } catch (error) {
            console.error("Error configuring pipeline:", error);
        } finally {
            setPipelineLoading(false); // Hide loader
        }
    };

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                {!showPipelineForm ? (
                    <div className="form-configurations-container">
                        <h2>Create a Report</h2>
                        <p className='form-subtitle'>To create a report, start by entering the necessary report information in the form. In the second step, configure the report by defining the pipeline to determine how the data should be processed and presented.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Report Name</label>
                                <input
                                    type="text"
                                    name="reportName"
                                    value={reportName}
                                    onChange={handleInputChange(setReportName, 'reportName')}
                                    placeholder="Enter the report name"
                                    className={`input-field ${errors.reportName ? 'input-error' : ''}`}
                                />
                                {errors.reportName && <span className="error-message">{errors.reportName}</span>}
                            </div>

                            <div className="form-group">
                                <label>Report Description</label>
                                <textarea
                                    name="reportDescription"
                                    value={reportDescription}
                                    onChange={handleDescriptionChange}
                                    placeholder="Enter the report description"
                                    className={`textarea-field ${errors.reportDescription ? 'input-error' : ''}`}
                                />
                                <span className={`character-count ${reportDescription.length === maxDescriptionLength ? 'limit-reached' : ''}`}>
                                    {reportDescription.length === maxDescriptionLength
                                        ? "You've reached the maximum character limit"
                                        : `${reportDescription.length}/${maxDescriptionLength} characters`}
                                </span>
                                {errors.reportDescription && <span className="error-message">{errors.reportDescription}</span>}
                            </div>

                            <div className="form-group">
                                <label>Is this a Lookup Report? <span className="know-more" onClick={() => handleOpenModal('lookupReport')}>Know more</span></label>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            name="isLookupReport"
                                            value="yes"
                                            checked={isLookupReport === true}
                                            onChange={() => setIsLookupReport(true)}
                                        />
                                        Yes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="isLookupReport"
                                            value="no"
                                            checked={isLookupReport === false}
                                            onChange={() => setIsLookupReport(false)}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>

                            {!isLookupReport && (
                                <div className="form-group">
                                    <label>Do you want to configure the parameter for this report? <span className="know-more" onClick={() => handleOpenModal('configureParameter')}>Know more</span></label>
                                    <div className="radio-group">
                                        <label>
                                            <input
                                                type="radio"
                                                name="requiresParameter"
                                                value="yes"
                                                checked={requiresParameter === true}
                                                onChange={(e) => setRequiresParameter(true)}
                                            />
                                            Yes
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="requiresParameter"
                                                value="no"
                                                checked={requiresParameter === false}
                                                onChange={(e) => setRequiresParameter(false)}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            )}

                            {!isLookupReport && requiresParameter && (
                                <>
                                    {parameters.map((param, index) => (
                                        <div key={index} className="parameter-section">
                                            <h4>Parameter {index + 1} <span className="know-more" onClick={() => handleOpenModal('parameterConfig')}>Know more</span></h4>
                                            <div className="form-group">
                                                <label>Parameter Name</label>
                                                <input
                                                    type="text"
                                                    value={param.parameter_name}
                                                    onChange={(e) => handleParameterChange(index, 'parameter_name', e.target.value)}
                                                    className="input-field"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Parameter Label</label>
                                                <input
                                                    type="text"
                                                    value={param.parameter_label}
                                                    onChange={(e) => handleParameterChange(index, 'parameter_label', e.target.value)}
                                                    className="input-field"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Parameter Description</label>
                                                <textarea
                                                    value={param.parameter_description}
                                                    onChange={(e) => handleParameterChange(index, 'parameter_description', e.target.value)}
                                                    className="textarea-field"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Parameter Data Type</label>
                                                <select
                                                    value={param.parameter_data_type}
                                                    onChange={(e) => handleParameterChange(index, 'parameter_data_type', e.target.value)}
                                                    className="select-field"
                                                >
                                                    <option value="" disabled>Select Data Type</option>
                                                    <option value="Date">Date</option>
                                                    <option value="Long">Long</option>
                                                    <option value="String">String</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Parameter Component Type</label>
                                                <select
                                                    value={param.parameter_component_type}
                                                    onChange={(e) => handleParameterChange(index, 'parameter_component_type', e.target.value)}
                                                    className="select-field"
                                                >
                                                    <option value="" disabled>Select Component Type</option>
                                                    <option value="Date">Date</option>
                                                    <option value="Dropdown">Dropdown</option>
                                                    <option value="Text">Text</option>
                                                </select>
                                            </div>
                                            {param.parameter_component_type === 'Dropdown' && (
                                                <>
                                                    <div className="form-group">
                                                        <label>Configure Pre-load Data Source for this Dropdown</label>
                                                        <select
                                                            value={param.pre_load_data_source?.source || ''}
                                                            onChange={(e) => handlePreloadSourceChange(index, 'source', e.target.value)}
                                                            className="select-field"
                                                        >
                                                            <option value="" disabled>Select Source Type</option>
                                                            <option value="Existing Report">Existing Report</option>
                                                            <option value="Enum">Enum</option>
                                                            <option value="API">API</option>
                                                        </select>
                                                    </div>

                                                    {/* If source is "Existing Report" */}
                                                    {param.pre_load_data_source?.source === 'Existing Report' && (
                                                        <div className="form-group">
                                                            <label>Select the Lookup Report for this Source</label>
                                                            <Select
                                                                className="input-field search-select-field"
                                                                isSearchable={true}
                                                                placeholder="Search and select..."
                                                                options={reportOptions}
                                                                onChange={(selectedOption) => {
                                                                    handlePreloadSourceChange(index, 'report_id', selectedOption.value);
                                                                    if (param.pre_load_data_source?.source === 'Existing Report') {
                                                                        fetchAggregationData(selectedOption.value, index); // Call API with selected report_id
                                                                    }
                                                                }}
                                                                value={reportOptions.find(option => option.value === param.pre_load_data_source?.report_id) || null}
                                                            />
                                                        </div>
                                                    )}

                                                    {/* If source is "Enum" */}
                                                    {param.pre_load_data_source?.source === 'Enum' && (
                                                        <div className="form-group">
                                                            <label>Possible Values (Comma-separated)</label>
                                                            <input
                                                                type="text"
                                                                value={param.pre_load_data_source?.enum_values || ''}
                                                                onChange={(e) => handlePreloadSourceChange(index, 'enum_values', e.target.value)}
                                                                className="input-field"
                                                                placeholder="Enter possible values (e.g., Value1, Value2)"
                                                            />
                                                        </div>
                                                    )}

                                                    {/* If source is "API" */}
                                                    {param.pre_load_data_source?.source === 'API' && (
                                                        <div className="form-group">
                                                            <label>API Endpoint</label>
                                                            <input
                                                                type="text"
                                                                value={param.pre_load_data_source?.api_endpoint || ''}
                                                                onChange={(e) => handlePreloadSourceChange(index, 'api_endpoint', e.target.value)}
                                                                className="input-field"
                                                                placeholder="Enter API endpoint"
                                                            />
                                                        </div>
                                                    )}

                                                    {/* Value Key (Select if "Existing Report", input field otherwise) */}
                                                    <div className="form-group">
                                                        <label>Value Key (This is the key for which value will be loaded in the dropdown)</label>
                                                        {param.pre_load_data_source?.source === 'Existing Report' && responseKeys.length > 0 ? (
                                                            <Select
                                                                className="input-field search-select-field"
                                                                isSearchable={true}
                                                                placeholder="Select or search value key"
                                                                options={responseKeys.map((key) => ({ value: key, label: key }))}
                                                                onChange={(selectedOption) => handlePreloadSourceChange(index, 'value_key', selectedOption.value)} // Update the selected value key
                                                                value={responseKeys.includes(param.pre_load_data_source?.value_key)
                                                                    ? { value: param.pre_load_data_source?.value_key, label: param.pre_load_data_source?.value_key }
                                                                    : null
                                                                }
                                                            />

                                                        ) : (
                                                            <input
                                                                type="text"
                                                                value={param.pre_load_data_source?.value_key || ''}
                                                                onChange={(e) => handlePreloadSourceChange(index, 'value_key', e.target.value)}
                                                                className="input-field"
                                                                placeholder="Enter the value key"
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Parameter Key (Select if "Existing Report", input field otherwise) */}
                                                    <div className="form-group">
                                                        <label>Parameter Key (This is the parameter key passed in the report)</label>
                                                        {param.pre_load_data_source?.source === 'Existing Report' && responseKeys.length > 0 ? (
                                                            <Select
                                                                className="input-field search-select-field"
                                                                isSearchable={true}
                                                                placeholder="Select or search parameter key"
                                                                options={responseKeys.map((key) => ({ value: key, label: key }))}
                                                                onChange={(selectedOption) => handlePreloadSourceChange(index, 'parameter_key', selectedOption.value)} // Update the selected parameter key
                                                                value={responseKeys.includes(param.pre_load_data_source?.parameter_key)
                                                                    ? { value: param.pre_load_data_source?.parameter_key, label: param.pre_load_data_source?.parameter_key }
                                                                    : null
                                                                }
                                                            />

                                                        ) : (
                                                            <input
                                                                type="text"
                                                                value={param.pre_load_data_source?.parameter_key || ''}
                                                                onChange={(e) => handlePreloadSourceChange(index, 'parameter_key', e.target.value)}
                                                                className="input-field"
                                                                placeholder="Enter the parameter key"
                                                            />
                                                        )}
                                                    </div>





                                                </>
                                            )}

                                            <div className="form-group">
                                                <label>Is this parameter mandatory?</label>
                                                <div className="radio-group">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`mandatory-${index}`}
                                                            value="yes"
                                                            checked={param.is_mandatory === true}
                                                            onChange={(e) => handleParameterChange(index, 'is_mandatory', true)}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`mandatory-${index}`}
                                                            value="no"
                                                            checked={param.is_mandatory === false}
                                                            onChange={(e) => handleParameterChange(index, 'is_mandatory', false)}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            {/* Remove Button */}
                                            <button type="button" className="add-question-button remove-button" onClick={() => handleRemoveParameter(index)}>
                                                Remove Parameter
                                            </button>
                                        </div>
                                    ))}
                                    <button type="button" className="add-question-button" onClick={handleAddParameter}>
                                        Add Parameter
                                    </button>
                                </>
                            )}


                            <div className="modal-buttons">
                                <button type="submit" className="button" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Next'}
                                </button>
                            </div>
                        </form>
                    </div>
                ) : finalStep ? (
                    <div className="form-configurations-container">
                        <p className='form-title'>The report {reportName} has been saved and is ready to view</p>
                        <p className='form-subtitle'>{reportDescription}</p>
                        <div className="modal-buttons">
                            <button className="button" onClick={() => navigate('/dashboard')}>
                                View Report
                            </button>
                            <button className="button" onClick={() => navigate('/create-report')}>
                                Create another Report
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="form-configurations-container">
                        <h2>Configure Pipeline</h2>
                        <p>Please configure the collection name and pipeline below:</p>
                        <form onSubmit={handlePipelineFormSubmit}>
                            <div className="form-group">
                                <label>Collection Name <span className="know-more" onClick={() => handleOpenModal('collectionName')}>Know more</span></label>
                                <input
                                    type="text"
                                    name="collectionName"
                                    value={collectionName}
                                    onChange={handleInputChange(setCollectionName, 'collectionName')}
                                    placeholder="Enter collection name"
                                    className={`input-field ${errors.collectionName ? 'input-error' : ''}`}
                                />
                                {errors.collectionName && <span className="error-message">{errors.collectionName}</span>}
                            </div>

                            <div className="form-group">
                                <label>Pipeline <span className="know-more" onClick={() => handleOpenModal('pipeline')}>Know more</span></label>

                                <AceEditor
                                    mode="json"
                                    theme="github"
                                    name="pipeline-editor"
                                    value={pipeline}
                                    onChange={handlePipelineChange}
                                    fontSize={11}
                                    width="100%"
                                    height="300px"
                                    showPrintMargin={false}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        showLineNumbers: true,
                                        tabSize: 3,
                                        useWorker: false, // Disable the web worker to avoid the importScripts error
                                    }}
                                />

                                {errors.pipeline && <span className="error-message">{errors.pipeline}</span>}
                            </div>

                            <div className="modal-buttons">
                                <button type="submit" className="button" disabled={pipelineLoading}>
                                    {pipelineLoading ? 'Creating...' : 'Create Form'}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                style={customModalStyles}
                contentLabel="Information"
            >

                <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                <div className="modal-buttons">
                    <button className="button" onClick={handleCloseModal}>Close</button>
                </div>
            </Modal>
        </div>
    );
};

export default CreateReport;
