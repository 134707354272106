import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { validateFormDetails, validateQuestionDetails } from '../../utils/validationUtils';
import Sidebar from './../dashboard/Sidebar';
import TopBar from './../dashboard/TopBar';
import './../../assets/css/CreateFormPage.css';

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        borderRadius: '10px',
        padding: '20px',
        border: '1px solid #d3e2fd',
        boxShadow: '0 1px 2px rgba(0,0,0,.1)',
        width: '50%',
    },
};

Modal.setAppElement('#root');

const CreateFormPage = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [selectedFileName, setSelectedFileName] = useState('');
    const [formDetails, setFormDetails] = useState({
        formName: '',
        formURL: '',
        formDescription: '',
        webhookURL: '',
        authToken: '',
    });

    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);
    const [questions, setQuestions] = useState([
        { key: '', label: '', inputType: 'text', required: false, options: [], conditions: [] }
    ]);
    const [isPreConfiguredModalOpen, setIsPreConfiguredModalOpen] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
    const [currentConditionIndex, setCurrentConditionIndex] = useState(null);

    useEffect(() => {
        setErrors({});
    }, [formDetails, questions]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'formDescription' && value.length > 200) {
            return;
        }

        setFormDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleQuestionChange = (e, index) => {
        const { name, value, type, checked } = e.target;
        const updatedQuestions = [...questions];
        updatedQuestions[index][name] = type === 'checkbox' ? checked : value;
        setQuestions(updatedQuestions);

        if (errors[index] && errors[index][name]) {
            const updatedErrors = [...errors];
            updatedErrors[index][name] = '';
            setErrors(updatedErrors);
        }
    };

    const handleAddQuestion = () => {
        setQuestions([...questions, { key: '', label: '', inputType: 'text', required: false, options: [], conditions: [] }]);
    };

    const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };

    const handleAddOption = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].options.push({ label: '', key: '' });
        setQuestions(updatedQuestions);
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        setQuestions(updatedQuestions);
    };

    const handleOptionChange = (e, questionIndex, optionIndex) => {
        const { name, value } = e.target;
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex][name] = value;
        setQuestions(updatedQuestions);
    };

    const handleAddCondition = (questionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions.push({ type: 'equality', value: '', range: { min: '', max: '' }, followUpQuestion: { key: '', label: '', inputType: 'text', required: false, options: [] } });
        setQuestions(updatedQuestions);
    };

    const handleRemoveCondition = (questionIndex, conditionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions.splice(conditionIndex, 1);
        setQuestions(updatedQuestions);
    };

    const handleConditionChange = (e, questionIndex, conditionIndex) => {
        const { name, value } = e.target;
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions[conditionIndex][name] = value;
        setQuestions(updatedQuestions);
    };

    const handleFollowUpQuestionChange = (e, questionIndex, conditionIndex) => {
        const { name, value, type, checked } = e.target;
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions[conditionIndex].followUpQuestion[name] = type === 'checkbox' ? checked : value;
        setQuestions(updatedQuestions);
    };

    const handleAddFollowUpOption = (questionIndex, conditionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions[conditionIndex].followUpQuestion.options.push({ label: '', key: '' });
        setQuestions(updatedQuestions);
    };

    const handleRemoveFollowUpOption = (questionIndex, conditionIndex, optionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions[conditionIndex].followUpQuestion.options.splice(optionIndex, 1);
        setQuestions(updatedQuestions);
    };

    const handleFollowUpOptionChange = (e, questionIndex, conditionIndex, optionIndex) => {
        const { name, value } = e.target;
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].conditions[conditionIndex].followUpQuestion.options[optionIndex][name] = value;
        setQuestions(updatedQuestions);
    };

    const validateForm = () => {
        const newErrors = validateFormDetails(formDetails);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateQuestions = () => {
        const questionErrors = questions.map(validateQuestionDetails);
        const hasErrors = questionErrors.some((error) => Object.keys(error).length > 0);
        setErrors(questionErrors);
        return !hasErrors;
    };

    const removeFile = () => {
        setSelectedFileName('');
        fileInputRef.current.value = null;
    };

    const onFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log(selectedFile);
        setSelectedFileName(selectedFile.name);
    };

    const handleNextStep = () => {
        if (validateForm()) {
            setStep(2);
        }
    };

    const handlePrevStep = () => {
        setStep(1);
    };

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm() && validateQuestions()) {
            const formData = {
                logo: "https://formapp.sgp1.digitaloceanspaces.com/22c21ZXo/LhuZi3YrbFXK.png", // Example logo URL, replace with actual
                title: formDetails.formName,
                description: formDetails.formDescription,
                url: formDetails.formURL,
                webhookURL: formDetails.webhookURL,
                questions: questions.map((question) => {
                    const formattedQuestion = {
                        key: question.key,
                        label: question.label,
                        inputType: question.inputType,
                        required: question.required,
                    };

                    if (question.options.length > 0) {
                        formattedQuestion.options = question.options.map((option) => ({
                            label: option.label,
                            value: option.label, // Assuming label is used as the value
                        }));
                    }

                    if (question.conditions.length > 0) {
                        formattedQuestion.conditions = question.conditions.map((condition) => ({
                            type: condition.type,
                            value: condition.value,
                            range: condition.range,
                            followUpQuestion: condition.followUpQuestion,
                        }));
                    }

                    return formattedQuestion;
                }),
            };

            // Post form data to webhook URL
            try {
                await axios.post(formDetails.webhookURL, formData);
                // Use the formURL for navigation
                navigate(`/preview/${formDetails.formURL}`, { state: { formData } });
            } catch (error) {
                console.error("Error saving the form data", error);
            }
        }
    };

    const openPreConfiguredModal = (index, conditionIndex = null) => {
        setCurrentQuestionIndex(index);
        setCurrentConditionIndex(conditionIndex);
        setIsPreConfiguredModalOpen(true);
    };

    const closePreConfiguredModal = () => {
        setIsPreConfiguredModalOpen(false);
        setCurrentQuestionIndex(null);
        setCurrentConditionIndex(null);
    };

    const handleSelectPreConfigured = (type) => {
        const preConfiguredOptions = {
            industries: [
                { label: 'Technology', key: 'tech' },
                { label: 'Finance', key: 'finance' },
                // Add more industries here
            ],
            countries: [
                { label: 'United States', key: 'us' },
                { label: 'Canada', key: 'ca' },
                // Add more countries here
            ],
            gender: [
                { label: 'Male', key: 'male' },
                { label: 'Female', key: 'female' },
                { label: 'Other', key: 'other' },
            ],
            ageGroups: [
                { label: '18-24', key: '18-24' },
                { label: '25-34', key: '25-34' },
                { label: '35-44', key: '35-44' },
                { label: '45-54', key: '45-54' },
                { label: '55+', key: '55+' },
            ],
            jobTitles: [
                { label: 'Engineer', key: 'engineer' },
                { label: 'Manager', key: 'manager' },
                { label: 'Director', key: 'director' },
                { label: 'Executive', key: 'executive' },
            ],
            educationLevels: [
                { label: 'High School', key: 'high_school' },
                { label: 'Bachelor\'s', key: 'bachelor' },
                { label: 'Master\'s', key: 'master' },
                { label: 'Doctorate', key: 'doctorate' },
            ],
            maritalStatus: [
                { label: 'Single', key: 'single' },
                { label: 'Married', key: 'married' },
                { label: 'Divorced', key: 'divorced' },
                { label: 'Widowed', key: 'widowed' },
            ],
            paymentMethods: [
                { label: 'Credit Card', key: 'credit_card' },
                { label: 'Debit Card', key: 'debit_card' },
                { label: 'PayPal', key: 'paypal' },
                { label: 'Bank Transfer', key: 'bank_transfer' },
            ],
            languages: [
                { label: 'English', key: 'english' },
                { label: 'Spanish', key: 'spanish' },
                { label: 'French', key: 'french' },
                { label: 'German', key: 'german' },
            ],
            timeZones: [
                { label: 'Pacific Time (PT)', key: 'pt' },
                { label: 'Mountain Time (MT)', key: 'mt' },
                { label: 'Central Time (CT)', key: 'ct' },
                { label: 'Eastern Time (ET)', key: 'et' },
            ],
        };

        if (currentQuestionIndex !== null) {
            const updatedQuestions = [...questions];
            if (currentConditionIndex !== null) {
                updatedQuestions[currentQuestionIndex].conditions[currentConditionIndex].followUpQuestion.options = preConfiguredOptions[type];
            } else {
                updatedQuestions[currentQuestionIndex].options = preConfiguredOptions[type];
            }
            setQuestions(updatedQuestions);
        }

        closePreConfiguredModal();
    };

    const handlePreviewForm = () => {
        const formData = {
            logo: "https://formapp.sgp1.digitaloceanspaces.com/22c21ZXo/LhuZi3YrbFXK.png", // Example logo URL, replace with actual
            title: formDetails.formName,
            description: formDetails.formDescription,
            url: formDetails.formURL,
            webhookURL: formDetails.webhookURL,
            questions: questions.map((question) => {
                const formattedQuestion = {
                    key: question.key,
                    label: question.label,
                    inputType: question.inputType,
                    required: question.required,
                };

                if (question.options.length > 0) {
                    formattedQuestion.options = question.options.map((option) => ({
                        label: option.label,
                        value: option.label, // Assuming label is used as the value
                    }));
                }

                if (question.conditions.length > 0) {
                    formattedQuestion.conditions = question.conditions.map((condition) => ({
                        type: condition.type,
                        value: condition.value,
                        range: condition.range,
                        followUpQuestion: condition.followUpQuestion,
                    }));
                }

                return formattedQuestion;
            }),
        };

        localStorage.setItem('formPreviewData', JSON.stringify(formData));
        window.open('/form-preview', '_blank');
    };

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                <div className="create-form-container">
                    <h2>Create New Form</h2>
                    {step === 1 && (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Form Name</label>
                                <input
                                    type="text"
                                    name="formName"
                                    value={formDetails.formName}
                                    onChange={handleInputChange}
                                    placeholder="Enter form name"
                                    className="input-field"
                                />
                                {errors.formName && <span className="error-message">{errors.formName}</span>}
                            </div>
                            <div className="form-group">
                                <label>Form URL</label>
                                <input
                                    type="text"
                                    name="formURL"
                                    value={formDetails.formURL}
                                    onChange={handleInputChange}
                                    placeholder="Enter form URL"
                                    className="input-field"
                                />
                                {errors.formURL && <span className="error-message">{errors.formURL}</span>}
                            </div>
                            <div className="form-group">
                                <label>Form Description</label>
                                <textarea
                                    name="formDescription"
                                    value={formDetails.formDescription}
                                    onChange={handleInputChange}
                                    placeholder="Enter form description"
                                    className="textarea-field"
                                    maxLength="200"
                                />
                                {errors.formDescription && <span className="error-message">{errors.formDescription}</span>}
                                <div className="char-count">{formDetails.formDescription.length}/200</div>
                            </div>
                            <div className="form-group">
                                <label>Webhook URL</label>
                                <input
                                    type="text"
                                    name="webhookURL"
                                    value={formDetails.webhookURL}
                                    onChange={handleInputChange}
                                    placeholder="Enter webhook URL"
                                    className="input-field"
                                />
                                {errors.webhookURL && <span className="error-message">{errors.webhookURL}</span>}
                            </div>
                            <div className="form-group">
                                <label>Authorization Token</label>
                                <input
                                    type="text"
                                    name="authToken"
                                    value={formDetails.authToken}
                                    onChange={handleInputChange}
                                    placeholder="Enter authorization token"
                                    className="input-field"
                                />
                            </div>
                            <div className="modal-buttons">
                                <button type="button" className="button" onClick={handleNextStep}>Next</button>
                            </div>
                        </form>
                    )}
                    {step === 2 && (
                        <form onSubmit={handleSubmit}>
                            <h4>Configure Questions</h4>
                            {questions.map((question, index) => (
                                <div key={index} className="question-group">
                                    <div className="form-group">
                                        <label>Question Key</label>
                                        <input
                                            type="text"
                                            name="key"
                                            value={question.key}
                                            onChange={(e) => handleQuestionChange(e, index)}
                                            placeholder="Enter unique question key"
                                            className="input-field"
                                        />
                                        {errors[index]?.key && <span className="error-message">{errors[index].key}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label>Question Label</label>
                                        <input
                                            type="text"
                                            name="label"
                                            value={question.label}
                                            onChange={(e) => handleQuestionChange(e, index)}
                                            placeholder="Enter question label"
                                            className="input-field"
                                        />
                                        {errors[index]?.label && <span className="error-message">{errors[index].label}</span>}
                                        <div className="char-count">{question.label.length}/72</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Input Type</label>
                                        <select
                                            name="inputType"
                                            value={question.inputType}
                                            onChange={(e) => handleQuestionChange(e, index)}
                                            className="select-field"
                                        >
                                            <option value="text">Text</option>
                                            <option value="radio">Radio</option>
                                            <option value="dropdown">Dropdown</option>
                                            <option value="checkbox">Checkbox</option>
                                            <option value="date">Date picker</option>
                                            <option value="file">File</option>
                                        </select>
                                    </div>
                                    <div className="form-group switch-container">
                                        <label className="switch-label">Required</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="required"
                                                checked={question.required}
                                                onChange={(e) => handleQuestionChange(e, index)}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    {['dropdown', 'checkbox', 'radio'].includes(question.inputType) && (
                                        <div className="form-group">
                                            <label>
                                                <span className="preconfigured-link" onClick={() => openPreConfiguredModal(index)}>Select Pre-configured Options</span>
                                            </label>
                                            <div className="options-list">
                                                {question.options.map((option, optionIndex) => (
                                                    <div key={optionIndex} className="option-group">
                                                        <input
                                                            type="text"
                                                            name="label"
                                                            value={option.label}
                                                            onChange={(e) => handleOptionChange(e, index, optionIndex)}
                                                            placeholder="Option Label"
                                                            className="input-field"
                                                        />
                                                        <input
                                                            type="text"
                                                            name="key"
                                                            value={option.key}
                                                            onChange={(e) => handleOptionChange(e, index, optionIndex)}
                                                            placeholder="Option Key"
                                                            className="input-field"
                                                        />
                                                        <button type="button" className="remove-button" onClick={() => handleRemoveOption(index, optionIndex)}>Remove</button>
                                                    </div>
                                                ))}
                                                <button type="button" className="add-option-button" onClick={() => handleAddOption(index)}>Add Option</button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <label>
                                            <span className="preconfigured-link" onClick={() => handleAddCondition(index)}>Add Condition</span>
                                        </label>
                                        {question.conditions.map((condition, conditionIndex) => (
                                            <div key={conditionIndex} className="condition-group">
                                                <h5>Condition {conditionIndex + 1}</h5>
                                                <div className="form-group">
                                                    <label>Condition Type</label>
                                                    <select
                                                        name="type"
                                                        value={condition.type}
                                                        onChange={(e) => handleConditionChange(e, index, conditionIndex)}
                                                        className="select-field"
                                                    >
                                                        <option value="equality">Is equal to</option>
                                                        <option value="inequality">Is not equal to</option>
                                                        <option value="range">Is between</option>
                                                    </select>
                                                </div>
                                                {condition.type === 'range' ? (
                                                    <div className="form-group">
                                                        <label>Condition Range</label>
                                                        <input
                                                            type="text"
                                                            name="min"
                                                            value={condition.range.min}
                                                            onChange={(e) => handleConditionChange(e, index, conditionIndex)}
                                                            placeholder="Min value"
                                                            className="input-field"
                                                        />
                                                        <input
                                                            type="text"
                                                            name="max"
                                                            value={condition.range.max}
                                                            onChange={(e) => handleConditionChange(e, index, conditionIndex)}
                                                            placeholder="Max value"
                                                            className="input-field"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label>Condition Value</label>
                                                        <input
                                                            type="text"
                                                            name="value"
                                                            value={condition.value}
                                                            onChange={(e) => handleConditionChange(e, index, conditionIndex)}
                                                            placeholder="Enter condition value"
                                                            className="input-field"
                                                        />
                                                    </div>
                                                )}
                                                <div className="condition-remove-button">
                                                    <button type="button" className="remove-button" onClick={() => handleRemoveCondition(index, conditionIndex)}>Remove Condition</button>
                                                </div>
                                                <h5>Follow-up Question</h5>
                                                <div className="form-group">
                                                    <label>Question Key</label>
                                                    <input
                                                        type="text"
                                                        name="key"
                                                        value={condition.followUpQuestion.key}
                                                        onChange={(e) => handleFollowUpQuestionChange(e, index, conditionIndex)}
                                                        placeholder="Enter unique question key"
                                                        className="input-field"
                                                    />
                                                    {errors[index]?.conditions?.[conditionIndex]?.followUpQuestion?.key && <span className="error-message">{errors[index].conditions[conditionIndex].followUpQuestion.key}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>Question Label</label>
                                                    <input
                                                        type="text"
                                                        name="label"
                                                        value={condition.followUpQuestion.label}
                                                        onChange={(e) => handleFollowUpQuestionChange(e, index, conditionIndex)}
                                                        placeholder="Enter question label"
                                                        className="input-field"
                                                    />
                                                    {errors[index]?.conditions?.[conditionIndex]?.followUpQuestion?.label && <span className="error-message">{errors[index].conditions[conditionIndex].followUpQuestion.label}</span>}
                                                    <div className="char-count">{condition.followUpQuestion.label.length}/72</div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Input Type</label>
                                                    <select
                                                        name="inputType"
                                                        value={condition.followUpQuestion.inputType}
                                                        onChange={(e) => handleFollowUpQuestionChange(e, index, conditionIndex)}
                                                        className="select-field"
                                                    >
                                                        <option value="text">Text</option>
                                                        <option value="radio">Radio</option>
                                                        <option value="dropdown">Dropdown</option>
                                                        <option value="checkbox">Checkbox</option>
                                                        <option value="file">File</option>
                                                    </select>
                                                </div>
                                                <div className="form-group switch-container">
                                                    <label className="switch-label">Required</label>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="required"
                                                            checked={condition.followUpQuestion.required}
                                                            onChange={(e) => handleFollowUpQuestionChange(e, index, conditionIndex)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>
                                                {['dropdown', 'checkbox', 'radio'].includes(condition.followUpQuestion.inputType) && (
                                                    <div className="form-group">
                                                        <label>
                                                            <span className="preconfigured-link" onClick={() => openPreConfiguredModal(index, conditionIndex)}>Select Pre-configured Options</span>
                                                        </label>
                                                        <div className="options-list">
                                                            {condition.followUpQuestion.options.map((option, optionIndex) => (
                                                                <div key={optionIndex} className="option-group">
                                                                    <input
                                                                        type="text"
                                                                        name="label"
                                                                        value={option.label}
                                                                        onChange={(e) => handleFollowUpOptionChange(e, index, conditionIndex, optionIndex)}
                                                                        placeholder="Option Label"
                                                                        className="input-field"
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        name="key"
                                                                        value={option.key}
                                                                        onChange={(e) => handleFollowUpOptionChange(e, index, conditionIndex, optionIndex)}
                                                                        placeholder="Option Key"
                                                                        className="input-field"
                                                                    />
                                                                    <button type="button" className="remove-button" onClick={() => handleRemoveFollowUpOption(index, conditionIndex, optionIndex)}>Remove</button>
                                                                </div>
                                                            ))}
                                                            <button type="button" className="add-option-button" onClick={() => handleAddFollowUpOption(index, conditionIndex)}>Add Option</button>
                                                        </div>
                                                    </div>
                                                )}
                                                <hr className="condition-separator" />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="question-buttons">
                                        <button type="button" className="remove-button" onClick={() => handleRemoveQuestion(index)}>Remove</button>
                                        {index === questions.length - 1 && (
                                            <button type="button" className="add-question-button" onClick={handleAddQuestion}>Add Question</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="modal-buttons">
                                <button type="button" className="button" onClick={handlePrevStep}>Back</button>
                                <button type="button" className="button" onClick={handlePreviewForm}>Preview Form</button>
                                <button type="submit" className="button">Create Form</button>
                            </div>
                        </form>
                    )}
                </div>
                <Modal
                    isOpen={isPreConfiguredModalOpen}
                    onRequestClose={closePreConfiguredModal}
                    style={customModalStyles}
                    contentLabel="Select Pre-configured Options"
                >
                    <h2>Select Pre-configured Options</h2>
                    <ul className="preconfigured-options-list">
                        <li onClick={() => handleSelectPreConfigured('industries')}>Industries</li>
                        <li onClick={() => handleSelectPreConfigured('countries')}>Countries</li>
                        <li onClick={() => handleSelectPreConfigured('gender')}>Gender</li>
                        <li onClick={() => handleSelectPreConfigured('ageGroups')}>Age Groups</li>
                        <li onClick={() => handleSelectPreConfigured('jobTitles')}>Job Titles</li>
                        <li onClick={() => handleSelectPreConfigured('educationLevels')}>Education Levels</li>
                        <li onClick={() => handleSelectPreConfigured('maritalStatus')}>Marital Status</li>
                        <li onClick={() => handleSelectPreConfigured('paymentMethods')}>Payment Methods</li>
                        <li onClick={() => handleSelectPreConfigured('languages')}>Languages</li>
                        <li onClick={() => handleSelectPreConfigured('timeZones')}>Time Zones</li>
                    </ul>
                </Modal>
            </div>
        </div>
    );
};

export default CreateFormPage;
