import React, { useState } from 'react';
import Sidebar from './../dashboard/Sidebar';
import TopBar from './../dashboard/TopBar';
import './../../assets/css/SettingsPage.css';
import sendGridLogo from './../../assets/images/sendgrid.png'; // Make sure to add the logos in the correct path
import awsSesLogo from './../../assets/images/ses.png';

const SettingsPage = () => {

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const [activeTab, setActiveTab] = useState('emailSettings');
    const [emailProvider, setEmailProvider] = useState('');
    const [emailSettings, setEmailSettings] = useState({
        apiKey: '',
        fromEmail: '',
        fromName: '',
        awsAccessKey: '',
        awsSecretKey: '',
    });
    const [generalSettings, setGeneralSettings] = useState({
        name: '',
        email: '',
        companyName: '',
        password: '',
    });

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleEmailSettingsChange = (e) => {
        const { name, value } = e.target;
        setEmailSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleGeneralSettingsChange = (e) => {
        const { name, value } = e.target;
        setGeneralSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleProviderSelect = (provider) => {
        setEmailProvider(provider);
    };

    const handleSaveSettings = () => {
        // Implement save settings logic here
        console.log('Email Settings:', emailSettings);
        console.log('General Settings:', generalSettings);
    };

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                <div className="settings-container">
                    <h2>Settings</h2>
                    <div className="tabs">
                        <button
                            className={activeTab === 'emailSettings' ? 'active' : ''}
                            onClick={() => handleTabClick('emailSettings')}
                        >
                            Email Settings
                        </button>
                        <button
                            className={activeTab === 'generalSettings' ? 'active' : ''}
                            onClick={() => handleTabClick('generalSettings')}
                        >
                            General Settings
                        </button>
                    </div>
                    {activeTab === 'emailSettings' && (
                        <div className="email-settings">
                            <h3>Email Settings</h3>
                            <p className="context-info">
                                Link your existing email providers to send emails for any forms you create. Choose a provider and configure the necessary settings.
                            </p>
                            <div className="provider-selection">
                                <div
                                    className={`provider-option ${emailProvider === 'sendGrid' ? 'selected' : ''}`}
                                    onClick={() => handleProviderSelect('sendGrid')}
                                >
                                    <img src={sendGridLogo} alt="SendGrid" />
                                </div>
                                <div
                                    className={`provider-option ${emailProvider === 'awsSes' ? 'selected' : ''}`}
                                    onClick={() => handleProviderSelect('awsSes')}
                                >
                                    <img src={awsSesLogo} alt="AWS SES" />
                                </div>
                            </div>
                            {emailProvider && (
                                <>
                                    <div className="form-group">
                                        <label>API Key</label>
                                        <input
                                            type="text"
                                            name="apiKey"
                                            value={emailSettings.apiKey}
                                            onChange={handleEmailSettingsChange}
                                            className="input-field"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Email</label>
                                        <input
                                            type="text"
                                            name="fromEmail"
                                            value={emailSettings.fromEmail}
                                            onChange={handleEmailSettingsChange}
                                            className="input-field"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Name</label>
                                        <input
                                            type="text"
                                            name="fromName"
                                            value={emailSettings.fromName}
                                            onChange={handleEmailSettingsChange}
                                            className="input-field"
                                        />
                                    </div>
                                    {emailProvider === 'awsSes' && (
                                        <>
                                            <div className="form-group">
                                                <label>AWS Access Key</label>
                                                <input
                                                    type="text"
                                                    name="awsAccessKey"
                                                    value={emailSettings.awsAccessKey}
                                                    onChange={handleEmailSettingsChange}
                                                    className="input-field"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>AWS Secret Key</label>
                                                <input
                                                    type="text"
                                                    name="awsSecretKey"
                                                    value={emailSettings.awsSecretKey}
                                                    onChange={handleEmailSettingsChange}
                                                    className="input-field"
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {activeTab === 'generalSettings' && (
                        <div className="general-settings">
                            <h3>General Settings</h3>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={generalSettings.name}
                                    onChange={handleGeneralSettingsChange}
                                    className="input-field"
                                />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    value={generalSettings.email}
                                    onChange={handleGeneralSettingsChange}
                                    className="input-field"
                                />
                            </div>
                            <div className="form-group">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={generalSettings.companyName}
                                    onChange={handleGeneralSettingsChange}
                                    className="input-field"
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={generalSettings.password}
                                    onChange={handleGeneralSettingsChange}
                                    className="input-field"
                                />
                            </div>
                        </div>
                    )}
                    <div className="modal-buttons">
                        <button className="button" onClick={handleSaveSettings}>Save Settings</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
