import React, { useState } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { Bar, Line, Radar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    Filler,
    RadialLinearScale,
    ArcElement,
} from 'chart.js';
import './../../assets/css/GraphicalInsights.css';

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement, Filler, RadialLinearScale, ArcElement);

const sampleClientsData = [
    {
        id: 1,
        name: "John Doe",
        gender: "Male",
        dob: "1989-02-15",
        age: 34,
        location: "Kigali, Rwanda",
        chronicCondition: "Diabetes",
        bmi: 28.9,
        smokingStatus: "Yes",
        physicalActivity: 70,
        diet: 80,
        mentalHealth: 75,
        claimFrequency: [2, 1, 3, 0, 4, 1],
        claimAmount: [500, 200, 700, 0, 1000, 250],
        riskScore: 60,
        ecc: [500, 600, 700, 800, 750, 820],
        preventiveCareOpportunities: ["Hypertension", "Obesity"],
        monthlyInterventionSuccess: [20, 30, 40, 50, 45, 55],
        healthScore: 250,
        healthScoreCategory: "Average",
        medicalFraudProbability: 60,
        sanitationScore: 66,
        earlyClaimProbability: 40,
        smokingImpact: 90,
        alcoholImpact: 70,
        sentimentScores: { positive: 0.00153, neutral: 0.00153, negative: 0.00153 },
        weatherInsights: [
            { status: "At Risk", condition: "Arthritis Pain Forecast", value: 4.2 },
            { status: "At High Risk", condition: "Asthma Forecast", value: 7.2 },
            { status: "At High Risk", condition: "COPD Forecast", value: 2.5 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.7 },
        ],
    },
    {
        id: 2,
        name: "Alice Mukamana",
        gender: "Female",
        dob: "1992-08-25",
        age: 31,
        location: "Gisenyi, Rwanda",
        chronicCondition: "Asthma",
        bmi: 22.4,
        smokingStatus: "No",
        physicalActivity: 80,
        diet: 85,
        mentalHealth: 78,
        claimFrequency: [1, 0, 2, 1, 3, 1],
        claimAmount: [300, 0, 400, 200, 700, 300],
        riskScore: 55,
        ecc: [400, 420, 450, 500, 520, 550],
        preventiveCareOpportunities: ["Respiratory Health"],
        monthlyInterventionSuccess: [15, 25, 35, 45, 50, 60],
        healthScore: 300,
        healthScoreCategory: "Good",
        medicalFraudProbability: 20,
        sanitationScore: 72,
        earlyClaimProbability: 30,
        smokingImpact: 0,
        alcoholImpact: 40,
        sentimentScores: { positive: 0.002, neutral: 0.003, negative: 0.001 },
        weatherInsights: [
            { status: "Beneficial", condition: "Arthritis Pain Forecast", value: 1.5 },
            { status: "At Moderate Risk", condition: "Asthma Forecast", value: 5.5 },
            { status: "At High Risk", condition: "COPD Forecast", value: 3.0 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.0 },
        ],
    },
    {
        id: 3,
        name: "Jean Paul Uwizeye",
        gender: "Male",
        dob: "1985-05-10",
        age: 39,
        location: "Huye, Rwanda",
        chronicCondition: "Hypertension",
        bmi: 29.5,
        smokingStatus: "Yes",
        physicalActivity: 65,
        diet: 60,
        mentalHealth: 70,
        claimFrequency: [3, 2, 4, 1, 2, 3],
        claimAmount: [600, 500, 700, 200, 300, 400],
        riskScore: 70,
        ecc: [600, 700, 800, 750, 900, 950],
        preventiveCareOpportunities: ["Cardiovascular Health"],
        monthlyInterventionSuccess: [30, 35, 40, 50, 55, 65],
        healthScore: 180,
        healthScoreCategory: "Low",
        medicalFraudProbability: 45,
        sanitationScore: 60,
        earlyClaimProbability: 55,
        smokingImpact: 85,
        alcoholImpact: 65,
        sentimentScores: { positive: 0.001, neutral: 0.004, negative: 0.003 },
        weatherInsights: [
            { status: "At High Risk", condition: "Arthritis Pain Forecast", value: 6.0 },
            { status: "At Moderate Risk", condition: "Asthma Forecast", value: 5.0 },
            { status: "At Risk", condition: "COPD Forecast", value: 4.5 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 2.0 },
        ],
    },
    {
        id: 4,
        name: "Grace Uwase",
        gender: "Female",
        dob: "1995-11-12",
        age: 28,
        location: "Nyamata, Rwanda",
        chronicCondition: "None",
        bmi: 23.1,
        smokingStatus: "No",
        physicalActivity: 85,
        diet: 90,
        mentalHealth: 85,
        claimFrequency: [0, 1, 0, 1, 1, 0],
        claimAmount: [0, 250, 0, 300, 350, 0],
        riskScore: 40,
        ecc: [300, 320, 340, 350, 400, 420],
        preventiveCareOpportunities: ["Cancer Screening"],
        monthlyInterventionSuccess: [20, 25, 30, 35, 40, 45],
        healthScore: 600,
        healthScoreCategory: "Excellent",
        medicalFraudProbability: 10,
        sanitationScore: 75,
        earlyClaimProbability: 25,
        smokingImpact: 0,
        alcoholImpact: 20,
        sentimentScores: { positive: 0.004, neutral: 0.001, negative: 0.0005 },
        weatherInsights: [
            { status: "Beneficial", condition: "Arthritis Pain Forecast", value: 1.2 },
            { status: "Low Risk", condition: "Asthma Forecast", value: 2.0 },
            { status: "Low Risk", condition: "COPD Forecast", value: 1.0 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.5 },
        ],
    },
    {
        id: 5,
        name: "Olivier Habimana",
        gender: "Male",
        dob: "1978-03-14",
        age: 46,
        location: "Rwamagana, Rwanda",
        chronicCondition: "Cholesterol",
        bmi: 28.7,
        smokingStatus: "Yes",
        physicalActivity: 55,
        diet: 65,
        mentalHealth: 68,
        claimFrequency: [3, 3, 4, 2, 3, 5],
        claimAmount: [500, 600, 700, 450, 800, 900],
        riskScore: 75,
        ecc: [700, 750, 800, 850, 900, 950],
        preventiveCareOpportunities: ["Heart Disease"],
        monthlyInterventionSuccess: [25, 30, 40, 45, 50, 60],
        healthScore: 220,
        healthScoreCategory: "Low",
        medicalFraudProbability: 55,
        sanitationScore: 50,
        earlyClaimProbability: 60,
        smokingImpact: 90,
        alcoholImpact: 75,
        sentimentScores: { positive: 0.002, neutral: 0.002, negative: 0.004 },
        weatherInsights: [
            { status: "At Risk", condition: "Arthritis Pain Forecast", value: 5.5 },
            { status: "At Moderate Risk", condition: "Asthma Forecast", value: 6.5 },
            { status: "High Risk", condition: "COPD Forecast", value: 4.0 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 2.5 },
        ],
    },
    {
        id: 6,
        name: "Marie Claire Niyonzima",
        gender: "Female",
        dob: "1990-04-18",
        age: 34,
        location: "Musanze, Rwanda",
        chronicCondition: "Diabetes",
        bmi: 27.5,
        smokingStatus: "No",
        physicalActivity: 60,
        diet: 75,
        mentalHealth: 80,
        claimFrequency: [2, 1, 2, 3, 1, 2],
        claimAmount: [400, 300, 500, 600, 200, 350],
        riskScore: 65,
        ecc: [500, 550, 600, 620, 640, 680],
        preventiveCareOpportunities: ["Blood Sugar Management"],
        monthlyInterventionSuccess: [25, 35, 40, 45, 50, 60],
        healthScore: 290,
        healthScoreCategory: "Good",
        medicalFraudProbability: 25,
        sanitationScore: 68,
        earlyClaimProbability: 35,
        smokingImpact: 0,
        alcoholImpact: 45,
        sentimentScores: { positive: 0.003, neutral: 0.002, negative: 0.001 },
        weatherInsights: [
            { status: "At Risk", condition: "Arthritis Pain Forecast", value: 3.2 },
            { status: "At Moderate Risk", condition: "Asthma Forecast", value: 4.8 },
            { status: "At Low Risk", condition: "COPD Forecast", value: 1.9 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.6 },
        ],
    },
    {
        id: 7,
        name: "Eric Ndayambaje",
        gender: "Male",
        dob: "1975-12-20",
        age: 48,
        location: "Kibungo, Rwanda",
        chronicCondition: "Hypertension",
        bmi: 31.0,
        smokingStatus: "Yes",
        physicalActivity: 50,
        diet: 60,
        mentalHealth: 65,
        claimFrequency: [4, 3, 5, 3, 4, 3],
        claimAmount: [700, 600, 800, 500, 700, 650],
        riskScore: 80,
        ecc: [800, 850, 900, 950, 1000, 1100],
        preventiveCareOpportunities: ["Blood Pressure Management"],
        monthlyInterventionSuccess: [20, 30, 40, 35, 50, 55],
        healthScore: 150,
        healthScoreCategory: "Low",
        medicalFraudProbability: 50,
        sanitationScore: 58,
        earlyClaimProbability: 70,
        smokingImpact: 95,
        alcoholImpact: 60,
        sentimentScores: { positive: 0.001, neutral: 0.003, negative: 0.004 },
        weatherInsights: [
            { status: "At High Risk", condition: "Arthritis Pain Forecast", value: 6.0 },
            { status: "At High Risk", condition: "Asthma Forecast", value: 7.0 },
            { status: "Moderate Risk", condition: "COPD Forecast", value: 3.8 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 2.1 },
        ],
    },
    {
        id: 8,
        name: "Florence Uwera",
        gender: "Female",
        dob: "1987-06-28",
        age: 36,
        location: "Butare, Rwanda",
        chronicCondition: "None",
        bmi: 22.9,
        smokingStatus: "No",
        physicalActivity: 90,
        diet: 85,
        mentalHealth: 88,
        claimFrequency: [1, 0, 1, 0, 1, 1],
        claimAmount: [300, 0, 350, 0, 400, 300],
        riskScore: 35,
        ecc: [300, 320, 330, 340, 350, 380],
        preventiveCareOpportunities: ["Health Check-up"],
        monthlyInterventionSuccess: [10, 20, 30, 35, 40, 45],
        healthScore: 650,
        healthScoreCategory: "Excellent",
        medicalFraudProbability: 15,
        sanitationScore: 78,
        earlyClaimProbability: 15,
        smokingImpact: 0,
        alcoholImpact: 30,
        sentimentScores: { positive: 0.005, neutral: 0.001, negative: 0.0003 },
        weatherInsights: [
            { status: "Beneficial", condition: "Arthritis Pain Forecast", value: 1.3 },
            { status: "Low Risk", condition: "Asthma Forecast", value: 1.8 },
            { status: "Low Risk", condition: "COPD Forecast", value: 1.1 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.2 },
        ],
    },
    {
        id: 9,
        name: "Isaac Mugiraneza",
        gender: "Male",
        dob: "1991-09-15",
        age: 32,
        location: "Nyanza, Rwanda",
        chronicCondition: "Obesity",
        bmi: 33.5,
        smokingStatus: "No",
        physicalActivity: 45,
        diet: 55,
        mentalHealth: 60,
        claimFrequency: [2, 2, 3, 2, 3, 4],
        claimAmount: [450, 400, 500, 450, 600, 700],
        riskScore: 78,
        ecc: [700, 750, 800, 820, 900, 950],
        preventiveCareOpportunities: ["Weight Management", "Nutrition Counseling"],
        monthlyInterventionSuccess: [30, 40, 45, 50, 60, 65],
        healthScore: 180,
        healthScoreCategory: "Low",
        medicalFraudProbability: 30,
        sanitationScore: 64,
        earlyClaimProbability: 55,
        smokingImpact: 0,
        alcoholImpact: 50,
        sentimentScores: { positive: 0.002, neutral: 0.0025, negative: 0.003 },
        weatherInsights: [
            { status: "At High Risk", condition: "Arthritis Pain Forecast", value: 5.8 },
            { status: "Moderate Risk", condition: "Asthma Forecast", value: 4.5 },
            { status: "Moderate Risk", condition: "COPD Forecast", value: 3.0 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 2.0 },
        ],
    },
    {
        id: 10,
        name: "Aline Mukashyaka",
        gender: "Female",
        dob: "1994-07-07",
        age: 29,
        location: "Kibuye, Rwanda",
        chronicCondition: "Asthma",
        bmi: 24.8,
        smokingStatus: "No",
        physicalActivity: 80,
        diet: 82,
        mentalHealth: 85,
        claimFrequency: [2, 1, 2, 1, 1, 2],
        claimAmount: [350, 300, 450, 400, 300, 500],
        riskScore: 58,
        ecc: [400, 450, 500, 520, 560, 600],
        preventiveCareOpportunities: ["Respiratory Health"],
        monthlyInterventionSuccess: [20, 25, 30, 40, 45, 50],
        healthScore: 310,
        healthScoreCategory: "Good",
        medicalFraudProbability: 20,
        sanitationScore: 70,
        earlyClaimProbability: 25,
        smokingImpact: 0,
        alcoholImpact: 35,
        sentimentScores: { positive: 0.004, neutral: 0.002, negative: 0.001 },
        weatherInsights: [
            { status: "Moderate Risk", condition: "Arthritis Pain Forecast", value: 3.5 },
            { status: "High Risk", condition: "Asthma Forecast", value: 6.5 },
            { status: "Moderate Risk", condition: "COPD Forecast", value: 4.0 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 2.3 },
        ],
    },
    {
        id: 11,
        name: "Jacques Habyarimana",
        gender: "Male",
        dob: "1983-02-10",
        age: 41,
        location: "Rubavu, Rwanda",
        chronicCondition: "Hypertension",
        bmi: 32.0,
        smokingStatus: "Yes",
        physicalActivity: 48,
        diet: 58,
        mentalHealth: 70,
        claimFrequency: [3, 2, 3, 3, 4, 3],
        claimAmount: [500, 450, 600, 500, 700, 650],
        riskScore: 75,
        ecc: [650, 700, 750, 800, 850, 900],
        preventiveCareOpportunities: ["Cardiovascular Disease Prevention"],
        monthlyInterventionSuccess: [25, 30, 35, 40, 45, 50],
        healthScore: 200,
        healthScoreCategory: "Low",
        medicalFraudProbability: 45,
        sanitationScore: 55,
        earlyClaimProbability: 65,
        smokingImpact: 88,
        alcoholImpact: 72,
        sentimentScores: { positive: 0.001, neutral: 0.003, negative: 0.005 },
        weatherInsights: [
            { status: "At High Risk", condition: "Arthritis Pain Forecast", value: 5.5 },
            { status: "High Risk", condition: "Asthma Forecast", value: 7.5 },
            { status: "Moderate Risk", condition: "COPD Forecast", value: 4.0 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 2.4 },
        ],
    },
    {
        id: 12,
        name: "Nadine Uwamahoro",
        gender: "Female",
        dob: "1997-03-05",
        age: 27,
        location: "Muhanga, Rwanda",
        chronicCondition: "None",
        bmi: 21.5,
        smokingStatus: "No",
        physicalActivity: 95,
        diet: 92,
        mentalHealth: 90,
        claimFrequency: [0, 1, 0, 1, 0, 0],
        frequency: [0, 1, 0, 1, 0, 0],
        claimAmount: [0, 200, 0, 300, 0, 0],
        riskScore: 25,
        ecc: [200, 220, 230, 240, 250, 260],
        preventiveCareOpportunities: ["Regular Health Check-up"],
        monthlyInterventionSuccess: [15, 20, 25, 30, 35, 40],
        healthScore: 680,
        healthScoreCategory: "Excellent",
        medicalFraudProbability: 5,
        sanitationScore: 85,
        earlyClaimProbability: 10,
        smokingImpact: 0,
        alcoholImpact: 15,
        sentimentScores: { positive: 0.0055, neutral: 0.0008, negative: 0.0002 },
        weatherInsights: [
            { status: "Beneficial", condition: "Arthritis Pain Forecast", value: 1.0 },
            { status: "Low Risk", condition: "Asthma Forecast", value: 1.5 },
            { status: "Low Risk", condition: "COPD Forecast", value: 1.1 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.3 },
        ],
    },
    {
        id: 13,
        name: "Emmanuel Mugenzi",
        gender: "Male",
        dob: "1981-01-11",
        age: 43,
        location: "Gitarama, Rwanda",
        chronicCondition: "Hypertension",
        bmi: 30.5,
        smokingStatus: "Yes",
        physicalActivity: 40,
        diet: 55,
        mentalHealth: 60,
        claimFrequency: [4, 3, 4, 3, 5, 4],
        claimAmount: [600, 500, 700, 600, 800, 750],
        riskScore: 78,
        ecc: [800, 820, 850, 880, 900, 950],
        preventiveCareOpportunities: ["Cardiovascular Health"],
        monthlyInterventionSuccess: [20, 25, 35, 45, 50, 55],
        healthScore: 170,
        healthScoreCategory: "Low",
        medicalFraudProbability: 60,
        sanitationScore: 52,
        earlyClaimProbability: 70,
        smokingImpact: 90,
        alcoholImpact: 65,
        sentimentScores: { positive: 0.0018, neutral: 0.0022, negative: 0.004 },
        weatherInsights: [
            { status: "At High Risk", condition: "Arthritis Pain Forecast", value: 6.5 },
            { status: "Moderate Risk", condition: "Asthma Forecast", value: 4.0 },
            { status: "At High Risk", condition: "COPD Forecast", value: 4.3 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.7 },
        ],
    },
    {
        id: 14,
        name: "Esther Umutoni",
        gender: "Female",
        dob: "1988-09-09",
        age: 35,
        location: "Ruhengeri, Rwanda",
        chronicCondition: "None",
        bmi: 24.0,
        smokingStatus: "No",
        physicalActivity: 87,
        diet: 90,
        mentalHealth: 88,
        claimFrequency: [0, 0, 1, 1, 1, 0],
        claimAmount: [0, 0, 250, 300, 350, 0],
        riskScore: 30,
        ecc: [200, 210, 220, 230, 240, 250],
        preventiveCareOpportunities: ["General Wellness"],
        monthlyInterventionSuccess: [25, 30, 35, 40, 45, 50],
        healthScore: 640,
        healthScoreCategory: "Excellent",
        medicalFraudProbability: 8,
        sanitationScore: 80,
        earlyClaimProbability: 20,
        smokingImpact: 0,
        alcoholImpact: 20,
        sentimentScores: { positive: 0.005, neutral: 0.001, negative: 0.0005 },
        weatherInsights: [
            { status: "Beneficial", condition: "Arthritis Pain Forecast", value: 1.8 },
            { status: "Low Risk", condition: "Asthma Forecast", value: 2.5 },
            { status: "Low Risk", condition: "COPD Forecast", value: 1.3 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.5 },
        ],
    },
    {
        id: 15,
        name: "Felix Ntirenganya",
        gender: "Male",
        dob: "1984-06-01",
        age: 39,
        location: "Byumba, Rwanda",
        chronicCondition: "Cholesterol",
        bmi: 27.8,
        smokingStatus: "No",
        physicalActivity: 60,
        diet: 65,
        mentalHealth: 70,
        claimFrequency: [2, 1, 3, 2, 2, 3],
        claimAmount: [400, 350, 500, 450, 600, 700],
        riskScore: 65,
        ecc: [500, 520, 550, 600, 650, 680],
        preventiveCareOpportunities: ["Dietary Counseling"],
        monthlyInterventionSuccess: [20, 25, 30, 35, 40, 45],
        healthScore: 310,
        healthScoreCategory: "Good",
        medicalFraudProbability: 28,
        sanitationScore: 65,
        earlyClaimProbability: 45,
        smokingImpact: 0,
        alcoholImpact: 55,
        sentimentScores: { positive: 0.002, neutral: 0.0025, negative: 0.002 },
        weatherInsights: [
            { status: "Moderate Risk", condition: "Arthritis Pain Forecast", value: 4.0 },
            { status: "Moderate Risk", condition: "Asthma Forecast", value: 3.5 },
            { status: "Moderate Risk", condition: "COPD Forecast", value: 2.8 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.9 },
        ],
    },
    {
        id: 16,
        name: "Hope Ingabire",
        gender: "Female",
        dob: "1999-11-23",
        age: 24,
        location: "Kayonza, Rwanda",
        chronicCondition: "None",
        bmi: 20.5,
        smokingStatus: "No",
        physicalActivity: 92,
        diet: 93,
        mentalHealth: 89,
        claimFrequency: [0, 0, 0, 1, 0, 1],
        claimAmount: [0, 0, 0, 200, 0, 300],
        riskScore: 20,
        ecc: [150, 160, 170, 180, 190, 200],
        preventiveCareOpportunities: ["Cancer Screening", "General Health Check-up"],
        monthlyInterventionSuccess: [15, 20, 25, 30, 35, 40],
        healthScore: 700,
        healthScoreCategory: "Excellent",
        medicalFraudProbability: 5,
        sanitationScore: 88,
        earlyClaimProbability: 10,
        smokingImpact: 0,
        alcoholImpact: 10,
        sentimentScores: { positive: 0.006, neutral: 0.0005, negative: 0.0001 },
        weatherInsights: [
            { status: "Beneficial", condition: "Arthritis Pain Forecast", value: 1.1 },
            { status: "Low Risk", condition: "Asthma Forecast", value: 1.0 },
            { status: "Low Risk", condition: "COPD Forecast", value: 0.9 },
            { status: "Beneficial", condition: "Common cold Forecast", value: 1.3 },
        ],
    }

];



const GraphicalInsights = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [selectedClient, setSelectedClient] = useState(sampleClientsData[0]);

    const handleClientChange = (e) => {
        const clientId = parseInt(e.target.value);
        const client = sampleClientsData.find((client) => client.id === clientId);
        setSelectedClient(client);
    };

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">
                <TopBar />
                <div className="insights-container">
                    {/* Client Selection */}
                    <div className="client-selection">
                        <label>Select Client:</label>
                        <select onChange={handleClientChange}>
                            {sampleClientsData.map((client) => (
                                <option key={client.id} value={client.id}>
                                    {client.name} - {client.gender}, Age: {client.age}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Insights Overview (New Section) */}
                    <div className="insight-grid">
                        <div className="card client-info">
                            <div className="avatar-placeholder">
                                <div className="avatar-circle">
                                    <span>{selectedClient.name.charAt(0)}</span>
                                </div>
                            </div>
                            <h4>{selectedClient.name}</h4>
                            <div className="info-grid">
                                <div className="info-item">Age: {selectedClient.age}</div>
                                <div className="info-item">Gender: {selectedClient.gender}</div>
                                <div className="info-item">Smoker: {selectedClient.smokingStatus}</div>
                                <div className="info-item">BMI: {selectedClient.bmi}</div>
                            </div>
                        </div>

                        {/* Health Score */}
                        <div className="card health-score">
                            <h4>Health Score</h4>
                            <Doughnut
                                data={{
                                    labels: ["Health Score"],
                                    datasets: [
                                        {
                                            label: "Health Score",
                                            data: [selectedClient.healthScore, 700 - selectedClient.healthScore],
                                            backgroundColor: ["#007bff", "#e9ecef"],
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={{
                                    circumference: 180,
                                    rotation: -90,
                                    cutout: "70%",
                                    plugins: {
                                        tooltip: { enabled: false },
                                    },
                                }}
                            />
                            <div className="health-score-label">{selectedClient.healthScoreCategory}</div>
                        </div>

                        {/* Risk and Fraud Probability */}
                        <div className="card risk-info">
                            <h4>Risk Information</h4>
                            <div className="info-grid">
                                <div className="info-item">Risk Score: {selectedClient.riskScore}</div>
                                <div className="info-item">Medical Fraud Probability: {selectedClient.medicalFraudProbability}%</div>
                                <div className="info-item negative-pin-code">Negative PIN Code: NO</div>
                            </div>
                        </div>

                        {/* Sentiment Score */}
                        <div className="card sentiment-score">
                            <h4>Sentiment Score</h4>
                            <div className="info-grid">
                                <div className="info-item positive">Positive: {selectedClient.sentimentScores.positive}</div>
                                <div className="info-item neutral">Neutral: {selectedClient.sentimentScores.neutral}</div>
                                <div className="info-item negative">Negative: {selectedClient.sentimentScores.negative}</div>
                            </div>
                        </div>

                        {/* Weather Insights */}
                        <div className="card weather-insights">
                            <h4>Weather Insights</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Condition</th>
                                        <th>Status</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedClient.weatherInsights.map((insight, index) => (
                                        <tr key={index}>
                                            <td>{insight.condition}</td>
                                            <td>{insight.status}</td>
                                            <td>{insight.value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Sanitation Insights */}
                        <div className="card sanitation-insights">
                            <h4>Sanitation Insights</h4>
                            <Doughnut
                                data={{
                                    labels: ["Sanitation Score"],
                                    datasets: [
                                        {
                                            label: "Sanitation Score",
                                            data: [selectedClient.sanitationScore, 100 - selectedClient.sanitationScore],
                                            backgroundColor: ["#ff6384", "#e9ecef"],
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={{
                                    circumference: 180,
                                    rotation: -90,
                                    cutout: "70%",
                                    plugins: {
                                        tooltip: { enabled: false },
                                    },
                                }}
                            />
                            <div className="sanitation-score-label">{selectedClient.sanitationScore}%</div>
                        </div>

                        {/* Early Claim Probability */}
                        <div className="card early-claim">
                            <h4>Early Claim Score Probability</h4>
                            <Doughnut
                                data={{
                                    labels: ["Early Claim Probability"],
                                    datasets: [
                                        {
                                            label: "Early Claim Score Probability",
                                            data: [selectedClient.earlyClaimProbability, 100 - selectedClient.earlyClaimProbability],
                                            backgroundColor: ["#28a745", "#e9ecef"],
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={{
                                    circumference: 180,
                                    rotation: -90,
                                    cutout: "70%",
                                    plugins: {
                                        tooltip: { enabled: false },
                                    },
                                }}
                            />
                            <div className="claim-score-label">{selectedClient.earlyClaimProbability}%</div>
                        </div>

                        {/* Smoking Insights */}
                        <div className="card smoking-insights">
                            <h4>Smoking Impact</h4>
                            <Doughnut
                                data={{
                                    labels: ["Smoking Impact"],
                                    datasets: [
                                        {
                                            label: "Smoking Impact",
                                            data: [selectedClient.smokingImpact, 100 - selectedClient.smokingImpact],
                                            backgroundColor: ["#f39c12", "#e9ecef"],
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={{
                                    circumference: 180,
                                    rotation: -90,
                                    cutout: "70%",
                                    plugins: {
                                        tooltip: { enabled: false },
                                    },
                                }}
                            />
                            <div className="smoking-impact-label">{selectedClient.smokingImpact}%</div>
                        </div>

                        {/* Alcohol Insights */}
                        <div className="card alcohol-insights">
                            <h4>Alcohol Impact</h4>
                            <Doughnut
                                data={{
                                    labels: ["Alcohol Impact"],
                                    datasets: [
                                        {
                                            label: "Alcohol Impact",
                                            data: [selectedClient.alcoholImpact, 100 - selectedClient.alcoholImpact],
                                            backgroundColor: ["#ffc107", "#e9ecef"],
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={{
                                    circumference: 180,
                                    rotation: -90,
                                    cutout: "70%",
                                    plugins: {
                                        tooltip: { enabled: false },
                                    },
                                }}
                            />
                            <div className="alcohol-impact-label">{selectedClient.alcoholImpact}%</div>
                        </div>
                    </div>

                    {/* Existing Graphs (Retained) */}

                    {/* Health Profile */}
                    <div className="insight-section">
                        <h3>Health Profile</h3>
                        <p>Chronic Condition: {selectedClient.chronicCondition}</p>
                        <p>BMI: {selectedClient.bmi}</p>
                        <p>Smoking Status: {selectedClient.smokingStatus}</p>
                    </div>

                    {/* Lifestyle Factors */}
                    <div className="insight-section">
                        <h3>Lifestyle Factors</h3>
                        <Radar
                            data={{
                                labels: ["Physical Activity", "Diet", "Mental Health"],
                                datasets: [
                                    {
                                        label: "Lifestyle Scores",
                                        data: [selectedClient.physicalActivity, selectedClient.diet, selectedClient.mentalHealth],
                                        backgroundColor: "rgba(0, 123, 255, 0.2)",
                                        borderColor: "rgba(0, 123, 255, 1)",
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                            options={{
                                scales: {
                                    r: {
                                        beginAtZero: true,
                                        max: 100,
                                    },
                                },
                            }}
                        />
                        <p>
                            <em>Data collected from user activity tracked through our mobile app with the help of device sensors.</em>
                        </p>
                    </div>

                    {/* Claim History */}
                    <div className="insight-section">
                        <h3>Claim History</h3>
                        <Bar
                            data={{
                                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                                datasets: [
                                    {
                                        label: "Claim Frequency",
                                        data: selectedClient.claimFrequency,
                                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                                        borderColor: "rgba(255, 99, 132, 1)",
                                        borderWidth: 1,
                                    },
                                    {
                                        label: "Claim Amount",
                                        data: selectedClient.claimAmount,
                                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                                        borderColor: "rgba(54, 162, 235, 1)",
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                            options={{ scales: { y: { beginAtZero: true } } }}
                        />
                        <p>
                            <em>Claims data provided by healthcare providers and processed through our claims management system.</em>
                        </p>
                    </div>

                    {/* Cost Forecasting */}
                    <div className="insight-section">
                        <h3>Cost Forecasting (ECC)</h3>
                        <Line
                            data={{
                                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                                datasets: [
                                    {
                                        label: "Projected Claim Costs",
                                        data: selectedClient.ecc,
                                        fill: true,
                                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                                        borderColor: "rgba(75, 192, 192, 1)",
                                        tension: 0.1,
                                    },
                                ],
                            }}
                        />
                        <p>
                            <em>Expected Claim Costs (ECC) forecasts are derived using historical claim data and trend analysis, considering factors like chronic conditions and seasonal healthcare utilization patterns.</em>
                        </p>
                    </div>

                    {/* Preventive Care Opportunities */}
                    <div className="insight-section">
                        <h3>Preventive Care Opportunities</h3>
                        <p>At Risk for: {selectedClient.preventiveCareOpportunities.join(", ")}</p>
                        <Line
                            data={{
                                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                                datasets: [
                                    {
                                        label: "Monthly Intervention Success Rate",
                                        data: selectedClient.monthlyInterventionSuccess,
                                        fill: false,
                                        borderColor: "rgba(153, 102, 255, 1)",
                                        tension: 0.1,
                                    },
                                ],
                            }}
                        />
                        <p>
                            <em>Preventive care opportunities are identified based on health assessments and risk analysis. Our intervention programs are designed to address these risks through personalized coaching and monitoring.</em>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GraphicalInsights;
