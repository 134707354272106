import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';
import industriesData from '../assets/data/industries.json';
import './../assets/css/SurveyForm.css';

const SurveyForm = ({ formData, setFormData }) => {
    const [displayedQuestions, setDisplayedQuestions] = useState([...formData.questions]);
    const [submitted, setSubmitted] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [industryOptions, setIndustryOptions] = useState([]);

    const extractUserResponses = (formData) => {
        let userResponses = {};
        formData.questions.forEach((question) => {
            if (formData[question.label]) {
                userResponses[question.key] = formData[question.label];
            }
            if (question.options) {
                question.options.forEach((option) => {
                    if (option.nextQuestion && formData[option.nextQuestion.label]) {
                        userResponses[option.nextQuestion.key] = formData[option.nextQuestion.label];
                    }
                });
            }
        });
        return userResponses;
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countries = response.data.map((country) => country.name.common);
                const sortedCountries = countries.sort((a, b) => a.localeCompare(b)); // Sort countries alphabetically
                setCountryOptions(sortedCountries);
            } catch (error) {
                console.log('Error fetching countries:', error);
            }
        };

        const fetchIndustries = async () => {
            try {
                const industries = industriesData.industries.sort();
                const sortedIndustries = industries.sort((a, b) => a.localeCompare(b)); // Sort industries alphabetically
                setIndustryOptions(sortedIndustries);
            } catch (error) {
                console.log('Error fetching industries:', error);
            }
        };

        const countryDropdown = formData.questions.find(
            (question) => question.inputType === 'dropdown' && question.key === 'country'
        );
        if (countryDropdown) {
            fetchCountries();
        }

        const industryDropdown = formData.questions.find(
            (question) => question.inputType === 'dropdown' && question.key === 'industry'
        );
        if (industryDropdown) {
            fetchIndustries();
        }
    }, [formData.questions]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Extract only the user's responses from the form data.
            const userResponses = extractUserResponses(formData);
            // Convert this object to a JSON string to send it in the request body.
            const response = await fetch(formData.webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userResponses),
            });
            if (response.ok) {
                setSubmitted(true);
            } else {
                // handle error
                console.log('Submission failed');
            }
        } catch (error) {
            // handle error
            console.log('Submission failed:', error);
        }
    };

    if (submitted) {
        return (
            <div className="thank-you-container">
                <h2>Thank you for your submission.</h2>
            </div>
        );
    }

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            // For file inputs, handle files
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.files,
            }));
        } else {
            // Logic for other input types
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;
        const selectedQuestion = formData.questions.find((question) => question.label === e.target.name);

        if (selectedQuestion && selectedQuestion.options) {
            const nextQuestion = selectedQuestion.options.find((option) => option.value === selectedValue)?.nextQuestion;
            const otherQuestion = selectedQuestion.options.find((option) => option.value !== selectedValue)?.nextQuestion;

            let displayedQuestionsCopy = [...displayedQuestions];
            const currentQuestionIndex = displayedQuestionsCopy.findIndex((question) => question.label === selectedQuestion.label);

            if (nextQuestion) {
                const otherQuestionIndex = displayedQuestionsCopy.findIndex((question) => question.label === otherQuestion?.label);
                if (otherQuestionIndex !== -1) {
                    displayedQuestionsCopy.splice(otherQuestionIndex, 1);
                }
                displayedQuestionsCopy.splice(currentQuestionIndex + 1, 0, nextQuestion);
            } else if (otherQuestion) {
                displayedQuestionsCopy = displayedQuestionsCopy.filter((question) => question.label !== otherQuestion.label);
            }

            setDisplayedQuestions(displayedQuestionsCopy);
        }

        handleChange(e);
    };

    const handleCheckboxChange = (e) => {
        const selectedValue = e.target.value;
        const selectedQuestion = displayedQuestions.find((question) => question.label === e.target.name);

        if (selectedQuestion) {
            // Make a copy of the current form data
            let newFormData = { ...formData };
            if (e.target.checked) {
                // If the checkbox is checked, add the value to the array
                newFormData[selectedQuestion.label] = [...newFormData[selectedQuestion.label] || [], selectedValue];
            } else {
                // If the checkbox is unchecked, remove the value from the array
                newFormData[selectedQuestion.label] = newFormData[selectedQuestion.label]?.filter((value) => value !== selectedValue);
            }
            setFormData(newFormData);
        }
    };

    return (
        <div className="survey-form-container">
            <Header formStructure={formData} />
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {displayedQuestions.map((question, i) => {
                        switch (question.inputType) {
                            case 'text':
                                return (
                                    <div key={i} className="input-div">
                                        <label>
                                            {question.label}
                                            {question.required && <span className="required">*</span>}
                                        </label>
                                        <input type="text" name={question.label} onChange={handleChange} required={question.required} />
                                    </div>
                                );
                            case 'radio':
                                return (
                                    <div key={i} className="input-div">
                                        <label>
                                            {question.label}
                                            {question.required && <span className="required">*</span>}
                                        </label>
                                        <div className="option-group">
                                            {question.options.map((option, j) => (
                                                <label key={j}>
                                                    <input
                                                        type="radio"
                                                        value={option.value}
                                                        checked={formData[question.label] === option.value}
                                                        onChange={handleRadioChange}
                                                        name={question.label}
                                                        required={question.required}
                                                    />
                                                    {option.label}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                );
                            case 'checkbox':
                                return (
                                    <div key={i} className="input-div">
                                        <label>
                                            {question.label}
                                            {question.required && <span className="required">*</span>}
                                        </label>
                                        <div className="option-group">
                                            {question.options.map((option, j) => (
                                                <label key={j} className="checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        checked={formData[question.label]?.includes(option.value)}
                                                        onChange={handleCheckboxChange}
                                                        name={question.inputType + i}
                                                        required={question.required}
                                                    />
                                                    {option.label}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                );
                            case 'dropdown':
                                if (question.key === 'country') {
                                    return (
                                        <div key={i} className="input-div">
                                            <label>
                                                {question.label}
                                                {question.required && <span className="required">*</span>}
                                            </label>
                                            <select name={question.key} value={formData[question.key] || ''} onChange={handleChange} required={question.required}>
                                                <option value="">-- Select a country --</option>
                                                {countryOptions.map((country, index) => (
                                                    <option key={index} value={country}>
                                                        {country}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    );
                                } else if (question.key === 'industry') {
                                    return (
                                        <div key={i} className="input-div">
                                            <label>
                                                {question.label}
                                                {question.required && <span className="required">*</span>}
                                            </label>
                                            <select name={question.key} value={formData[question.key] || ''} onChange={handleChange} required={question.required}>
                                                <option value="">-- Select an industry --</option>
                                                {industryOptions.map((industry, index) => (
                                                    <option key={index} value={industry}>
                                                        {industry}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={i} className="input-div">
                                            <label>
                                                {question.label}
                                                {question.required && <span className="required">*</span>}
                                            </label>
                                            <select name={question.key} value={formData[question.key] || ''} onChange={handleChange} required={question.required}>
                                                {question.options.map((option, index) => (
                                                    <option key={index} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    );
                                }
                            case 'file':
                                return (
                                    <div key={i} className="input-div">
                                        <label>
                                            {question.label}
                                            {question.required && <span className="required">*</span>}
                                        </label>
                                        <label htmlFor={question.label} className="custom-file-upload">
                                            Choose File
                                        </label>
                                        <input
                                            id={question.label}
                                            type="file"
                                            name={question.label}
                                            onChange={handleChange}
                                            required={question.required}
                                            multiple={question.multiple}
                                        />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })}
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    );
};

export default SurveyForm;
