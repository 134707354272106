import React, { useState } from 'react';
import { FiUserPlus, FiChevronDown, FiEdit2, FiPlus } from 'react-icons/fi';
import Modal from 'react-modal';
import './../../assets/css/TopBar.css';

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        borderRadius: '10px',
        padding: '20px',
        border: '1px solid #d3e2fd',
        boxShadow: '0 1px 2px rgba(0,0,0,.1)',
        width: '30%',
    },
};

Modal.setAppElement('#root');

const TopBar = () => {
    const [workspaceModalIsOpen, setWorkspaceModalIsOpen] = useState(false);
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
    const [editWorkspaceModalIsOpen, setEditWorkspaceModalIsOpen] = useState(false);
    const [createWorkspaceModalIsOpen, setCreateWorkspaceModalIsOpen] = useState(false);
    const [workspaceName, setWorkspaceName] = useState('Workspace 1');
    const [newWorkspaceName, setNewWorkspaceName] = useState('');
    const [newWorkspace, setNewWorkspace] = useState('');

    const openWorkspaceModal = () => {
        setWorkspaceModalIsOpen(true);
    };

    const closeWorkspaceModal = () => {
        setWorkspaceModalIsOpen(false);
    };

    const openInviteModal = () => {
        setInviteModalIsOpen(true);
    };

    const closeInviteModal = () => {
        setInviteModalIsOpen(false);
    };

    const openEditWorkspaceModal = () => {
        setNewWorkspaceName(workspaceName);
        setEditWorkspaceModalIsOpen(true);
    };

    const closeEditWorkspaceModal = () => {
        setEditWorkspaceModalIsOpen(false);
    };

    const openCreateWorkspaceModal = () => {
        setCreateWorkspaceModalIsOpen(true);
    };

    const closeCreateWorkspaceModal = () => {
        setCreateWorkspaceModalIsOpen(false);
    };

    const handleWorkspaceNameChange = (e) => {
        setNewWorkspaceName(e.target.value);
    };

    const handleNewWorkspaceChange = (e) => {
        setNewWorkspace(e.target.value);
    };

    const saveWorkspaceName = () => {
        setWorkspaceName(newWorkspaceName);
        closeEditWorkspaceModal();
    };

    const createNewWorkspace = () => {
        // Add logic to create a new workspace here
        console.log('New workspace created:', newWorkspace);
        closeCreateWorkspaceModal();
    };

    return (
        <div className="top-bar">
            <div className="workspace-group-display">
                <p className="workspace-group">Active Workspace</p>
                <div className="current-workspace" onClick={openWorkspaceModal}>
                    <span className="workspace-name">{workspaceName}</span>
                    <FiChevronDown className="workspace-switch-icon" />
                </div>
                <FiEdit2 className="edit-workspace-icon" onClick={openEditWorkspaceModal} />
                <FiPlus className="create-workspace-icon" onClick={openCreateWorkspaceModal} />
            </div>
            <div className="separator"></div>
            <div className="top-bar-right">
                <div className="invite-user-link" onClick={openInviteModal}>
                    <FiUserPlus className="invite-icon" />
                    Invite to this workspace
                </div>
            </div>
            <Modal
                isOpen={workspaceModalIsOpen}
                onRequestClose={closeWorkspaceModal}
                style={customModalStyles}
                contentLabel="Switch Workspace"
            >
                <div className="modal-header">
                    <div className="header-left-line"></div>
                    <h2>Switch Workspace</h2>
                </div>
                <p>Select a workspace to switch to:</p>
                <div className="workspace-list">
                    <div className="workspace-item">Sales & Marketing</div>
                    <div className="workspace-item">Human Resource</div>
                    <div className="workspace-item">Surveys</div>
                </div>
                <div className="modal-buttons">
                    <button className="switch-workspace-modal-button" onClick={closeWorkspaceModal}>Cancel</button>
                    <button className="switch-workspace-modal-button" onClick={closeWorkspaceModal}>Switch</button>
                </div>
            </Modal>
            <Modal
                isOpen={inviteModalIsOpen}
                onRequestClose={closeInviteModal}
                style={customModalStyles}
                contentLabel="Invite User"
            >
                <div className="modal-header">
                    <div className="header-left-line"></div>
                    <h2>Invite User</h2>
                </div>
                <p>Enter the email of the user you want to invite to this workspace.</p>
                <input type="email" className="email-input" placeholder="Add a user email to invite" />
                <div className="modal-buttons">
                    <button className="invite-user-button" onClick={closeInviteModal}>Cancel</button>
                    <button className="invite-user-button">Invite User</button>
                </div>
            </Modal>
            <Modal
                isOpen={editWorkspaceModalIsOpen}
                onRequestClose={closeEditWorkspaceModal}
                style={customModalStyles}
                contentLabel="Edit Workspace Name"
            >
                <div className="modal-header">
                    <div className="header-left-line"></div>
                    <h2>Edit Workspace Name</h2>
                </div>
                <p>Enter a new name for the workspace:</p>
                <input
                    type="text"
                    className="workspace-name-input"
                    value={newWorkspaceName}
                    onChange={handleWorkspaceNameChange}
                    placeholder="New Workspace Name"
                />
                <div className="modal-buttons">
                    <button className="save-workspace-name-button" onClick={closeEditWorkspaceModal}>Cancel</button>
                    <button className="save-workspace-name-button" onClick={saveWorkspaceName}>Save</button>
                </div>
            </Modal>
            <Modal
                isOpen={createWorkspaceModalIsOpen}
                onRequestClose={closeCreateWorkspaceModal}
                style={customModalStyles}
                contentLabel="Create New Workspace"
            >
                <div className="modal-header">
                    <div className="header-left-line"></div>
                    <h2>Create New Workspace</h2>
                </div>
                <p>Enter a name for the new workspace:</p>
                <input
                    type="text"
                    className="workspace-name-input"
                    value={newWorkspace}
                    onChange={handleNewWorkspaceChange}
                    placeholder="New Workspace Name"
                />
                <div className="modal-buttons">
                    <button className="create-workspace-button" onClick={closeCreateWorkspaceModal}>Cancel</button>
                    <button className="create-workspace-button" onClick={createNewWorkspace}>Create</button>
                </div>
            </Modal>
        </div>
    );
};

export default TopBar;
