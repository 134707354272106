import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './../../assets/css/FormSubmittedPage.css';
import axios from 'axios';

const FormSubmittedPage = () => {
    const { formUrl } = useParams(); // Get dynamic URL parameter
    const [formData, setFormData] = useState(null); // Initialize as null
    const [preview, setPreview] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [showFollowUp, setShowFollowUp] = useState({});

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                const response = await axios.get(`http://localhost:5030/prod/api/test/webhook/form-structure/${formUrl}`);
                setFormData(response.data);
                setPreview(true); // Set preview to true to display the form
            } catch (error) {
                console.error("Error fetching the form structure", error);
            }
        };

        fetchFormData();
    }, [formUrl]);

    const handleInputChange = (e, questionKey, conditions) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));

        if (conditions) {
            const newShowFollowUp = { ...showFollowUp };
            conditions.forEach((condition) => {
                if (condition.type === 'equality' && condition.value === newValue) {
                    newShowFollowUp[condition.followUpQuestion.key] = true;
                } else {
                    newShowFollowUp[condition.followUpQuestion.key] = false;
                }
            });
            setShowFollowUp(newShowFollowUp);
        }
    };

    const renderFollowUpQuestion = (followUpQuestion) => {
        if (!followUpQuestion) return null;

        return (
            <div className="form-group follow-up-question">
                <label>{followUpQuestion.label}</label>
                {followUpQuestion.inputType === 'text' && (
                    <input
                        type="text"
                        name={followUpQuestion.key}
                        required={followUpQuestion.required}
                        className="input-field"
                    />
                )}
                {['radio', 'checkbox'].includes(followUpQuestion.inputType) && (
                    <div className="options-group">
                        {followUpQuestion.options.map((option, optIndex) => (
                            <label key={optIndex} className="option-label">
                                <input
                                    type={followUpQuestion.inputType}
                                    name={followUpQuestion.key}
                                    value={option.value}
                                    required={followUpQuestion.required}
                                    className="option-input"
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                )}
                {followUpQuestion.inputType === 'dropdown' && (
                    <select
                        name={followUpQuestion.key}
                        required={followUpQuestion.required}
                        className="select-field"
                    >
                        {followUpQuestion.options.map((option, optIndex) => (
                            <option key={optIndex} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                )}
                {followUpQuestion.inputType === 'file' && (
                    <div className="file-input-container">
                        <input
                            type="file"
                            name={followUpQuestion.key}
                            required={followUpQuestion.required}
                            multiple={followUpQuestion.multiple}
                            className="file-input"
                            id={`file-input-${followUpQuestion.key}`}
                        />
                        <label htmlFor={`file-input-${followUpQuestion.key}`} className="file-input-label">
                            Choose File
                        </label>
                    </div>
                )}
                {followUpQuestion.inputType === 'date' && (
                    <input
                        type="date"
                        name={followUpQuestion.key}
                        required={followUpQuestion.required}
                        className="input-field"
                    />
                )}

            </div>
        );
    };

    const renderFormPreview = () => {
        if (!formData || !formData.questions) {
            return <p>No form data available</p>;
        }

        return (
            <div className="form-preview-container">
                {formData.logo && (
                    <img src={formData.logo} alt="Form Logo" className="form-logo" />
                )}
                <h2>{formData.title}</h2>
                <p>{formData.description}</p>
                <form>
                    {formData.questions.map((question, index) => (
                        <div key={index} className="form-group">
                            <label>{question.label}</label>
                            {question.inputType === 'text' && (
                                <input
                                    type="text"
                                    name={question.key}
                                    required={question.required}
                                    className="input-field"
                                />
                            )}
                            {['radio', 'checkbox'].includes(question.inputType) && (
                                <div className="options-group">
                                    {question.options.map((option, optIndex) => (
                                        <label key={optIndex} className="option-label">
                                            <input
                                                type={question.inputType}
                                                name={question.key}
                                                value={option.value}
                                                required={question.required}
                                                className="option-input"
                                                onChange={(e) => handleInputChange(e, question.key, question.conditions)}
                                            />
                                            {option.label}
                                        </label>
                                    ))}
                                </div>
                            )}
                            {question.inputType === 'dropdown' && (
                                <select
                                    name={question.key}
                                    required={question.required}
                                    className="select-field"
                                    onChange={(e) => handleInputChange(e, question.key, question.conditions)}
                                >
                                    {question.options.map((option, optIndex) => (
                                        <option key={optIndex} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {question.inputType === 'file' && (
                                <div className="file-input-container">
                                    <input
                                        type="file"
                                        name={question.key}
                                        required={question.required}
                                        multiple={question.multiple}
                                        className="file-input"
                                        id={`file-input-${question.key}`}
                                    />
                                    <label htmlFor={`file-input-${question.key}`} className="file-input-label">
                                        Choose File
                                    </label>
                                </div>
                            )}
                            {question.inputType === 'date' && (
                                <input
                                    type="date"
                                    name={question.key}
                                    required={question.required}
                                    className="input-field"
                                    onChange={(e) => handleInputChange(e, question.key, question.conditions)}
                                />
                            )}

                            {question.conditions && question.conditions.map((condition, condIndex) =>
                                showFollowUp[condition.followUpQuestion.key]
                                    ? renderFollowUpQuestion(condition.followUpQuestion)
                                    : null
                            )}
                        </div>
                    ))}
                    <button type="submit" className="button">Submit</button>
                </form>
            </div>
        );
    };

    return (
        <div className="form-submitted-container">
            {preview ? (
                renderFormPreview()
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default FormSubmittedPage;
