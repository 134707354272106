import React from 'react';
import '../App.css';

const Header = ({ formStructure }) => {
    return (
        <div className='container'>
            <header className="header">
                <img className="logo" src={formStructure.logo} alt="Logo" />
                <h1 className="title">{formStructure.title}</h1>
                <p className="desc">{formStructure.description}</p>
            </header>
        </div>
    );
};

export default Header;
