import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { BsFilter, BsSortDown, BsDownload, BsArrowClockwise, BsChevronExpand, BsChatDots, BsThreeDotsVertical } from 'react-icons/bs';
import * as XLSX from 'xlsx';
import './../../assets/css/ReportViewer.css';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import AIComponent from './AIComponent';
import emptyBoxIcon from './../../assets/images/empty-box.png';
import noDataIcon from './../../assets/images/no-data-icon.png';


const ReportViewer = () => {
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filterConfig, setFilterConfig] = useState({ query: '', column: '', values: {} });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(null);
    const tableRef = useRef(null);

    const [isDataFetched, setIsDataFetched] = useState(false);

    const [selectedCells, setSelectedCells] = useState([]);


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isGridVisible, setIsGridVisible] = useState(true); // Default visibility for grids

    const [frozenColumns, setFrozenColumns] = useState(0); // Number of frozen columns




    const toggleGridVisibility = () => {
        setIsGridVisible(!isGridVisible);
        const table = tableRef.current;
        if (table) {
            if (isGridVisible) {
                table.classList.add('hide-grid'); // Hides the grid
            } else {
                table.classList.remove('hide-grid'); // Shows the grid
            }
        }
    };

    const handleFreezeColumns = (num) => {
        const numberOfFrozenColumns = parseInt(num, 10);
        setFrozenColumns(numberOfFrozenColumns);

        const tableHeaders = tableRef.current.querySelectorAll('th');
        const tableCells = tableRef.current.querySelectorAll('td');

        // Reset all columns first by removing any sticky positioning
        tableHeaders.forEach((th) => {
            th.style.position = 'initial';
            th.style.zIndex = 'auto';
            th.style.backgroundColor = ''; // Reset background color
        });
        tableCells.forEach((td) => {
            td.style.position = 'initial';
            td.style.zIndex = 'auto';
            td.style.backgroundColor = ''; // Reset background color
        });

        // Apply sticky positioning to the first `numberOfFrozenColumns` columns
        for (let i = 0; i < numberOfFrozenColumns; i++) {
            tableHeaders[i].style.position = 'sticky';
            tableHeaders[i].style.left = `${i * tableHeaders[i].offsetWidth}px`;
            tableHeaders[i].style.zIndex = 2; // Ensures header stays on top
            tableHeaders[i].style.backgroundColor = '#fffaea';

            // Apply sticky to all rows for the current column
            const cellsInColumn = tableRef.current.querySelectorAll(`td:nth-child(${i + 1})`);
            cellsInColumn.forEach((cell) => {
                cell.style.position = 'sticky';
                cell.style.left = `${i * cell.offsetWidth}px`;
                cell.style.zIndex = 2; // Ensure cell stays below header
                cell.style.backgroundColor = '#fffaea'; // Add background color to the cells
            });
        }
    };


    /*
        Setup Alert Modal
    */
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertConfig, setAlertConfig] = useState({ column: '', threshold: '', type: 'one-time', cadence: '' });

    /*
        Setup Show Sum Modal
    */
    const [showSumModal, setShowSumModal] = useState(false);
    const [sumValue, setSumValue] = useState(0);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, column: '' });





    const location = useLocation();
    const navigate = useNavigate();
    const { reportId } = useParams();


    const [reportName, setReportName] = useState(location.state?.reportName || '');
    const [reportDescription, setReportDescription] = useState(location.state?.reportDescription || '');
    const [reportEndpoint, setReportEndpoint] = useState(location.state?.reportEndpoint || '');

    const [enableAction, setEnableAction] = useState(location.state?.enableAction || false);
    const [actionLabel, setActionLabel] = useState(location.state?.actionLabel || '');
    const [actionReportId, setActionReportId] = useState(location.state?.actionReportId || '');



    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const [showAI, setShowAI] = useState(false);

    useEffect(() => {
        const handleCopyEvent = (event) => {
            if (selectedCells.length > 0) {
                event.preventDefault();

                // Remove any duplicate cells by creating a set of unique coordinates
                const uniqueCells = Array.from(
                    new Set(selectedCells.map(([rowIndex, colIndex]) => `${rowIndex}-${colIndex}`))
                ).map((cell) => cell.split('-').map(Number));

                // Sort the unique cells by row and column for consistent copying
                const sortedCells = uniqueCells.sort((a, b) => {
                    return a[0] - b[0] || a[1] - b[1];
                });

                // Group selected cells by rows
                const rows = {};
                sortedCells.forEach(([rowIndex, colIndex]) => {
                    if (!rows[rowIndex]) {
                        rows[rowIndex] = [];
                    }
                    rows[rowIndex].push(data[rowIndex][columns[colIndex]]);
                });

                // Format the selected data into a string separated by tabs and newlines
                const copiedData = Object.keys(rows)
                    .map((rowIndex) => rows[rowIndex].join('\t'))
                    .join('\n');

                // Set the formatted string to the clipboard
                event.clipboardData.setData('text/plain', copiedData);
            }
        };

        document.addEventListener('copy', handleCopyEvent);

        return () => {
            document.removeEventListener('copy', handleCopyEvent);
        };
    }, [selectedCells, columns, data]);


    const handleMouseDown = (rowIndex, colIndex) => {
        setSelectedCells([[rowIndex, colIndex]]);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        // Prevent default text selection
        window.getSelection()?.removeAllRanges();
    };

    const handleMouseMove = (event) => {
        event.preventDefault(); // Prevent default text selection
        const target = event.target.closest('td');
        if (target) {
            const rowIndex = parseInt(target.dataset.row);
            const colIndex = parseInt(target.dataset.col);
            if (selectedCells.length === 1 || !selectedCells.some(cell => cell[0] === rowIndex && cell[1] === colIndex)) {
                setSelectedCells(prevSelectedCells => [...prevSelectedCells, [rowIndex, colIndex]]);
            }
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };


    const handleCopy = () => {
        const selectedValues = selectedCells.map(([rowIndex, colIndex]) => data[rowIndex][columns[colIndex]]);
        const formattedValues = selectedValues.join('\t');
        navigator.clipboard.writeText(formattedValues)
            .then(() => console.log('Copied to clipboard'))
            .catch(err => console.error('Failed to copy: ', err));
    };


    useEffect(() => {
        const handleClickOutsideModal = (event) => {
            if (showSumModal && !event.target.closest('.custom-modal')) {
                setShowSumModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideModal);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideModal);
        };
    }, [showSumModal]);

    const handleRightClick = (event, column) => {
        event.preventDefault(); // Prevent the default context menu from showing

        // Check if the value is numeric or can be parsed to a number
        if (originalData.some(item => !isNaN(parseFloat(item[column])))) {
            setContextMenu({
                visible: true,
                x: event.clientX,
                y: event.clientY,
                column: column,
            });
        }
    };


    useEffect(() => {
        const handleClickOutside = () => {
            if (contextMenu.visible) {
                setContextMenu({ ...contextMenu, visible: false });
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [contextMenu]);



    const handleColumnSum = (column) => {
        const sum = originalData.reduce((acc, item) => {
            const value = parseFloat(item[column]);
            return acc + (isNaN(value) ? 0 : value);
        }, 0);
        setSumValue(sum);
        setShowSumModal(true);
    };



    const toggleAIComponent = () => {
        setShowAI(prevState => !prevState);
        console.log(`toggleAIComponent called, showAI: ${!showAI}`);
    };

    console.log("is data fetched: ", isDataFetched);

    const handleSidebarToggle = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };
    // Utility function to format column names
    const formatColumnName = (key) => {
        return key
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
    };

    const fetchData = async (endpoint = reportEndpoint, enableAction = true, requestBody = null) => {
        setLoading(true); // Set loading state to true before fetching data
        console.log("endpoint on report viewer: ", endpoint);
        try {
            const response = await fetch(endpoint, {
                method: 'POST', // Always use POST method
                headers: {
                    'Content-Type': 'application/json',
                    // Add any other necessary headers here
                },
                body: requestBody ? JSON.stringify(requestBody) : null, // Add body only if requestBody exists
            });

            const result = await response.json();

            if (result.length > 0) {
                const columnKeys = Object.keys(result[0]);
                setColumns(columnKeys);
                setVisibleColumns(columnKeys); // Initially, all columns are visible
                setData(result);
                setOriginalData(result); // Store the original data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Set loading state to false after fetching data
        }
    };


    // Function to handle sorting
    const handleSort = (column) => {
        let direction = 'ascending';
        if (sortConfig.key === column && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: column, direction: direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[column] < b[column]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[column] > b[column]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setData(sortedData);
    };

    // Function to handle filtering by specific values
    const handleFilterByValues = (column, selectedValues) => {
        setFilterConfig(prev => ({
            ...prev,
            values: { ...prev.values, [column]: selectedValues }
        }));

        const filteredData = originalData.filter(item => {
            if (!selectedValues.length) return true;
            const value = item[column];
            if (selectedValues.includes('null') && (value === null || value === '')) return true;
            return selectedValues.includes(value?.toString());
        });

        setData(filteredData);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Handle Enter key press in the search field
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleFilter();
        }
    };

    // Function to handle column resizing
    const handleResize = (index, event) => {
        const startX = event.clientX;
        const startWidth = tableRef.current.querySelectorAll('th')[index].offsetWidth;

        const handleMouseMove = (e) => {
            const currentWidth = startWidth + e.clientX - startX;
            tableRef.current.querySelectorAll('th')[index].style.width = `${currentWidth}px`;
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    // Function to handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Function to handle "Previous" and "Next" buttons
    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Function to handle column visibility
    const handleColumnVisibilityChange = (column) => {
        setVisibleColumns(prevState =>
            prevState.includes(column)
                ? prevState.filter(col => col !== column) // Hide the column
                : [...prevState, column] // Show the column
        );
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page when items per page changes
    };

    // Function to download the data as an XLSX file with formatted headers
    const handleDownload = () => {
        // Create a new workbook and a new worksheet
        const formattedData = data.map(item => {
            const formattedItem = {};
            columns.forEach(column => {
                if (visibleColumns.includes(column)) {
                    formattedItem[formatColumnName(column)] = item[column];
                }
            });
            return formattedItem;
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Auto fit columns, bold header, and set font size to 11px
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const col = XLSX.utils.encode_col(C);
            worksheet[col + '1'].s = {
                font: { bold: true, sz: 10 } // Bold header with 10px font size
            };
            for (let R = range.s.r + 1; R <= range.e.r; ++R) {
                const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
                if (cell && cell.v) {
                    cell.s = {
                        font: { sz: 10 } // Set font size to 10px for data cells
                    };
                }
            }
            let maxLength = 0;
            for (let R = range.s.r; R <= range.e.r; ++R) {
                const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
                if (cell && cell.v) {
                    maxLength = Math.max(maxLength, cell.v.toString().length);
                }
            }
            worksheet['!cols'] = worksheet['!cols'] || [];
            worksheet['!cols'][C] = { width: maxLength + 2 }; // Auto-fit columns
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        // Generate a file name
        const fileName = `data_${new Date().toISOString().slice(0, 10)}.xlsx`;

        // Export the file
        XLSX.writeFile(workbook, fileName);
    };

    const handleAlertSave = () => {
        // Assuming you store alert configurations in an array or state
        console.log("Alert Config Saved:", alertConfig);
        // Logic to save the alert configuration for server or client-side usage
    };


    useEffect(() => {
        if (alertConfig.column && alertConfig.threshold) {
            const shouldTriggerAlert = data.some(
                (item) => item[alertConfig.column] > parseFloat(alertConfig.threshold)
            );
            if (shouldTriggerAlert) {
                alert(`Alert Triggered: Value exceeded threshold for ${formatColumnName(alertConfig.column)}`);
            }
        }
    }, [data, alertConfig]);


    // Function to handle generic filtering based on search
    const handleFilter = () => {
        const { query, column } = filterConfig;
        if (!query || !column) {
            setData(originalData); // Reset data if filter is cleared
            return;
        }

        const filteredData = originalData.filter(item =>
            item[column]?.toString().toLowerCase().includes(query.toLowerCase())
        );

        setData(filteredData);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Calculate the current data to be displayed based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Create an array of unique values for filtering
    const getUniqueValues = (column) => {
        const values = originalData.map(item => item[column]);
        return Array.from(new Set(values.map(value => (value === null || value === undefined ? 'null' : value.toString()))));
    };

    // Create an array of page numbers for pagination
    const getPaginationGroup = () => {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
        let pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    // Add this function inside ReportViewer.js
    const handleActionClick = async (actionReportId, rowData) => {
        try {
            console.log("action got clicked!");
            /* const response = await fetch(`http://localhost:5020/prod/api/v1/test/report/${actionReportId}`); */

            const actionReportEndpoint = `http://localhost:5020/prod/api/v1/cas/reporting/${actionReportId}`;

            console.log("action endpoint: ", actionReportEndpoint);

            const response = await fetch(actionReportEndpoint);



            const actionReportDetails = await response.json();

            let constructedEndpoint = `http://localhost:5020/prod/api/v1/cas/reporting/run-aggregation/${actionReportId}`;

            // Update the reportEndpoint, reportName, and reportDescription states
            setReportEndpoint(constructedEndpoint);
            setReportName(actionReportDetails.report_name);
            setReportDescription(actionReportDetails.description);
            setEnableAction(actionReportDetails.enable_action);

            // Reset search input by clearing the query in filterConfig
            setFilterConfig(prevConfig => ({
                ...prevConfig,
                query: '',  // Clear the search query
            }));

            if (actionReportDetails.requires_parameter) {
                if (actionReportDetails.parameter.parameter_type === "path") {
                    constructedEndpoint = `${constructedEndpoint}/${rowData[actionReportDetails.parameter.parameter_name]}`;
                } else if (actionReportDetails.parameter.parameter_type === "body") {
                    const requestBody = {
                        [actionReportDetails.parameter.parameter_name]: rowData[actionReportDetails.parameter.parameter_name],
                    };
                    console.log("requestBody: ", requestBody);

                    // Call fetchData with the requestBody
                    fetchData(constructedEndpoint, actionReportDetails.enable_action, requestBody);
                    return; // Exit early since we already called fetchData
                }
            }

            // Fetch the data for the constructed endpoint and load it into the table
            fetchData(constructedEndpoint, actionReportDetails.enable_action);

        } catch (error) {
            console.error('Error fetching action report details:', error);
        }
    };

    // Check if the data was passed from ReportList.js and fetch if necessary
    useEffect(() => {
        if (location.state && location.state.parameterData) {
            // If parameter data is passed from ReportList.js, use it directly without another API call
            setColumns(Object.keys(location.state.parameterData[0] || {}));
            setVisibleColumns(Object.keys(location.state.parameterData[0] || {}));
            setData(location.state.parameterData);
            setOriginalData(location.state.parameterData);
            setIsDataFetched(true);
            setLoading(false);
        } else if (reportEndpoint && !isDataFetched) {
            // Otherwise, make the API call if data isn't already fetched
            fetchData(reportEndpoint);
            setIsDataFetched(true);
        }
    }, [reportEndpoint, location.state, isDataFetched]);



    return (
        <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <Sidebar onToggle={handleSidebarToggle} />
            <div className="main-content">

                {showAlertModal && (
                    <div className="custom-overlay">
                        <div className="custom-modal">
                            <div className="modal-header">
                                <h3>Set Alert on Column Value</h3>
                                <button
                                    className="modal-close-button"
                                    onClick={() => setShowAlertModal(false)}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <label className="modal-input-label" htmlFor="columnSelect">
                                    Select Column
                                </label>
                                <select
                                    id="columnSelect"
                                    value={alertConfig.column}
                                    onChange={(e) =>
                                        setAlertConfig({ ...alertConfig, column: e.target.value })
                                    }
                                    className="input-field search-select-field scheduler-modal-input"
                                >
                                    <option value="">Select Numeric Column</option>
                                    {columns.map((column, index) => (
                                        typeof originalData[0]?.[column] === 'number' && (
                                            <option key={index} value={column}>
                                                {formatColumnName(column)}
                                            </option>
                                        )
                                    ))}
                                </select>

                                <label className="modal-input-label" htmlFor="thresholdValue">
                                    Threshold Value
                                </label>
                                <input
                                    type="number"
                                    id="thresholdValue"
                                    value={alertConfig.threshold}
                                    onChange={(e) =>
                                        setAlertConfig({ ...alertConfig, threshold: e.target.value })
                                    }
                                    className="input-field"
                                />

                                <label className="modal-input-label">
                                    Alert Type
                                </label>
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        id="oneTime"
                                        name="alertType"
                                        value="one-time"
                                        checked={alertConfig.type === 'one-time'}
                                        onChange={(e) =>
                                            setAlertConfig({ ...alertConfig, type: e.target.value })
                                        }
                                    />
                                    <label htmlFor="oneTime">One-Time</label>

                                    <input
                                        type="radio"
                                        id="reminder"
                                        name="alertType"
                                        value="reminder"
                                        checked={alertConfig.type === 'reminder'}
                                        onChange={(e) =>
                                            setAlertConfig({ ...alertConfig, type: e.target.value })
                                        }
                                    />
                                    <label htmlFor="reminder">Reminder</label>
                                </div>

                                {alertConfig.type === 'reminder' && (
                                    <>
                                        <label className="modal-input-label" htmlFor="cadence">
                                            Reminder Cadence (in days)
                                        </label>
                                        <input
                                            type="number"
                                            id="cadence"
                                            value={alertConfig.cadence}
                                            onChange={(e) =>
                                                setAlertConfig({ ...alertConfig, cadence: e.target.value })
                                            }
                                            className="input-field"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="modal-submit-button"
                                    onClick={() => {
                                        handleAlertSave();
                                        setShowAlertModal(false);
                                    }}
                                >
                                    Save Alert
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showSumModal && (
                    <div className="custom-overlay">
                        <div className="custom-modal">
                            <div className="modal-header">
                                <h3>Sum of {formatColumnName(contextMenu.column)}</h3>
                                <button
                                    className="modal-close-button"
                                    onClick={() => setShowSumModal(false)}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="sum-result">
                                    The sum of <strong>{formatColumnName(contextMenu.column)}</strong> is: <strong>{sumValue}</strong>.
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button
                                    className="modal-submit-button"
                                    onClick={() => setShowSumModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}



                <div className="excel-view">
                    <div className="report-header">
                        <h2 className="report-title">{reportName}</h2>
                        <p className="report-description">{reportDescription}</p>
                    </div>
                    <AIComponent data={data} />
                    <div className="excel-panel-wrapper">
                        <div className="excel-panel">
                            <div className="filter-section">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={filterConfig.query}
                                    onChange={(e) => {
                                        setFilterConfig({ ...filterConfig, query: e.target.value });
                                        if (e.target.value === '') {
                                            setData(originalData); // Reset to original data when search is cleared
                                            setCurrentPage(1); // Reset to first page
                                        }
                                    }}
                                    onKeyPress={handleKeyPress}
                                    className="input-field"
                                />
                                <select
                                    value={filterConfig.column}
                                    onChange={(e) => setFilterConfig({ ...filterConfig, column: e.target.value })}
                                    className="filter-select"
                                >
                                    <option value="">Select Column</option>
                                    {columns.map((column, index) => (
                                        <option key={index} value={column}>{formatColumnName(column)}</option>
                                    ))}
                                </select>
                                <button onClick={handleFilter} className="excel-panel-button">
                                    <BsFilter className="excel-panel-icon" />Filter
                                </button>
                            </div>
                            <button className="excel-panel-button"><BsSortDown className="excel-panel-icon" />Sort</button>
                            <button className="excel-panel-button" onClick={handleDownload}>
                                <BsDownload className="excel-panel-icon" />Download
                            </button>
                            <button className="excel-panel-button" onClick={fetchData}>
                                <BsArrowClockwise className="excel-panel-icon" />Refresh
                            </button>
                            <button className="excel-panel-button" onClick={() => setShowColumnSelector(!showColumnSelector)}>
                                Show/Hide Columns
                            </button>

                            {/* <button className="excel-panel-button" onClick={() => setShowAlertModal(true)}>
                                Set Alert
                            </button> */}

                            <div className="menu-wrapper">
                                <BsThreeDotsVertical className="three-dot-icon" onClick={() => setIsMenuOpen(!isMenuOpen)} />
                                {isMenuOpen && (
                                    <div className="menu-dropdown">
                                        <div className="menu-item">
                                            <span>Show Gridlines</span>
                                            <div className={`toggle-switch ${isGridVisible ? 'on' : 'off'}`} onClick={toggleGridVisibility}>
                                                <div className="toggle-slider"></div>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <span>Freeze Columns</span>
                                            <select
                                                onChange={(e) => handleFreezeColumns(e.target.value)}
                                                className="freeze-select"
                                            >
                                                <option value="0">Unfreeze Columns</option>
                                                <option value="1">Freeze 1 Column</option>
                                                <option value="2">Freeze 2 Columns</option>
                                            </select>
                                        </div>
                                        <div className="menu-item">
                                            <span>Set Alert</span>
                                        </div>
                                    </div>
                                )}
                            </div>



                        </div>
                    </div>

                    {showColumnSelector && (
                        <div className="column-selector">
                            {columns.map((column, index) => (
                                <div key={index} className="column-selector-item">
                                    <input
                                        type="checkbox"
                                        id={`col-${column}`}
                                        checked={visibleColumns.includes(column)}
                                        onChange={() => handleColumnVisibilityChange(column)}
                                    />
                                    <label htmlFor={`col-${column}`}>{formatColumnName(column)}</label>
                                </div>
                            ))}
                        </div>
                    )}


                    {loading ? (
                        <div className="loader">Loading...</div>
                    ) : currentData.length === 0 ? (
                        <div className="no-data-container">

                            <img src={emptyBoxIcon} alt="No Data" className="no-data-icon" />

                            <p className="no-data-text">No data found</p>
                            <p className="no-data-subtext">No data is available for this report. Please try again later or adjust your filters.</p>
                        </div>
                    ) : (
                        <>
                            <div className="table-container"> {/* Add this wrapper */}
                                <table ref={tableRef}>
                                    <thead>
                                        <tr>
                                            {columns.map((column, index) => (
                                                visibleColumns.includes(column) && (
                                                    <th key={index} onContextMenu={(e) => handleRightClick(e, column)}>
                                                        <div className="column-header" onClick={() => handleSort(column)} style={{ cursor: 'pointer' }}>
                                                            <span>{formatColumnName(column)}</span>
                                                            <div className="filter-dropdown-wrapper">
                                                                <BsChevronExpand
                                                                    onClick={(e) => {
                                                                        e.stopPropagation(); // Prevent sorting on icon click
                                                                        setFilterDropdown(filterDropdown === column ? null : column);
                                                                    }}
                                                                    className="filter-dropdown-icon"
                                                                />
                                                                {filterDropdown === column && (
                                                                    <div className="filter-dropdown">
                                                                        {getUniqueValues(column).map((value, i) => (
                                                                            <div key={i}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={value}
                                                                                    checked={filterConfig.values[column]?.includes(value) || false}
                                                                                    onChange={(e) => {
                                                                                        const selectedValues = filterConfig.values[column] || [];
                                                                                        if (e.target.checked) {
                                                                                            handleFilterByValues(column, [...selectedValues, value]);
                                                                                        } else {
                                                                                            handleFilterByValues(column, selectedValues.filter(val => val !== value));
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label>{value === 'null' ? '(Blank/Empty)' : value}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </th>
                                                )
                                            ))}
                                            {enableAction && <th>Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((item, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {columns.map((column, colIndex) => (
                                                    visibleColumns.includes(column) && (
                                                        <td
                                                            key={colIndex}
                                                            data-row={rowIndex}
                                                            data-col={colIndex}
                                                            onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                                                            className={`${selectedCells.some(
                                                                (cell) => cell[0] === rowIndex && cell[1] === colIndex
                                                            )
                                                                ? 'selected-cell'
                                                                : ''
                                                                }`}
                                                        >
                                                            {item[column]}
                                                        </td>
                                                    )
                                                ))}
                                                {enableAction && (
                                                    <td className="action-column">
                                                        <a
                                                            onClick={() => handleActionClick(actionReportId, item)}
                                                            className="view-report-link"
                                                            style={{ cursor: 'pointer' }}>
                                                            {actionLabel}
                                                        </a>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>


                                </table>
                            </div>
                            <div className="pagination">
                                <div className="items-per-page">
                                    <label htmlFor="itemsPerPage">Items per page: </label>
                                    <select
                                        id="itemsPerPage"
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        className="items-per-page-select"
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="pagination-controls">
                                    <button
                                        onClick={() => paginate(1)}
                                        className="pagination-button"
                                        disabled={currentPage === 1}
                                    >
                                        First
                                    </button>
                                    <button
                                        onClick={() => handlePageChange('prev')}
                                        className="pagination-button"
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    {getPaginationGroup().map(page => (
                                        <button
                                            key={page}
                                            onClick={() => paginate(page)}
                                            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                        >
                                            {page}
                                        </button>
                                    ))}
                                    {currentPage < totalPages - 2 && <span className="pagination-dots">...</span>}
                                    {currentPage < totalPages - 1 && (
                                        <button
                                            onClick={() => paginate(totalPages)}
                                            className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
                                        >
                                            {totalPages}
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handlePageChange('next')}
                                        className="pagination-button"
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                    <button
                                        onClick={() => paginate(totalPages)}
                                        className="pagination-button"
                                        disabled={currentPage === totalPages}
                                    >
                                        Last
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    {/* Floating Button to Toggle AI Component */}
                    <button className="ai-floating-button" onClick={toggleAIComponent}>
                        <BsChatDots size={24} />
                    </button>

                    {/* AIComponent Modal */}
                    {showAI && <AIComponent data={data} onClose={toggleAIComponent} />}

                    {contextMenu.visible && (
                        <div
                            className="context-menu"
                            style={{ top: contextMenu.y, left: contextMenu.x, position: 'absolute', zIndex: 1000 }}
                            onClick={() => {
                                handleColumnSum(contextMenu.column);
                                setContextMenu({ ...contextMenu, visible: false });
                            }}
                        >
                            Sum the values
                        </div>
                    )}


                </div>
            </div>
        </div>
    );
};

export default ReportViewer;
