import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import './../../assets/css/SignupPage.css';

const LoginPage = () => {
    const navigate = useNavigate();
    const [loginDetails, setLoginDetails] = useState({
        email: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        // Clear the apiError when the user starts typing
        if (apiError) {
            setApiError('');
        }

    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!loginDetails.email) newErrors.email = 'Email is required';
        if (!loginDetails.password) newErrors.password = 'Password is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);  // Disable the button
            setLoading(true);
            setApiError('');
            try {
                const response = await fetch('http://localhost:5020/prod/api/v1/user/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: loginDetails.email,
                        password: loginDetails.password,
                        ref_access: 'CAS',
                    }),
                });

                const result = await response.json();

                if (response.ok && result.api.responseCode === 2230) {
                    // Navigate to OtpPage with email and contact number
                    navigate('/login/otp', {
                        state: {
                            email: loginDetails.email,
                            contact_number: result.result.contact_number || null,
                        },
                    });
                } else {
                    setApiError(result.message || 'Something went wrong. Please try again.');
                }
            } catch (error) {
                setApiError('Something went wrong. Please try again.');
            } finally {
                setLoading(false);
                setIsSubmitting(false);  // Enable the button after the API response
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Login to CAS Reporting</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={loginDetails.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            className="input-field"
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="form-group password-group">
                        <label>Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={loginDetails.password}
                            onChange={handleInputChange}
                            placeholder="Enter your password"
                            className="input-field"
                        />
                        <span className="eye-icon" onClick={toggleShowPassword}>
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                        </span>
                        {errors.password && <span className="error-message">{errors.password}</span>}
                    </div>
                    {apiError && <p className="error-message">{apiError}</p>}
                    <button type="submit" className="button" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
                <p className="signup-link">
                    Don't have an account? <span onClick={() => navigate('/signup')}>Sign Up</span>
                </p>
            </div>
            <footer className="footer">
                <p>© {new Date().getFullYear()} Eden Care. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default LoginPage;
