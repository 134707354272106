import React, { useState } from 'react';
import axios from 'axios';

const AIComponent = ({ data, show, onClose }) => {
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const selectedData = data.slice(0, 10);
            const columns = Object.keys(data[0] || {}).join(", ");
            const prompt = `Columns: ${columns}. Data (first 10 rows): ${JSON.stringify(selectedData)}. Question: ${question}`;

            const apiResponse = await axios.post('https://api.openai.com/v1/chat/completions', {
                messages: [{ role: "user", content: prompt }],
                model: "gpt-4",
                max_tokens: 150,
            }, {
                headers: {
                    'Authorization': `Bearer YOUR_API_KEY`,
                    'Content-Type': 'application/json'
                }
            });

            setResponse(apiResponse.data.choices[0].message.content);
        } catch (error) {
            console.error("Error fetching the AI response:", error);
            setResponse("Sorry, something went wrong.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`ai-component ${show ? 'visible' : 'hidden'}`}>
            <div className="ai-header">
                <button className="ai-close-button" onClick={onClose}>X</button>
            </div>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Ask a question about the data..."
                    className="ai-input"
                />
                <button type="submit" className="ai-submit-button" disabled={loading}>
                    {loading ? 'Asking...' : 'Ask AI'}
                </button>
            </form>
            {response && (
                <div className="ai-response">
                    <p>{response}</p>
                </div>
            )}
        </div>
    );
};

export default AIComponent;
