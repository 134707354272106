import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countriesData from './../../data/countries.json'; // Import the countries JSON data
import './../../assets/css/SignupPage.css';

const SetupFormlyticsPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userType: '',
        companyName: '',
        country: '',
        website: '',
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleCountryChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            country: selectedOption ? selectedOption.value : '',
        }));

        if (errors.country) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                country: '',
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.userType) newErrors.userType = 'Please select an option';
        if (formData.userType === 'organization' && !formData.companyName) newErrors.companyName = 'Company Name is required';
        if (!formData.country) newErrors.country = 'Country is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form data submitted:', formData);
            navigate('/create-workspace');
        }
    };

    const countryOptions = countriesData.map((country) => ({
        value: country.name,
        label: (
            <div className="country-option">
                <img src={country.flag} alt={country.name} className="country-flag" />
                {country.name}
            </div>
        ),
    }));

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Setup Formlytics</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Are you planning to set Formlytics for you as an Individual or for your organization?</label>
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    name="userType"
                                    value="individual"
                                    checked={formData.userType === 'individual'}
                                    onChange={handleInputChange}
                                />
                                Individual
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="userType"
                                    value="organization"
                                    checked={formData.userType === 'organization'}
                                    onChange={handleInputChange}
                                />
                                Organization
                            </label>
                        </div>
                        {errors.userType && <span className="error-message">{errors.userType}</span>}
                    </div>
                    {formData.userType === 'organization' && (
                        <div className="form-group">
                            <label>Company Name</label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                placeholder="Enter company name"
                                className="input-field"
                            />
                            {errors.companyName && <span className="error-message">{errors.companyName}</span>}
                        </div>
                    )}
                    <div className="form-group">
                        <label>Country</label>
                        <Select
                            name="country"
                            value={countryOptions.find(option => option.value === formData.country)}
                            onChange={handleCountryChange}
                            options={countryOptions}
                            className="country-select-field"
                            classNamePrefix="react-select"
                        />
                        {errors.country && <span className="error-message">{errors.country}</span>}
                    </div>
                    <div className="form-group">
                        <label>Website (optional)</label>
                        <input
                            type="text"
                            name="website"
                            value={formData.website}
                            onChange={handleInputChange}
                            placeholder="Enter website URL"
                            className="input-field"
                        />
                    </div>
                    <button type="submit" className="button">Next</button>
                </form>
            </div>
            <footer className="footer">
                <p>© {new Date().getFullYear()} Formlytics. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default SetupFormlyticsPage;
