import React, { useState } from 'react';
import './../../assets/css/FormConfiguration.css';

const FormConfiguration = () => {
    const [expandedSection, setExpandedSection] = useState(0);

    const handleSectionToggle = (sectionIndex) => {
        if (expandedSection === sectionIndex) {
            setExpandedSection(-1);
        } else {
            setExpandedSection(sectionIndex);
        }
    };

    const handleSave = (sectionIndex) => {
        // Handle save action for the section
        console.log(`Save button clicked for section ${sectionIndex}`);
    };

    return (
        <div className="form-configuration">
            <div className={`section ${expandedSection === 0 ? 'expanded' : ''}`}>
                <div className="section-header" onClick={() => handleSectionToggle(0)}>
                    <h2 className="section-title">Form & Campaign Settings</h2>
                </div>
                {expandedSection === 0 && (
                    <>
                        <div className="section-content">
                            <div className="form-control">
                                <label className="label">Form Name</label>
                                <input type="text" className="input-field" placeholder="Enter form name" />
                            </div>
                            <div className="form-control">
                                <label className="label">Form URL</label>
                                <input type="text" className="input-field" placeholder="Enter form URL" />
                            </div>
                            <div className="form-control">
                                <label className="label">Start Date</label>
                                <input type="date" className="input-field date-input" />
                            </div>
                            <div className="form-control">
                                <label className="label">End Date</label>
                                <input type="date" className="input-field date-input" />
                            </div>
                        </div>
                        <div className="section-footer">
                            <button className="save-button" onClick={() => handleSave(0)}>Save</button>
                        </div>
                    </>
                )}
            </div>

            <div className={`section ${expandedSection === 1 ? 'expanded' : ''}`}>
                <div className="section-header" onClick={() => handleSectionToggle(1)}>
                    <h2 className="section-title">Integrations</h2>
                </div>
                {expandedSection === 1 && (
                    <>
                        <div className="section-content">
                            <div className="form-control">
                                <label className="label">Webhook URL</label>
                                <input type="text" className="input-field" placeholder="Enter webhook URL" />
                            </div>
                            <div className="form-control">
                                <label className="label">Authorization Token</label>
                                <input type="text" className="input-field" placeholder="Enter authorization token" />
                            </div>
                        </div>
                        <div className="section-footer">
                            <button className="save-button" onClick={() => handleSave(1)}>Save</button>
                        </div>
                    </>
                )}
            </div>

            <div className={`section ${expandedSection === 2 ? 'expanded' : ''}`}>
                <div className="section-header" onClick={() => handleSectionToggle(2)}>
                    <h2 className="section-title">Audience Settings & Launch</h2>
                </div>
                {expandedSection === 2 && (
                    <>
                        <div className="section-content">
                            <div className="form-control">
                                <label className="label">Upload Users Data (Excel)</label>
                                <input type="file" className="input-field" />
                            </div>
                            <div className="form-control">
                                <label className="label">Or Connect API</label>
                                <input type="text" className="input-field" placeholder="Enter API endpoint" />
                                <input type="text" className="input-field" placeholder="Enter authorization token" />
                            </div>
                        </div>
                        <div className="section-footer">
                            <button className="save-button" onClick={() => handleSave(2)}>Save</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FormConfiguration;
