import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './../../assets/css/SignupPage.css';

const SignupPage = () => {
    const navigate = useNavigate();
    const [signupDetails, setSignupDetails] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [passwordValidations, setPasswordValidations] = useState({
        minLength: false,
        hasNumber: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasSpecialChar: false,
    });
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSignupDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        if (name === 'password') {
            validatePassword(value);
            setShowPasswordTooltip(value.length > 0);
        }
    };

    const validatePassword = (password) => {
        const validations = {
            minLength: password.length >= 8,
            hasNumber: /\d/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        setPasswordValidations(validations);

        // Hide tooltip if all validations are met
        const allValid = Object.values(validations).every(Boolean);
        setShowPasswordTooltip(!allValid);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleConfirmPasswordBlur = () => {
        if (signupDetails.password !== signupDetails.confirmPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: 'Passwords do not match',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: '',
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!signupDetails.name) newErrors.name = 'Name is required';
        if (!signupDetails.email) newErrors.email = 'Email is required';
        if (!signupDetails.password) newErrors.password = 'Password is required';
        if (signupDetails.password !== signupDetails.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Simulate sending OTP to user's email
            console.log('Sending OTP to:', signupDetails.email);
            navigate('/verify-otp', { state: { email: signupDetails.email } });
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Sign Up to CAS Reporting</h2>
                <p>Get started to create a form in 2 mins</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={signupDetails.name}
                            onChange={handleInputChange}
                            placeholder="Enter your name"
                            className="input-field"
                        />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={signupDetails.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            className="input-field"
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="form-group password-group">
                        <label>Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={signupDetails.password}
                            onChange={handleInputChange}
                            placeholder="Enter your password"
                            className="input-field"
                            onFocus={() => setShowPasswordTooltip(true)}
                            onBlur={() => setShowPasswordTooltip(signupDetails.password.length > 0 && !Object.values(passwordValidations).every(Boolean))}
                        />
                        <span className="eye-icon" onClick={toggleShowPassword}>
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                        </span>
                        {errors.password && <span className="error-message">{errors.password}</span>}
                        {showPasswordTooltip && (
                            <div className="password-tooltip">
                                <ul className="password-conditions">
                                    <li className={passwordValidations.minLength ? 'valid' : 'invalid'}>
                                        {passwordValidations.minLength ? <FaCheckCircle /> : <FaTimesCircle />} At least 8 characters
                                    </li>
                                    <li className={passwordValidations.hasNumber ? 'valid' : 'invalid'}>
                                        {passwordValidations.hasNumber ? <FaCheckCircle /> : <FaTimesCircle />} Contains a number
                                    </li>
                                    <li className={passwordValidations.hasUpperCase ? 'valid' : 'invalid'}>
                                        {passwordValidations.hasUpperCase ? <FaCheckCircle /> : <FaTimesCircle />} Contains an uppercase letter
                                    </li>
                                    <li className={passwordValidations.hasLowerCase ? 'valid' : 'invalid'}>
                                        {passwordValidations.hasLowerCase ? <FaCheckCircle /> : <FaTimesCircle />} Contains a lowercase letter
                                    </li>
                                    <li className={passwordValidations.hasSpecialChar ? 'valid' : 'invalid'}>
                                        {passwordValidations.hasSpecialChar ? <FaCheckCircle /> : <FaTimesCircle />} Contains a special character
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="form-group password-group">
                        <label>Confirm Password</label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            value={signupDetails.confirmPassword}
                            onChange={handleInputChange}
                            onBlur={handleConfirmPasswordBlur}
                            placeholder="Confirm your password"
                            className="input-field"
                        />
                        <span className="eye-icon" onClick={toggleShowConfirmPassword}>
                            {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                        </span>
                        {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}
                    </div>
                    <button type="submit" className="button">Sign Up</button>
                </form>
                <p className="login-link">
                    Already have an account? <span onClick={() => navigate('/login')}>Login to account</span>
                </p>
            </div>
            <footer className="footer">
                <p>© {new Date().getFullYear()} Eden Care. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default SignupPage;
